import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { unmountComponentAtNode } from 'react-dom';
import { BrowserRouter as RouterProvider } from 'react-router-dom';
import '@eigen3m/react-base-frontend/dist/react-base-frontend.cjs.development.css';
import './index.less';
import axios from 'axios';
import { authHelper } from '@eigen3m/react-base-frontend';
import { typeOf } from 'mathjs';
import { WebUrlData } from '@base-configs';

axios.interceptors.request.use((request) => {
  if (request.url.includes('contacts')) {
    const account = authHelper.getAccount();
    request.headers['x-ifgf-signature'] = !!account
      ? authHelper.encode({
          id: account?.user?.id,
          code: account?.user?.code,
          username: account?.user?.username,
        })
      : '';
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    if (response?.status === 403) window.location.replace(WebUrlData[403]);
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    if (status === 403) window.location.replace(WebUrlData[403]);
    return Promise.reject(error);
  },
);

declare global {
  interface Window {
    __POWERED_BY_QIANKUN__: any;
  }
}

function renderApp(props: any): any {
  const { container } = props;
  const rootElement = container ? container.querySelector('#root') : (document.querySelector('#root') as HTMLElement);
  const root = ReactDOM.createRoot(rootElement);
  const accessFromMainApp = window.__POWERED_BY_QIANKUN__;
  const prevUrl = accessFromMainApp ? process.env.REACT_APP_INIT_MICRO_URL : undefined;
  const appProps = {
    accessFromMainApp,
    prevUrl,
    ...props,
  };

  root.render(
    <React.StrictMode>
      <RouterProvider basename={accessFromMainApp ? process.env.REACT_APP_INIT_MICRO_URL : '/'}>
        <App {...appProps} />
      </RouterProvider>
    </React.StrictMode>,
  );
}

export async function bootstrap() {
  console.log(`[react18] react app bootstraped`);
}

export async function mount(props: any) {
  // console.log('[react18] props from main framework', props);
  renderApp(props);
}

export async function unmount(props: any) {
  const { container } = props;
  unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
}

if (!window.__POWERED_BY_QIANKUN__) renderApp({});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
