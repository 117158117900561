import React, { useEffect, useState } from 'react';
import { notificationFailed } from '@eigen3m/react-base-frontend';
import { useDataTableProvider, usePageProvider } from '@eigen3m/react-base-frontend';
import { AntdDataTable, AntdDataTableProps } from '../data-table-component';
import { DrawerDetail, DrawerDetailProps, DrawerForm, DrawerFormProps } from '@eigen3m/react-base-frontend';
import { Card } from 'antd';

interface SinglePageProps {
  drawerDetailProps?: DrawerDetailProps;
  drawerFormProps?: DrawerFormProps;
  dataTableProps?: AntdDataTableProps;
  autoConfirmAfterAction?: boolean;
}

export function SinglePage(props: SinglePageProps): JSX.Element {
  const { dataSource } = usePageProvider();
  const { recordDataTable, setRecordDataTable, loadingDataTable, setLoadingDataTable } = useDataTableProvider();
  const { dataTableProps, drawerDetailProps, drawerFormProps, autoConfirmAfterAction = false } = props;
  const [dataForm, setDataForm] = useState(null);
  const [dataDetail, setDetailData] = useState(null);

  const onShowDrawerForm = props?.drawerFormProps?.onShow;
  const onCloseDrawerForm = props?.drawerFormProps?.onClose;

  const onShowDrawerDetail = props?.drawerDetailProps?.onShow;
  const onCloseDrawerDetail = props?.drawerDetailProps?.onClose;

  useEffect(() => {
    //Reset dataform when action is create
    if (drawerFormProps?.action === 'create') setDataForm(null);
  }, [drawerFormProps?.action]);

  function handleClickUpdate(value: any): void {
    setDataForm(value);
    onShowDrawerForm('update');
  }

  function handleClickDuplicate(value: any): void {
    setDataForm(value);
    onShowDrawerForm('duplicate');
  }

  function handleClickDetail(value: any): void {
    setDetailData(value);
    onShowDrawerDetail();
  }

  async function refreshDataTable(): Promise<void> {
    setLoadingDataTable(true);
    await dataSource.handleGetIndex({
      onSuccess: ({ response }: any) => {
        const items = response?.items ?? [];
        setRecordDataTable(items);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
      params: {
        page: 1,
        limit: 50,
        ...dataTableProps?.defaultFilterRequestData,
      },
    });
    setLoadingDataTable(false);
  }

  async function handleConfirmProcess(payload): Promise<void> {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess: ({ response }: any) => {
        console.log('success');
      },
      onFailed: ({ message }: any) => {
        console.log('failed');
      },
    });
  }

  const handleAfterSave = (response: any): void => {
    refreshDataTable();
    if (drawerFormProps?.handleAfterSave) {
      drawerFormProps?.handleAfterSave(response);
    } else if (autoConfirmAfterAction) handleConfirmProcess(response);
  };

  return (
    <>
      <DrawerForm
        {...drawerFormProps}
        value={drawerFormProps?.value ?? dataForm}
        onClose={onCloseDrawerForm}
        handleAfterSave={handleAfterSave}
      />
      <DrawerDetail
        {...drawerDetailProps}
        value={drawerDetailProps?.value ?? dataDetail}
        onClose={onCloseDrawerDetail}
      />
      <Card>
        <AntdDataTable
          loading={loadingDataTable}
          dataSource={recordDataTable}
          onRow={(record: any) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                handleClickDetail(record);
              },
            };
          }}
          {...dataTableProps}
          rowActionProps={{
            handleClickUpdate(row) {
              handleClickUpdate(row);
            },
            handleClickDuplicate(row) {
              handleClickDuplicate(row);
            },
            handleClickDetail(row) {
              handleClickDetail(row);
            },
            ...(dataTableProps?.rowActionProps ?? {}),
          }}
        />
      </Card>
    </>
  );
}
