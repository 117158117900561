import { capitalize, omit } from 'lodash';
import { UserEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import moment from 'moment';

const getTransformer = (payload) => {
  if (!payload) return null;
  return {
    ...payload,
    has_join_care_group: payload?.has_join_care_group === true ? 'true' : 'false',
    has_attend_ifgf: payload?.has_attend_ifgf === true ? 'true' : 'false',
    date_of_birth: payload?.date_of_birth ? moment(payload?.date_of_birth) : null,
    anniversary_date: payload?.anniversary_date ? moment(payload?.anniversary_date) : null,
    relatives: payload?.relatives?.map((item) => {
      return {
        ...item,
        key: item?.id,
        contact: item?.contact ? getTransformer(item?.contact) : null,
      };
    }),
  };
};

export class UserTransformer<E extends BaseEntity = UserEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    return {
      ...cleanVal,
      date_of_birth: payload?.date_of_birth ? moment(payload?.date_of_birth).format('YYYY-MM-DD') : null,
      anniversary_date: payload?.anniversary_date ? moment(payload?.anniversary_date).format('YYYY-MM-DD') : null,
      has_join_care_group: payload?.has_join_care_group === 'true' ? true : false,
      has_attend_ifgf: payload?.has_attend_ifgf === 'true' ? true : false,
      gender: payload?.gender ? capitalize(payload?.gender) : null,
      password: payload?.password ? payload?.password : null,
      retype_password: payload?.retype_password ? payload?.retype_password : null,
      relatives: payload?.relatives?.map((item) => {
        return {
          ...item,
          contact: this.transformerUpdate(item?.contact),
        };
      }),
    };
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    if (!payload) return payload;
    const data = {
      ...getTransformer(payload),
      requested_data: getTransformer(payload?.requested_data),
    };
    return data;
  }
}
