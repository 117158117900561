import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';

import { CheckInEntity } from '../../domain/entities';
import { makeCheckInTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';

interface Props {
  defaultFilter?: any;
}

export default function FactoryPage(props: Props) {
  const { defaultFilter } = props;
  const transformer = makeCheckInTransformer<CheckInEntity>(defaultFilter);
  const InitialValue: InitialValuePageProviderEntity<CheckInEntity> = {
    transformer,
    moduleKey: 'data-check-in-page',
    webUrl: WebUrlData.event,
    apiUrl: ApiUrlData.check_in_data,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} PageDetail={PageIndex} />
    </PageProvider>
  );
}
