import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageDetail from '../pages/detail';

import { QREntity } from '../../domain/entities';
import { makeQRTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';

export default function FactoryPage() {
  const transformer = makeQRTransformer<QREntity>();
  const InitialValue: InitialValuePageProviderEntity<QREntity> = {
    transformer,
    moduleKey: 'data-qr-code-page',
    webUrl: WebUrlData.qr,
    apiUrl: ApiUrlData.contact,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} PageDetail={PageDetail} />
    </PageProvider>
  );
}
