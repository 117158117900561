import { CheckInEntity } from '../entities';
import { BaseEntity, IBaseTransformerRepository } from '@eigen3m/react-base-frontend';
import { CheckInTransformer } from '../transformer';

const defaultFilterKey = [
  'gender',
  'discipleship_center_ids',
  'care_group_leader_ids',
  'user_ids',
  'event_check_in_ids',
];

export function makeCheckInTransformer<E extends BaseEntity = CheckInEntity>(
  defaultFilter?: any,
): IBaseTransformerRepository<E> {
  const transformer = new CheckInTransformer<E>();
  transformer.transformerFilterIndexTable = (payload) => {
    const filter = {};
    Object.keys(defaultFilter).forEach((item) => {
      if (defaultFilterKey.includes(item)) {
        Object.assign(filter, { [item]: defaultFilter[item] });
      }
    });
    if (!payload)
      return {
        ...payload,
        ...filter,
      };
    return { ...payload, ...filter };
  };
  return transformer;
}
