import { authHelper, DetailPageWrapper, RenderText } from '@eigen3m/react-base-frontend';
import { Card, Form } from 'antd';
import { CubeProvider } from '@cubejs-client/react';
import { TableRenderer } from '../components/cube-query-builder/preview-query-builder/preview-chart/chart-render/tables/table-renderer';
import { cubejsApi } from '../helpers';

export default function DetailPage() {
  const account = authHelper.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;
  return (
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail Report',
        showTagStatus: false,
        showActivate: false,
        showDeactivate: false,
        showDelete: isAdmin,
        showUpdate: isAdmin ? true : false,
      }}
    >
      <Card>
        <Form.Item label="Report Name" name={['detail_data', 'name']}>
          <RenderText />
        </Form.Item>
      </Card>
      <div style={{ marginBottom: 10 }}></div>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const detailData = getFieldValue('detail_data');
          return (
            <Card>
              <CubeProvider cubejsApi={cubejsApi}>
                {detailData?.configuration && <TableRenderer configuration={detailData?.configuration ?? {}} />}
              </CubeProvider>
            </Card>
          );
        }}
      </Form.Item>
    </DetailPageWrapper>
  );
}
