import React from 'react';
import { Avatar, Button, Drawer } from 'antd';
import { SignOut } from 'phosphor-react';
import { CloseOutlined } from '@ant-design/icons';
import { AntdIconWrapper, authHelper } from '@eigen3m/react-base-frontend';
import { WebUrlData } from '@base-configs';
import { useNavigate } from 'react-router-dom';
import './style.less';

interface Props {
  onClose: any;
  open: any;
  labelName: string;
  name: string;
  menu: any;
}
export default function MobileMenu(props: Props) {
  const { onClose, open, labelName, name, menu } = props;
  const navigate = useNavigate();

  async function handleLogout() {
    await authHelper.clearStorageLogout();
    navigate(WebUrlData.login);
  }
  return (
    <Drawer
      className="mobile-menu"
      width="100%"
      title={
        <div style={{ textAlign: 'center' }}>
          <Avatar size={40} className="custom-avatar">
            {labelName?.toUpperCase()}
          </Avatar>
          <div style={{ marginTop: '10px' }}>
            <strong>{name}</strong>
            <div style={{ marginTop: '10px' }}>
              <Button onClick={() => handleLogout()} size="small" danger type="primary" style={{ borderRadius: '4px' }}>
                <AntdIconWrapper>
                  <SignOut />
                </AntdIconWrapper>
                Logout
              </Button>
            </div>
          </div>
        </div>
      }
      placement="right"
      onClose={onClose}
      open={open}
      bodyStyle={{ padding: 0 }}
      // closable={false}
      // closeIcon={<Button type="primary" icon={<CloseOutlined />} shape="circle" size="small" danger />}
    >
      {menu}
    </Drawer>
  );
}
