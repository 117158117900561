import React from 'react';
import { useDataTableProvider } from '@eigen3m/react-base-frontend';

interface TablePrintProps {
  columns: any[];
}

export function DataTablePrint(props: TablePrintProps) {
  const { recordDataTable, metaDataTable } = useDataTableProvider();
  const columns = [
    {
      title: 'No.',
      key: 'no',
      width: 40,
      align: 'center',
      style: { textAlign: 'center' },
      render(item, row, index) {
        return index + 1;
      },
    },

    ...(props.columns ?? []),
  ];

  return (
    <div>
      <table style={{ width: '100%', marginTop: '5px', color: '#000' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid black', textAlign: 'left' }}>
            {columns?.map((column: any) => {
              return (
                <th
                  style={{
                    ...(column?.style ?? {}),
                    border: '1px solid #000',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    fontSize: '11px',
                  }}
                  key={column?.key}
                >
                  {column?.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody style={{ textAlign: 'left' }}>
          {recordDataTable?.map((line, index) => {
            return (
              <tr key={index}>
                {columns?.map((column: any) => {
                  return (
                    <td
                      style={{
                        ...(column?.style ?? {}),
                        border: '1px solid #000',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        fontSize: '11px',
                      }}
                      key={column?.key}
                    >
                      {column?.render ? column?.render(line[column?.key], line, index) : line[column?.key] ?? '-'}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
