import { IChartType } from './helpers';
import { CubeProvider, QueryBuilder } from '@cubejs-client/react';
import { CustomQueryBuilder } from './custom-query-builder';

interface Props {
  ignoreChartType?: IChartType[];
  cubejsApi: any;
}

export function CubeQueryBuilder(props: Props) {
  const { ignoreChartType, cubejsApi } = props;
  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <QueryBuilder
        render={(props) => <CustomQueryBuilder {...(props ?? {})} ignoreChartType={ignoreChartType} />}
      ></QueryBuilder>
    </CubeProvider>
  );
}
