import { LoginTemplate1 } from '../components';
import rightContentImage from '../assets/background-3.png';
import { useNavigate, useParams } from 'react-router-dom';
import { WebUrlData, ApiUrlData } from '@base-configs';
import { authHelper, FormBuilder, makeCommonDataSource, notificationFailed } from '@eigen3m/react-base-frontend';
import { useEffect, useState } from 'react';
import logoImage from '../assets/logo-1.png';
import { Row, Col, Image, Form, Button, Divider, notification } from 'antd';
import React from 'react';
import { FormBuilderProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.user_login });
  const dataSourceLoginToken = makeCommonDataSource({ apiUrl: ApiUrlData.user_login_token });
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const [form] = Form.useForm();

  async function handleAfterLogin(response: any): Promise<void> {
    await authHelper.setAccount(response);
    navigate('/');
  }

  async function onFinish(payload) {
    if (token) {
      setLoading(true);
      //fungsi untuk reset password
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'PATCH',
          url: process.env.REACT_APP_BASE_SERVICE_URL + `v1/event/auth/${payload?.dataToken?.user?.id}/change-password`,
          data: payload,
          params: {
            email: payload?.email,
          },
        },
        async onSuccess({ response }: any) {
          notification.success({
            message: 'Success',
            description: `Password has been reset, please login with your new password!`,
          });
          navigate('/auth/login');
          setLoading(false);
        },
        async onFailed({ message }) {
          notificationFailed([message as string]);
          setLoading(false);
        },
      });
    } else {
      //fungsi untuk kirim email link berisi tautan untuk reset password
      setLoading(true);
      await dataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.forgot_password,
          data: payload,
          params: {
            email: payload?.email,
          },
        },
        async onSuccess({ response }: any) {
          notification.success({
            message: 'Success',
            description: `Email with link for reset password has been sent to ${payload?.email}`,
          });
          navigate('/auth/login');
          setLoading(false);
        },
        async onFailed({ message }) {
          await authHelper.clearStorageLogout();
          notificationFailed([message as string]);
          setLoading(false);
        },
      });
    }
  }

  const formBuilderProps: FormBuilderProps = {
    columns: [
      {
        fields: [
          {
            label: 'Email',
            name: 'email',
            gridColumn: { span: 24 },
            fieldType: 'inputText',
            rules: [{ required: true, message: 'Please input your email!' }, { type: 'email' }],
            inputTextProps: {
              type: 'email',
              // size: 'large',
              placeholder: 'Enter your mail',
              className: 'login-field',
            },
          },
        ],
      },
    ],
  };

  const formBuilderResetPassword: FormBuilderProps = {
    columns: [
      {
        fields: [
          {
            label: 'Password',
            name: 'password',
            gridColumn: { span: 24 },
            fieldType: 'inputPassword',
            rules: [{ required: true, message: 'Please input your new password!' }],
            inputTextProps: {
              type: 'password',
              size: 'large',
              placeholder: 'Enter your password',
              className: 'login-field',
            },
          },
          {
            label: 'Retype Password',
            name: 'retype_password',
            gridColumn: { span: 24 },
            fieldType: 'inputPassword',
            rules: [
              { required: true, message: 'Please input your new password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const password = getFieldValue('password');
                  if (!password) return Promise.resolve();
                  else if (password === value) return Promise.resolve();
                  else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                  else return Promise.reject(new Error("Passwords doesn't match!"));
                },
              }),
            ],
            inputTextProps: {
              type: 'password',
              size: 'large',
              placeholder: 'Enter your password',
              className: 'login-field',
            },
          },
        ],
      },
    ],
  };

  async function loginToken(token) {
    setLoading(true);
    await dataSourceLoginToken.handleCustomRequest({
      paramRequest: {
        method: 'POST',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.user_login_token,
        data: { token },
      },
      async onSuccess({ response }) {
        form.setFieldsValue({ dataToken: response });
        setLoading(false);
      },
      async onFailed({ message }) {
        await authHelper.clearStorageLogout();
        notificationFailed([message as string]);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      loginToken(decodeToken);
    }
  }, [token]);

  return (
    <div className="login-page-template-1">
      <Row>
        <Col xs={24} sm={24} lg={9}>
          <div className="form-content-wrapper">
            <div>
              <div className="logo-wrapper">
                {logoImage && <Image className="login-logo" src={logoImage} preview={false} />}
              </div>
              {!token && (
                <React.Fragment>
                  <div className="login-title">Forgot Password</div>
                  <div className="login-sub-title">
                    Welcome, please put your email and we will send an email with a link for reset your password!{' '}
                  </div>
                  <div>
                    <Form form={form} name="form-login" layout={'vertical'} onFinish={onFinish} autoComplete="off">
                      <Form.Item name="token" hidden></Form.Item>
                      <FormBuilder {...formBuilderProps} />
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <Button size="small" type="link" disabled={loading} onClick={() => navigate('/auth/login')}>
                        Login Instead
                      </Button>
                    </div>
                    <div style={{ marginBottom: '20px' }}></div>
                    <Button
                      size="large"
                      type="primary"
                      disabled={loading}
                      loading={loading}
                      style={{ width: '100%' }}
                      onClick={() => form.submit()}
                    >
                      Send
                    </Button>
                  </div>
                  <div style={{ marginBottom: '20px' }}></div>
                </React.Fragment>
              )}
              {token && (
                <React.Fragment>
                  <div className="login-title">Reset Password</div>
                  <div className="login-sub-title">Please input your new password! </div>
                  <div>
                    <Form form={form} name="form-login" layout={'vertical'} onFinish={onFinish} autoComplete="off">
                      <FormBuilder {...formBuilderResetPassword} />
                      <Form.Item name="dataToken" hidden></Form.Item>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <Button size="small" type="link" disabled={loading} onClick={() => navigate('/auth/login')}>
                        Login Instead
                      </Button>
                    </div>
                    <div style={{ marginBottom: '20px' }}></div>
                    <Button
                      size="large"
                      type="primary"
                      disabled={loading}
                      loading={loading}
                      style={{ width: '100%' }}
                      onClick={() => form.submit()}
                    >
                      Save
                    </Button>
                  </div>
                  <div style={{ marginBottom: '20px' }}></div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} lg={15}>
          {rightContentImage && (
            <img src={rightContentImage} style={{ height: '100vh', width: '100%', objectFit: 'cover' }} />
          )}
        </Col>
      </Row>
    </div>
  );
}
