import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthRoutes, NoAuthRoutes, PublicRoutes, DefaultPath } from '@main/routes/router';
import { AuthLayout, PublicLayout } from '@main/layouts';
import {
  AuthRouterProtector,
  makeCommonDataSource,
  NoAuthRouterProtector,
  PageNotFound,
} from '@eigen3m/react-base-frontend';
import { ApiUrlData } from '@main/configs/api-url';
import { useRecoilState } from 'recoil';
import { mockCompanies } from 'states';
import { ForbiddenPage } from '@main/components/forbidden-page';
import { PrivacyPolicy } from '@pages/privacy-policy';

interface Props {
  basePath: string;
  [key: string]: any;
}

export default function AppRoutes(props: Props): JSX.Element {
  const { basePath, accessFromMainApp } = props;
  const dataSourceCompanies = makeCommonDataSource({
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    apiUrl: ApiUrlData.companies,
  });
  const [, setCompanies] = useRecoilState(mockCompanies);
  async function handleGetMockCompany() {
    await dataSourceCompanies.handleGetIndex({
      onSuccess({ response }: any) {
        const items = response ?? [];
        setCompanies(items);
      },
      onFailed({ message }) {
        console.log({ message });
      },
    });
  }

  useEffect(() => {
    handleGetMockCompany();
  }, []);
  return (
    <Routes>
      {AuthRoutes.map((item) => (
        <React.Fragment key={item.path}>
          <Route
            path={item.path}
            element={
              <AuthRouterProtector routeItem={{ ...item, basePath: item.path }}>
                <AuthLayout accessFromMainApp={accessFromMainApp}>
                  <item.element />
                </AuthLayout>
              </AuthRouterProtector>
            }
          ></Route>
          {item.path !== basePath && (
            <Route
              path={item.path + '/:action'}
              element={
                <AuthRouterProtector routeItem={{ ...item, path: `${item.path}:/action`, basePath: item.path }}>
                  <AuthLayout accessFromMainApp={accessFromMainApp}>
                    <item.element />
                  </AuthLayout>
                </AuthRouterProtector>
              }
            ></Route>
          )}
        </React.Fragment>
      ))}
      {NoAuthRoutes.map((item) => (
        <React.Fragment key={item.path}>
          <Route
            path={item.path}
            element={
              <NoAuthRouterProtector>
                <PublicLayout>
                  <item.element />
                </PublicLayout>
              </NoAuthRouterProtector>
            }
          ></Route>
        </React.Fragment>
      ))}
      {PublicRoutes.map((item) => (
        <React.Fragment key={item.path}>
          <Route
            path={item.path}
            element={
              <PublicLayout>
                <item.element />
              </PublicLayout>
            }
          ></Route>
        </React.Fragment>
      ))}
      <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
      <Route path="/" element={<Navigate to={DefaultPath} replace />}></Route>
      <Route path="*" element={<PageNotFound />}></Route>
      <Route path="/403" element={<ForbiddenPage />}></Route>
    </Routes>
  );
}
