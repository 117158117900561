import { Alert } from 'antd';
import { queryTransformer } from '../../helpers';
import { ChartRender } from './chart-render/charts';
import { TableRender } from './chart-render/tables';

interface Props {
  configuration?: any;
}

const charts = ['line', 'area', 'bar', 'pie'];
export function ContentRender(props: Props) {
  const configuration = props?.configuration;
  const chartType = configuration?.chartType;
  const query = queryTransformer(configuration.query) ?? {};

  // if (!query?.measures || query?.measures?.length === 0)
  //   return <Alert type="warning" message="Choose a measure or dimension to get started" />;

  let noContent = true;
  if (!query?.measures && !query?.dimensions) noContent = true;
  else if (query?.measures?.length === 0 && query?.dimensions?.length === 0) noContent = true;
  else noContent = false;

  if (noContent) return <h3>Choose a measure or dimension to get started.</h3>;
  if (chartType === 'table') return <TableRender configuration={configuration} />;
  else if (charts.includes(chartType)) return <ChartRender vizState={{ query, chartType }} />;
  return <div>No content.</div>;
}
