import {
  authHelper,
  ButtonToolbarWrapperProps,
  DetailBuilder,
  DetailPageWrapper,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import moment from 'moment';
import { RelativeComponent } from '@main/components/relative-form';
import { Form, Spin, Tabs } from 'antd';
import { AiOutlineQrcode } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const grid = { xs: 24, sm: 10, md: 6, lg: 4, xl: 4 };

export default function DetailPage() {
  const { id, detailData } = usePageProvider();
  const [showContent, setShowContent] = useState(true);
  const [loading, setLoading] = useState(false);

  const account = authHelper.getAccount();
  const accountUserId = account?.user?.id;
  const isSameUser = accountUserId === id;
  const isNotAdmin = account?.user?.username !== process.env.REACT_APP_ADMIN_EMAIL;
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;
  const navigate = useNavigate();

  const isActive = detailData?.status === 'active';
  const isInActive = detailData?.status === 'inactive';

  const additionalToolbar: ButtonToolbarWrapperProps[] = [
    {
      buttonTitle: 'QR Code',
      tooltipTitle: 'QR Code',
      icon: <AiOutlineQrcode />,
      handleClick() {
        navigate(`/qr-code/detail?id=${id}`);
      },
    },
  ];

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      {!showContent && <div style={{ minHeight: '50vh' }}></div>}
      {showContent && (
        <DetailPageWrapper
          pageToolbarProps={{
            pageTitle: 'Detail User',
            additionalButtonAction: isSameUser || !isNotAdmin ? additionalToolbar : undefined,
            showUpdate: !isNotAdmin || isSameUser,
            showDuplicate: !isNotAdmin,
            showDelete: !isNotAdmin,
            showActivate: !isNotAdmin && isInActive,
            showDeactivate: !isNotAdmin && isActive,
            showNewData: !isNotAdmin,
            handleClickReload() {
              setShowContent(false);
              setLoading(true);
              setTimeout(() => {
                setShowContent(true);
                setLoading(false);
              }, 200);
            },
          }}
        >
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              const detailData = values?.detail_data;
              const isHcc = detailData?.is_hcc;
              const isIhc = detailData?.is_ihc;

              const showHcc = (detailData?.is_ihc || !isNotAdmin) && !detailData?.is_hcc;
              const showIhc = (detailData?.is_icc || !isNotAdmin) && !detailData?.is_hcc;
              const showIcc = (detailData?.is_leader || !isNotAdmin) && !detailData?.is_hcc;
              const showIcl =
                !detailData?.is_hcc && !detailData?.is_ihc && !detailData?.is_icc && !detailData?.is_leader;

              const columns: any[] = [
                {
                  gridColumn: grid,
                  label: 'Name',
                  name: ['detail_data', 'name'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Email',
                  name: ['detail_data', 'email'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Date of Birth',
                  name: ['detail_data', 'date_of_birth'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return moment(value).format('YYYY-MM-DD');
                    },
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Marital Status',
                  name: ['detail_data', 'marital_status'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Anniversary Date',
                  name: ['detail_data', 'anniversary_date'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      return moment(value).format('YYYY-MM-DD');
                    },
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Gender',
                  name: ['detail_data', 'gender'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Phone Number',
                  name: ['detail_data'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                    renderValue: (value) => `${value?.prefix_phone ?? ''} ${value?.phone_number ?? '-'}`,
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Address',
                  name: ['detail_data', 'address', 'address'],
                  fieldType: 'address',
                  renderTextProps: {
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Has Join Care Group',
                  name: ['detail_data', 'has_join_care_group'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      if (value === 'true') return 'Yes';
                      else return 'No';
                    },
                    defaultRender: () => '-',
                  },
                },
                ...(showIcl
                  ? [
                      {
                        gridColumn: grid,
                        label: 'Care Group Leader',
                        name: ['detail_data', 'care_group_leader', 'name'],
                        fieldType: 'text',
                        renderTextProps: {
                          defaultRender: () => '-',
                          renderValue(value) {
                            if (detailData?.has_join_care_group === 'true') {
                              return value;
                            }
                            return '-';
                          },
                        },
                      },
                    ]
                  : []),
                ...(showIcc
                  ? [
                      {
                        gridColumn: grid,
                        label: 'ICC',
                        name: ['detail_data', 'icc', 'name'],
                        fieldType: 'text',
                        renderTextProps: {
                          defaultRender: () => '-',
                        },
                      },
                    ]
                  : []),
                ...(showIhc
                  ? [
                      {
                        gridColumn: grid,
                        label: 'IHC',
                        name: ['detail_data', 'ihc', 'name'],
                        fieldType: 'text',
                        renderTextProps: {
                          defaultRender: () => '-',
                        },
                      },
                    ]
                  : []),
                ...(showHcc
                  ? [
                      {
                        gridColumn: grid,
                        label: 'HCC',
                        name: ['detail_data', 'hcc', 'name'],
                        fieldType: 'text',
                        renderTextProps: {
                          defaultRender: () => '-',
                        },
                      },
                    ]
                  : []),
                {
                  gridColumn: grid,
                  label: 'Has Attend IFGF',
                  name: ['detail_data', 'has_attend_ifgf'],
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue(value) {
                      if (value === 'true') return 'Yes';
                      else return 'No';
                    },
                    defaultRender: () => '-',
                  },
                },
                {
                  gridColumn: grid,
                  label: 'Discipleship Center',
                  name: ['detail_data', 'discipleship_center', 'name'],
                  fieldType: 'text',
                  renderTextProps: {
                    defaultRender: () => '-',
                    renderValue(value) {
                      if (isAdmin || detailData?.has_attend_ifgf === 'true') {
                        return value;
                      }
                      return '-';
                    },
                  },
                },
                ...(isNotAdmin
                  ? []
                  : [
                      {
                        gridColumn: grid,
                        label: 'Role Position',
                        name: ['detail_data', 'role_position', 'code'],
                        fieldType: 'text',
                        renderTextProps: {
                          defaultRender: () => '-',
                        },
                      },
                    ]),
                ...(isAdmin
                  ? [
                      {
                        gridColumn: grid,
                        label: 'Is Checker',
                        name: ['detail_data', 'is_checker'],
                        fieldType: 'text',
                        renderTextProps: {
                          defaultRender: () => '-',
                          renderValue(value) {
                            return value ? 'Yes' : 'No';
                          },
                        },
                      },
                    ]
                  : []),
              ];
              return (
                <DetailBuilder
                  columns={[
                    {
                      title: 'INDIVIDUAL INFORMATION',
                      fields: columns,
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              return (
                <Tabs>
                  <Tabs.TabPane tab="Relative">
                    <Form.Item name={['detail_data', 'relatives']} noStyle>
                      <RelativeComponent />
                    </Form.Item>
                  </Tabs.TabPane>
                </Tabs>
              );
            }}
          </Form.Item>
        </DetailPageWrapper>
      )}
    </Spin>
  );
}
