import { ApiUrlData } from '@base-configs';
import {
  AntdIconWrapper,
  authHelper,
  DetailBuilder,
  DetailPageWrapper,
  FormBuilder,
  makeCommonDataSource,
  useMediaQuery,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { Button, Card, Col, Descriptions, Form, notification, Row, Spin, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import { SendOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { AiOutlineQrcode } from 'react-icons/ai';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
const grid = { xs: 24, sm: 10, md: 6, lg: 4, xl: 4 };

const defaultButtonStyle = {
  marginLeft: '12px',
  marginRight: '12px',
  fontSize: '12px',
};

export default function DetailPage() {
  const { detailData } = usePageProvider();

  const account = authHelper.getAccount();
  const accountUserId = account?.user?.id;
  const isNotAdmin = account?.user?.username !== process.env.REACT_APP_ADMIN_EMAIL;

  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [relativeOptions, setRelativeOptions] = useState([]);
  const isMobile = useMediaQuery({ media: 'mobile' });
  const sendEmailDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.contact });
  const [showContent, setShowContent] = useState(true);
  const [loading, setLoading] = useState(false);
  async function handleSendEmail(payload?: any) {
    setLoadingSendEmail(true);
    if (payload) {
      let detail = null;
      await sendEmailDataSource.handleGetData(account?.id ?? detailData?.id, {
        onSuccess(params) {
          detail = params?.response;
        },
        onFailed(params) {
          console.log({ params });
        },
      });
      const data = {
        contacts: detail?.qr_codes?.map((item) => {
          const findData = detail?.relatives?.find((relative) => relative?.contact_relative?.id === item?.id);
          const newPayload = detailData?.id === item?.id ? detailData : payload;
          const data = findData?.contact_relative ?? newPayload;
          const name = data?.name;
          const email = data?.email;
          const gender = data?.gender;
          const date_of_birth = data?.date_of_birth;
          const age = moment().diff(date_of_birth, 'years', false);
          const marital_status = data?.marital_status;
          const phone_number = data?.phone_number;
          const address = data?.address;
          const hasCompletedByAge = age < 12 ? true : age >= 12 && !!email && !!phone_number ? true : false;
          const isComplete = name && hasCompletedByAge && gender && date_of_birth && marital_status && address;

          return {
            contact_relative: {
              id: data?.id,
              name: data?.name,
              email: payload?.id === item?.id ? data?.email : null,
            },
            is_completed: isComplete ? true : false,
            relation_type: findData?.relation_type ?? 'Main User',
          };
        }),
      };
      // const data = {
      //   contacts: detail?.qr_codes?.map((item) => {
      //     return {
      //       contact_relative: item,
      //       relation_type: 'Spouse',
      //     };
      //   }),
      // };
      await sendEmailDataSource.handleCustomRequest({
        paramRequest: {
          method: 'POST',
          url: process.env.REACT_APP_BASE_URL + ApiUrlData.contact + '/scan-qrcode',
          data: data,
        },
        onSuccess(params) {
          notification.success({
            message: 'Success',
            description: `The qr code has been emailed to ${payload?.email ?? 'user email account'}`,
          });
        },
        onFailed({ message }) {
          notification.error({
            message: 'Error',
            description: `${message?.[0]}`,
          });
        },
      });
    }
    setLoadingSendEmail(false);
  }

  async function generateRelativeOptions(data) {
    let detail = null;
    await sendEmailDataSource.handleGetData(detailData?.id, {
      onSuccess({ response }: any) {
        const data = response;
        detail = data;
      },
      onFailed(params) {
        console.log(params);
      },
    });
    if (detail?.name) {
      setRelativeOptions(
        detail?.relatives?.map((item) => {
          return {
            label: item?.contact_relative?.name,
            value: item?.contact_relative,
          };
        }),
      );
    }
  }

  useEffect(() => {
    if (detailData) {
      generateRelativeOptions(detailData);
    }
  }, [detailData]);

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      {!showContent && <div style={{ minHeight: '50vh' }}></div>}
      {showContent && (
        <DetailPageWrapper
          pageToolbarProps={{
            pageTitle: 'QR Code',
            showActivate: false,
            showDataTypeSelector: false,
            showApproval: false,
            showDeactivate: false,
            showDelete: false,
            showDuplicate: false,
            showNewData: false,
            showUpdate: false,
            showTagStatus: false,
            showConfirmProcess: false,
            handleClickReload() {
              setShowContent(false);
              setLoading(true);
              setTimeout(() => {
                setShowContent(true);
                setLoading(false);
              }, 200);
            },
          }}
        >
          <Spin spinning={loadingSendEmail} tip="Sending Email">
            <Form.Item shouldUpdate noStyle>
              {({ getFieldsValue }) => {
                const values = getFieldsValue();
                const user = values?.user;
                const account = values?.detail_data;

                const data = user ?? account;

                const name = data?.name;
                const email = data?.email;
                const gender = data?.gender;
                const date_of_birth = data?.date_of_birth;
                const marital_status = data?.marital_status;
                const phone_number = data?.phone_number;
                const address = data?.address;
                const age = moment().diff(date_of_birth, 'years', false);
                const hasCompletedByAge = age < 12 ? true : age >= 12 && !!email && !!phone_number ? true : false;
                const isComplete = name && hasCompletedByAge && gender && date_of_birth && marital_status && address;

                let id = account?.id;
                let phone_info = account?.phone_number
                  ? `${account?.prefix_phone ?? ''} ${account?.phone_number}`
                  : null;
                let email_info = account?.email ? account?.email : null;
                if (user) {
                  id = user?.id;
                  phone_info = user?.phone_number ? `${user?.prefix_phone ?? ''} ${user?.phone_number}` : null;
                  email_info = user?.email ? user?.email : null;
                }

                const isSameUser = id === accountUserId;
                return (
                  <div>
                    <FormBuilder
                      columns={[
                        {
                          fields: [
                            {
                              label: '',
                              name: 'user',
                              fieldType: 'select',
                              gridColumn: { xs: 24, sm: 12, md: 8, lg: 6, xl: 5 },
                              selectProps: {
                                // dataSourceUrl: ApiUrlData.contact,
                                options: relativeOptions,
                                keyLabel: 'name',
                                placeholder: 'View Other QR Code',
                              },
                            },
                            // {
                            //   label: '',
                            //   name: 'view_profile',
                            //   gridColumn: grid,
                            //   fieldType: 'select',
                            //   renderField(form) {
                            //     return (
                            //       <div>
                            //         <Link to={`/users/detail?id=${values?.user?.id ?? account?.id}`}>
                            //           <Button block disabled={!values?.user && !account}>
                            //             View Profile
                            //           </Button>
                            //         </Link>
                            //       </div>
                            //     );
                            //   },
                            // },
                            // {
                            //   label: '',
                            //   name: 'edit_profile',
                            //   gridColumn: grid,
                            //   fieldType: 'select',
                            //   renderField(form) {
                            //     return (
                            //       <div>
                            //         <Link to={`/users/update?id=${values?.user?.id ?? account?.id}`}>
                            //           <Button block disabled={!values?.user && !account}>
                            //             Edit Profile
                            //           </Button>
                            //         </Link>
                            //       </div>
                            //     );
                            //   },
                            // },
                            // {
                            //   label: '',
                            //   name: 'send_qr',
                            //   gridColumn: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
                            //   fieldType: 'select',
                            //   renderField(form) {
                            //     return (
                            //       <div>
                            //         <Button block onClick={() => handleSendEmail(values?.user ?? account)}>
                            //           Send QR Code to My Email
                            //         </Button>
                            //       </div>
                            //     );
                            //   },
                            // },
                          ],
                        },
                      ]}
                    />
                    {(values?.user || values?.detail_data) && (
                      <Row
                        style={{ marginTop: '30px', textAlign: isMobile ? 'center' : 'left', alignItems: 'flex-start' }}
                      >
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} style={{ marginBottom: '20px' }} hidden={!isComplete}>
                          <QRCode value={values?.user?.id ?? values?.detail_data?.id} size={240} />
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={18} xl={18}>
                          <Card>
                            <Row gutter={[10, 10]} hidden={!isComplete}>
                              <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                <Link to={`/users/detail?id=${id}`}>
                                  <Button
                                    block
                                    disabled={!values?.user && !values?.detail_data}
                                    icon={<UserOutlined />}
                                  >
                                    View Profile
                                  </Button>
                                </Link>
                              </Col>
                              {(isSameUser || !isNotAdmin) && (
                                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                  <Link to={`/users/update?id=${id}`}>
                                    <Button
                                      block
                                      disabled={!values?.user && !values?.detail_data}
                                      icon={<EditOutlined />}
                                    >
                                      Edit Profile
                                    </Button>
                                  </Link>
                                </Col>
                              )}
                              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                                <Button
                                  block
                                  disabled={!email}
                                  // onClick={() => handleSendEmail(values?.user ?? values?.detail_data)}
                                  onClick={() => handleSendEmail(values?.user ?? values?.detail_data)}
                                  icon={<SendOutlined />}
                                >
                                  Send QR Code to {values?.user?.name ?? 'My Email'}
                                </Button>
                              </Col>
                            </Row>
                            {isComplete && (
                              <Descriptions title="User Info" style={{ marginTop: '10px' }} size="small">
                                <Descriptions.Item label="Name" span={24}>
                                  {values?.user?.name ?? values?.detail_data?.name ?? '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Phone" span={24}>
                                  {phone_info ?? '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Email" span={24}>
                                  {/* {values?.user?.email ?? values?.detail_data?.email ?? '-'} */}
                                  {email_info ?? '-'}
                                </Descriptions.Item>
                              </Descriptions>
                            )}
                            {values?.user && !isComplete && (
                              <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                                <Link to={`/users/update?id=${account?.id}`}>
                                  <Button block disabled={!values?.user && !account} icon={<EditOutlined />}>
                                    Edit Profile
                                  </Button>
                                </Link>
                              </Col>
                            )}
                            {!isComplete && (
                              <Descriptions title="" style={{ marginTop: '10px' }} size="small">
                                <Descriptions.Item label="" span={24}>
                                  {`Please complete ${
                                    values?.user?.name ?? 'your'
                                  } profile first and QR Code will be showed here!`}
                                </Descriptions.Item>
                              </Descriptions>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </div>
                );
              }}
            </Form.Item>
          </Spin>
        </DetailPageWrapper>
      )}
    </Spin>
  );
}
