import React from 'react';
import { FormBuilder } from '@eigen3m/react-base-frontend';
import { Form } from 'antd';

export function EventForm() {
  return (
    <React.Fragment>
      <Form.Item name="company" hidden></Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const values = getFieldsValue();
          return (
            <FormBuilder
              className="mt-0"
              columns={[
                {
                  fields: [
                    {
                      gridColumn: { span: 24 },
                      customHidden: () => true,
                      renderField() {
                        return <Form.Item name="company" noStyle />;
                      },
                    },
                    {
                      label: 'Name',
                      gridColumn: { span: 24 },
                      name: ['information', 'name'],
                      fieldType: 'inputText',
                      rules: [{ required: true, message: 'Name is required!' }],
                      inputTextProps: {
                        placeholder: 'Input Name',
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>
      <Form.Item name={['information', 'id']} hidden></Form.Item>
    </React.Fragment>
  );
}
