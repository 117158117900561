import React from 'react';
import { RowActionProps } from './row-action.entity';
import { Button, Dropdown, Menu } from 'antd';
import { BsEye, BsLock, BsUnlock } from 'react-icons/bs';
import { AiOutlineMore } from 'react-icons/ai';
import { BiTrashAlt, BiCopy, BiEdit, BiCheck } from 'react-icons/bi';
import { EnumStatusData, AntdIconWrapper } from '@eigen3m/react-base-frontend';
import './row-action.less';

export function RowAction(props: RowActionProps) {
  const {
    handleClickActivate,
    handleClickDeactivate,
    handleClickDelete,
    handleClickDetail,
    handleClickDuplicate,
    handleClickUpdate,
    handleClickConfirmProcess,
    actionIgnored = [],
    actionDisabled = [],
    additionalActions,
    rowData,
    icon,
  } = props;

  function makeMenuAction(row) {
    const status = row?.status?.toLowerCase();
    let newIgnoreAction = actionIgnored;

    if (status === EnumStatusData.draft) {
      newIgnoreAction = [...actionIgnored, 'activate', 'deactivate'];
    } else {
      if (status === EnumStatusData.active) newIgnoreAction = [...actionIgnored, 'activate'];
      else if (status === EnumStatusData.inactive) newIgnoreAction = [...actionIgnored, 'deactivate'];

      if (!row?.requested_data) newIgnoreAction = [...newIgnoreAction, 'confirm_process'];
    }

    const newDisabledAction = actionDisabled;

    return (
      <Menu
        style={{ minWidth: '150px' }}
        items={[
          {
            key: 'confirm_process',
            icon: <BiCheck />,
            label: 'Confirm Process',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickConfirmProcess) handleClickConfirmProcess(row);
            },
          },
          {
            key: 'Detail',
            icon: <BsEye />,
            label: 'Detail',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickDetail) handleClickDetail(row);
            },
          },
          {
            key: 'Update',
            icon: <BiEdit />,
            label: 'Update',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickUpdate) handleClickUpdate(row);
            },
          },
          {
            key: 'Duplicate',
            icon: <BiCopy />,
            label: 'Duplicate',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickDuplicate) handleClickDuplicate(row);
            },
          },
          {
            key: 'Delete',
            icon: <BiTrashAlt />,
            label: 'Delete',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickDelete) handleClickDelete(row);
            },
          },
          {
            key: 'Activate',
            icon: <BsUnlock />,
            label: 'Activate',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickActivate) handleClickActivate(row);
            },
          },
          {
            key: 'Deactivate',
            icon: <BsLock />,
            label: 'Deactivate',
            onClick(e) {
              e.domEvent.stopPropagation();
              if (handleClickDeactivate) handleClickDeactivate(row);
            },
          },
          ...(additionalActions ? additionalActions(row) : []),
        ]
          .filter((item: any) => !newIgnoreAction.includes(item.key.toLowerCase()))
          .map((item: any) => {
            if (!newDisabledAction.includes(item.key.toLowerCase())) return item;
            return {
              ...item,
              disabled: true,
            };
          })}
      />
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Dropdown
        overlay={makeMenuAction(rowData)}
        placement="bottomRight"
        // arrow={{ pointAtCenter: true }}
      >
        <Button
          size="small"
          type="text"
          onClick={(e) => e.stopPropagation()}
          icon={<AntdIconWrapper>{icon || <AiOutlineMore />}</AntdIconWrapper>}
        ></Button>
      </Dropdown>
    </div>
  );
}
