import { makeCommonDataSource } from '@eigen3m/react-base-frontend';
import { typeOf } from 'mathjs';
import { GOOGLE_MAPS_KEY } from './google-key';
import axios from 'axios';

export const BASE_REGION_URL = 'https://api.region.eigen.co.id';

export enum RegionEnum {
  countries = 'countries',
  country = 'country',
  provinces = 'provinces',
  province = 'province',
  cities = 'cities',
  city = 'city',
  districts = 'districts',
  district = 'district',
  villages = 'villages',
  village = 'village',
}

export const ApiUrlData = {
  countries: 'countries',
  country: 'country',
  provinces: 'provinces',
  province: 'province',
  cities: 'cities',
  city: 'city',
  districts: 'districts',
  district: 'district',
  villages: 'villages',
  village: 'village',
};

export const defaultAdditional = {
  page: 1,
  limit: 10,
};

export function makeOptions(options) {
  const newOptions = options ?? [];
  return newOptions.map((item) => {
    const keys = Object.keys(item ?? {});
    if (keys.length === 2 && keys.includes('label') && keys.includes('value')) return item;
    else
      return {
        label: item?.name,
        value: item,
      };
  });
}

export function filterOptions(options: any[], initialValue: any): any[] {
  let newOpt = options;
  if (Array.isArray(initialValue)) {
    newOpt = newOpt
      ?.map((item) => {
        const findLabel = initialValue?.find((itemInit) => itemInit.label === item.label);
        if (findLabel) return null;
        return item;
      })
      .filter(Boolean);
  }
  return newOpt;
}

export function isObject(value) {
  const exType = ['string', 'number', 'boolean'];
  const typeValue = typeOf(value);
  return !exType.includes(typeValue);
}

export function makeArrayValue(value: any[], customLabel: any, keyLabel: string): any[] {
  if (!value || value?.length === 0) return value;
  return value.map((itemValue) => {
    const isObj = isObject(itemValue);
    if (!isObj) {
      return {
        label: customLabel ? customLabel(itemValue) : itemValue,
        value: itemValue,
      };
    }
    return {
      label: customLabel ? customLabel(itemValue) : itemValue[keyLabel],
      value: itemValue,
    };
  });
}

export function makeValue(value: any, customLabel: any, keyLabel: string): any {
  if (!value) return value;
  const isObj = isObject(value);
  if (!isObj) {
    return {
      label: customLabel ? customLabel(value) : value,
      value: value,
    };
  }
  return {
    label: customLabel ? customLabel(value) : value[keyLabel],
    value: value,
  };
}

export function transformValue(value: any, customLabel: any, keyLabel: string): any {
  if (!value) return null;
  else if (Array.isArray(value)) return makeArrayValue(value, customLabel, keyLabel);
  return makeValue(value, customLabel, keyLabel);
}

// New version

function makeValueRegion(value) {
  if (!value) return undefined;
  return {
    ...value,
    name: value.long_name,
  };
}

function makeValuePostalCode(value) {
  if (!value) return undefined;
  else if (typeOf(value === 'Object')) {
    return Number(value.long_name);
  }
  return value;
}

// get full address by latitude and Longitude
async function getAddressLocation({ lat, lng }) {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_KEY}`,
    );

    const result = await response.json();
    return result ? result.results[0] : '';
  } catch (error) {
    return '';
  }
}

export async function generateFormateAddress({ lat, lng, setMarker = undefined }) {
  const generate = await getAddressLocation({ lat, lng });
  const address_components = generate?.address_components;
  const address = generate?.formatted_address;
  const country = makeValueRegion(address_components.find((item) => item.types[0] === 'country'));
  const province = makeValueRegion(address_components.find((item) => item.types[0] === 'administrative_area_level_1'));
  const city = makeValueRegion(address_components.find((item) => item.types[0] === 'administrative_area_level_2'));
  const postal_code = makeValuePostalCode(address_components.find((item) => item.types[0] === 'postal_code'));

  const country_name = country?.name;
  const province_name = province?.name;
  const city_name = city?.name;

  const regionResponse = await axios.post(`${BASE_REGION_URL}/regions/generate`, {
    country_name,
    province_name,
    city_name,
  });
  const regions = regionResponse?.data?.data ?? {};
  const marker = {
    lat,
    lng,
    address,
    address_components,
    time: new Date(),
  };
  if (setMarker) setMarker(marker);
  return {
    address,
    postal_code,
    latitude: lat,
    longitude: lng,
    ...regions,
  };
}
