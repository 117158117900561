import { omit } from 'lodash';
import { ReportEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import moment from 'moment';

export class ReportTransformer<E extends BaseEntity = ReportEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    Object.assign(cleanVal, { title_id: 1 });
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerFilterIndexTable(payload: any) {
    if (!payload) return payload;
    const resultDate = (data) => {
      return data?.map((item, idx) => {
        if (idx === 0) {
          return moment(`${moment(item).format('YYYY-MM-DD')} 00:00:00`);
        }
        if (idx === 1) {
          return moment(`${moment(item).format('YYYY-MM-DD')} 23:59:59`);
        }
      });
    };
    const createdDate = resultDate(payload?.created_at);
    const updatedDate = resultDate(payload?.updated_at);
    return {
      ...payload,
      created_from: payload?.created_at?.[0] ? new Date(createdDate?.[0]).valueOf() : null,
      created_to: payload?.created_at?.[1] ? new Date(createdDate?.[1]).valueOf() : null,
      created_at: [],
      updated_from: payload?.updated_at?.[0] ? new Date(updatedDate?.[0]).valueOf() : null,
      updated_to: payload?.updated_at?.[1] ? new Date(updatedDate?.[1]).valueOf() : null,
      updated_at: [],
    };
  }
}
