import {
  AntdIconWrapper,
  authHelper,
  FormBuilder,
  makeCommonDataSource,
  notificationFailed,
  RenderAddress,
  RowAction,
  stringFormatter,
  useMediaQuery,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { Button, Checkbox, Drawer, Form, Input, message, Select, Table, Tooltip } from 'antd';
import { Plus } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
import { omit } from 'lodash';
import { ApiUrlData } from '@base-configs';
import { useNavigate, useParams } from 'react-router-dom';
import { phoneNumberPrefixOptions } from '@pages/users/presentation/pages/helpers';
import { OptionGender, OptionsYesOrNo } from '@pages/users/presentation/helpers';
import { typeOf } from 'mathjs';
import React from 'react';
import { ListComponent } from './list-component';
import { BiEdit, BiTrashAlt } from 'react-icons/bi';
import { BsFillEyeFill } from 'react-icons/bs';
import { AiOutlineEye } from 'react-icons/ai';
import { HiOutlineMenu } from 'react-icons/hi';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { InputAddress } from '../form-address';

const { Option } = Select;
interface RelativeProps {
  value?: any[];
  onChange?(value: [any]): void;
  isRegister?: boolean;
  dataGeneral?: any;
}

const defaultAdditional = {
  page: 1,
  limit: 10,
};

function makeOptions(options) {
  const newOptions = options ?? [];
  return newOptions.map((item) => {
    const keys = Object.keys(item ?? {});
    if (keys.length === 2 && keys.includes('label') && keys.includes('value')) return item;
    else
      return {
        label: item,
        value: item,
      };
  });
}

function filterOptions(options: any[], initialValue: any): any[] {
  let newOpt = options;
  if (Array.isArray(initialValue)) {
    newOpt = newOpt
      ?.map((item) => {
        const findLabel = initialValue?.find((itemInit) => itemInit.label === item.label);
        if (findLabel) return null;
        return item;
      })
      .filter(Boolean);
  }
  return newOpt;
}

function isObject(value) {
  const exType = ['string', 'number', 'boolean'];
  const typeValue = typeOf(value);
  return !exType.includes(typeValue);
}

export function makeArrayValue(value: any[], customLabel: any, keyLabel: string): any[] {
  if (!value || value?.length === 0) return value;
  return value.map((itemValue) => {
    const isObj = isObject(itemValue);
    if (!isObj) {
      return {
        label: customLabel ? customLabel(itemValue) : itemValue,
        value: itemValue,
      };
    }
    return {
      label: customLabel ? customLabel(itemValue) : itemValue[keyLabel],
      value: itemValue,
    };
  });
}

export function makeValue(value: any, customLabel: any, keyLabel: string): any {
  if (!value) return value;
  const isObj = isObject(value);
  if (!isObj) {
    return {
      label: customLabel ? customLabel(value) : value,
      value: value,
    };
  }
  return {
    label: customLabel ? customLabel(value) : value[keyLabel],
    value: value,
  };
}

function transformValue(value: any, customLabel: any, keyLabel: string): any {
  if (!value) return null;
  else if (Array.isArray(value)) return makeArrayValue(value, customLabel, keyLabel);
  return makeValue(value, customLabel, keyLabel);
}

const prefixPhoneSelector = (
  <Form.Item name={['contact_relative', 'prefix_phone']} noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

export function RelativeComponent(props: RelativeProps) {
  const { value = [], onChange, isRegister, dataGeneral } = props;
  const isMobile = useMediaQuery({ media: 'mobile' });
  const params = useParams();
  const navigate = useNavigate();
  const { dataSource, isCreate } = usePageProvider();
  const isDetail = params?.action === 'detail';
  const account = authHelper.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;
  const isNotAdmin = account?.user?.username !== process.env.REACT_APP_ADMIN_EMAIL;

  const [isCreateAnother, setIsCreateAnother] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const onShowDrawer = () => setVisibleDrawer(true);
  const [form] = Form.useForm();
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
  };

  const [action, setAction] = useState(null);

  const dataSourceRelationType = makeCommonDataSource({
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    apiUrl: ApiUrlData.enum_relation,
  });

  async function loadRelationTypeOptions(search, prevOptions, { page, limit }) {
    let options = [];
    let hasMore = false;
    let newAdditional = defaultAdditional;
    await dataSourceRelationType.handleGetIndex({
      params: { page, limit, search },
      onSuccess: ({ response }: any) => {
        const message = response.items ?? response;
        const meta = response.meta;
        const rawOptions = makeOptions(message);
        options = filterOptions(rawOptions, transformValue(value, null, null));
        hasMore = meta.currentPage < meta.totalPages && options.length > 0;
        newAdditional = {
          page: meta.currentPage + 1,
          limit: 10,
        };
      },
      onFailed: ({ message }) => {
        console.log({ message });
      },
    });
    return {
      hasMore,
      options,
      additional: newAdditional,
    };
  }

  function onChangeValue(newValue) {
    if (onChange) onChange(newValue);
  }

  function onSubmitForm(payload) {
    let newValue = [];
    const cleanVal = omit(payload, ['form_action']);
    if (payload.form_action === 'create') {
      newValue = [{ ...cleanVal, key: payload.key ?? uuidV4() }, ...value];
    } else {
      newValue = value.map((item) => {
        if (item.key !== payload.key) return item;
        return {
          ...cleanVal,
          key: payload.key ?? uuidV4(),
        };
      });
    }
    onChangeValue(newValue);
    form.resetFields();
    if (isCreateAnother) {
      form.setFieldsValue({
        form_action: 'create',
        contact_relative: {
          has_attend_ifgf: 'false',
          has_join_care_group: 'false',
          prefix_phone: '+62',
        },
      });
    }
    if (!isCreateAnother) onCloseDrawer();
  }

  function handleUpdate(row) {
    setAction('update');
    form.setFieldsValue({
      ...row,
      form_action: 'update',
    });
    onShowDrawer();
  }

  function handleDelete(row) {
    const newValue = value.filter((item) => item.key !== row.key);
    onChangeValue(newValue);
  }

  // const showHasJoinCareGroup = !isNotAdmin && !isRegister;
  const showHasJoinCareGroup = !isRegister;
  // const showCareGroupLeader = !isNotAdmin && !isRegister;
  const showCareGroupLeader = !isRegister;
  // const showDiscipleshipCenter = !isNotAdmin && !isRegister;
  // const showRolePosition = !isNotAdmin && !isRegister;
  const showDiscipleshipCenter = !isRegister;
  const showRolePosition = !isNotAdmin && !isRegister;
  const showHcc = !isNotAdmin && !isRegister;
  const showIhc = !isNotAdmin && !isRegister;
  const showIcc = !isNotAdmin && !isRegister;

  const actionIgnored: any[] = ['activate', 'deactivate', 'duplicate', ...(isDetail ? ['update', 'delete'] : [])];
  function handleClickDetail(row) {
    navigate(`/users/detail?id=${row?.contact_relative?.id}`);
  }

  const columns = [
    {
      width: '30px',
      key: 'action',
      fixed: true,
      render(row) {
        const dateOfBirth = row?.contact_relative?.date_of_birth;
        const age = moment().diff(dateOfBirth, 'years', false);
        return (
          <div style={{ zIndex: 0 }}>
            {isMobile && (
              <React.Fragment>
                <Button
                  hidden={isDetail || (isNotAdmin && row?.contact_relative?.id && age > 12)}
                  size="small"
                  ghost
                  type="primary"
                  style={{ border: 0, padding: 0 }}
                  icon={<BiEdit style={{ marginRight: '5px' }} />}
                  onClick={() => handleUpdate(row)}
                >
                  Update
                </Button>
                <Button
                  hidden={isDetail}
                  size="small"
                  ghost
                  type="primary"
                  style={{ border: 0, marginLeft: '10px', marginRight: '10px', padding: 0 }}
                  icon={<BiTrashAlt style={{ marginRight: '5px' }} />}
                  onClick={() => handleDelete(row)}
                >
                  Delete
                </Button>
                <Button
                  hidden={!row?.contact_relative?.id || (isNotAdmin && row?.contact_relative?.id && age > 12)}
                  size="small"
                  ghost
                  type="primary"
                  style={{ border: 0, padding: 0 }}
                  icon={<AiOutlineEye style={{ marginRight: '5px' }} />}
                  onClick={() => handleClickDetail(row)}
                >
                  Detail
                </Button>
              </React.Fragment>
            )}
            {!isMobile && (
              <RowAction
                {...(!row?.contact_relative?.id ? { actionDisabled: ['detail'] } : {})}
                icon={<HiOutlineMenu />}
                actionIgnored={
                  isNotAdmin && row?.contact_relative?.id && age > 12
                    ? [...actionIgnored, 'detail', 'update']
                    : actionIgnored
                }
                handleClickUpdate={() => handleUpdate(row)}
                handleClickDelete={() => handleDelete(row)}
                handleClickDetail={() => handleClickDetail(row)}
              />
            )}
          </div>
        );
      },
    },
    // ...(isDetail
    //   ? []
    //   : [
    //       {
    //         width: '30px',
    //         key: 'action',
    //         fixed: true,
    //         render(row) {
    //           return (
    //             <div style={{ zIndex: 0 }}>
    //               {isMobile && (
    //                 <React.Fragment>
    //                   <Button
    //                     size="small"
    //                     ghost
    //                     type="primary"
    //                     style={{ border: 0, padding: 0 }}
    //                     icon={<BiEdit style={{ marginRight: '5px' }} />}
    //                     onClick={() => handleUpdate(row)}
    //                   >
    //                     Update
    //                   </Button>
    //                   <Button
    //                     size="small"
    //                     ghost
    //                     type="primary"
    //                     style={{ border: 0, marginLeft: '10px', padding: 0 }}
    //                     icon={<BiTrashAlt style={{ marginRight: '5px' }} />}
    //                     onClick={() => handleDelete(row)}
    //                   >
    //                     Delete
    //                   </Button>
    //                 </React.Fragment>
    //               )}
    //               {!isMobile && (
    //                 <RowAction
    //                   actionIgnored={['activate', 'deactivate', 'detail', 'duplicate']}
    //                   handleClickUpdate={() => handleUpdate(row)}
    //                   handleClickDelete={() => handleDelete(row)}
    //                 />
    //               )}
    //             </div>
    //           );
    //         },
    //       },
    //     ]),
    { width: 150, title: 'Relation', dataIndex: 'relation_type' },
    { width: 200, title: 'Name', key: 'contact_relative.name', dataIndex: ['contact_relative', 'name'] },
    { width: 200, title: 'Email', dataIndex: ['contact_relative', 'email'], key: 'email' },
    {
      width: 150,
      title: 'Date of Birth',
      key: 'date_of_birth',
      dataIndex: ['contact_relative', 'date_of_birth'],
      render(item) {
        if (!item) return '-';
        return moment(item).format('DD-MM-YYYY');
      },
    },
    {
      width: 100,
      title: 'Gender',
      dataIndex: ['contact_relative', 'gender'],
      render(item) {
        if (!item) return '-';
        return stringFormatter.capitalizeEachWord(item);
      },
    },
    {
      width: 100,
      title: 'Marital Status',
      dataIndex: ['contact_relative', 'marital_status'],
      render(item) {
        if (!item) return '-';
        return stringFormatter.capitalizeEachWord(item);
      },
    },
    {
      width: 130,
      title: 'Anniversary Date',
      key: 'anniversary_date',
      dataIndex: ['contact_relative', 'anniversary_date'],
      render(item) {
        if (!item) return '-';
        return moment(item).format('DD-MM-YYYY');
      },
    },
    {
      width: 200,
      title: 'Address',
      key: 'address',
      dataIndex: ['contact_relative', 'address', 'address'],
      render(item) {
        if (!item) return '-';
        return RenderAddress({ value: item });
      },
    },
    {
      width: 150,
      title: 'Phone Number',
      dataIndex: ['contact_relative', 'phone_number'],
      render(item, row) {
        if (!item) return '-';
        return `${row?.contact_relative?.prefix_phone ?? ''} ${item}`;
      },
    },
    ...(showHasJoinCareGroup
      ? [
          {
            width: 200,
            title: 'Has Join Care Group?',
            dataIndex: ['contact_relative', 'has_join_care_group'],
            render(item) {
              if (!item || item === 'false') return 'No';
              if (item === 'true') return 'Yes';
              // return stringFormatter.capitalizeEachWord(item);
            },
          },
        ]
      : []),
    ...(showCareGroupLeader
      ? [
          {
            width: 150,
            title: 'Care Group Leader',
            dataIndex: ['contact_relative', 'care_group_leader'],
            render(item) {
              if (!item) return '-';
              return item?.name;
            },
          },
        ]
      : []),
    ...(showIcc
      ? [
          {
            width: 150,
            title: 'ICC Name',
            dataIndex: ['contact_relative', 'icc'],
            render(item) {
              if (!item) return '-';
              return item?.name;
            },
          },
        ]
      : []),
    ...(showIhc
      ? [
          {
            width: 150,
            title: 'IHC Name',
            dataIndex: ['contact_relative', 'ihc'],
            render(item) {
              if (!item) return '-';
              return item?.name;
            },
          },
        ]
      : []),
    ...(showHcc
      ? [
          {
            width: 150,
            title: 'HCC',
            dataIndex: ['contact_relative', 'hcc'],
            render(item) {
              if (!item) return '-';
              return item?.name;
            },
          },
        ]
      : []),
    {
      width: 150,
      title: 'Has Attend IFGF',
      dataIndex: ['contact_relative', 'has_attend_ifgf'],
      render(item) {
        if (!item || item === 'false') return 'No';
        if (item === 'true') return 'Yes';
        // return stringFormatter.capitalizeEachWord(item);
      },
    },
    ...(showDiscipleshipCenter
      ? [
          {
            width: 150,
            title: 'Discipleship Center',
            dataIndex: ['contact_relative', 'discipleship_center'],
            render(item) {
              if (!item) return '-';
              return item?.name;
            },
          },
        ]
      : []),
    ...(showRolePosition
      ? [
          {
            width: 150,
            title: 'Role Position',
            dataIndex: ['contact_relative', 'role_position'],
            render(item) {
              if (!item) return '-';
              return item?.code;
            },
          },
        ]
      : []),
  ];

  function transformData(data = []) {
    return data.map((item) => {
      return {
        ...item,
        key: item.key ?? item.id ?? uuidV4(),
      };
    });
  }

  const [loadingContactRelation, setLoadingContactRelation] = useState(false);

  async function checkContactsRelation(values) {
    setLoadingContactRelation(true);
    try {
      if (
        !!values?.contact_relative?.name &&
        (!!values?.contact_relative?.phone_number || !!values?.contact_relative?.date_of_birth)
      ) {
        const dateOfBirth = values?.contact_relative?.date_of_birth;
        const formatDateOfBirth = values?.contact_relative?.date_of_birth
          ? moment(values?.contact_relative?.date_of_birth).format('YYYY-MM-DD')
          : undefined;
        const age = moment().diff(dateOfBirth, 'years', false);
        await dataSource.handleCustomRequest({
          paramRequest: {
            url: 'v1/event/contacts/relation',
            method: 'GET',
            baseURL: process.env.REACT_APP_BASE_URL,
            params: {
              name: values?.contact_relative?.name,
              prefix_phone: !!values?.contact_relative?.phone_number
                ? values?.contact_relative?.prefix_phone
                : undefined,
              phone_number: age >= 12 ? values?.contact_relative?.phone_number : undefined,
              date_of_birth: age < 12 ? formatDateOfBirth : undefined,
            },
          },
          onSuccess({ response }: any) {
            if (!!response && response?.hasOwnProperty('id')) {
              const dateOfBirthContact = response?.date_of_birth ? moment(response?.date_of_birth, 'YYYY-MM-DD') : null;
              const anniversaryDateContact = response?.anniversary_date
                ? moment(response?.anniversary_date, 'YYYY-MM-DD')
                : null;
              const hasAttendIfgf = !!response?.has_attend_ifgf ? 'true' : 'false';
              const hasJoinCareGroup = !!response?.has_join_care_group ? 'true' : 'false';

              //Check User Exist on Relative Users
              const userRelatives = dataGeneral?.relatives ?? [];
              //By Id
              const isIdSameAsMain = !!response && response?.id?.toLowerCase() === dataGeneral?.id?.toLowerCase();
              const isIdSameAsRelative = userRelatives?.some(
                (item) =>
                  !!item?.contact_relative?.id &&
                  item?.contact_relative?.id?.toLowerCase() === response?.id?.toLowerCase(),
              );
              //By Email
              const isEmailSameAsMain =
                !!response && response?.email?.toLowerCase() === dataGeneral?.email?.toLowerCase();
              const isEmailSameAsRelatives = userRelatives?.some(
                (item) =>
                  !!item?.contact_relative?.email &&
                  item?.contact_relative?.email?.toLowerCase() === response?.email?.toLowerCase(),
              );

              if (isIdSameAsMain || isIdSameAsRelative) {
                notificationFailed(['This user has already been relatives!']);
              } else {
                form.setFieldsValue({
                  ...values,
                  is_exist_data: true,
                  contact_relative: {
                    ...response,
                    date_of_birth: dateOfBirthContact,
                    anniversary_date: anniversaryDateContact,
                    has_attend_ifgf: hasAttendIfgf,
                    has_join_care_group: hasJoinCareGroup,
                  },
                });
              }
            } else {
              form.setFieldsValue({
                ...values,
                contact_relative: {
                  has_attend_ifgf: age < 12 ? dataGeneral?.has_attend_ifgf : null,
                  discipleship_center:
                    age < 12 && dataGeneral?.has_attend_ifgf === 'true' ? dataGeneral?.discipleship_center : null,
                },
                is_exist_data: false,
              });
              notificationFailed(['Data not found, Input data for new user!']);
            }
          },
          onFailed({ message }: any) {
            notificationFailed([message]);
          },
        });
      } else {
        notificationFailed(['Please complete your name, date of birth or phone number!']);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingContactRelation(false);
  }

  async function onSubmitChecking() {
    const values = form.getFieldsValue();
    const isExistData = form.getFieldValue(['is_exist_data']);
    if (action === 'create' && [true, undefined].includes(isExistData)) {
      await checkContactsRelation(values);
      const hasExistDataAfterChecking = form.getFieldValue(['is_exist_data']);
      if (hasExistDataAfterChecking) form.submit();
    } else {
      form.submit();
    }
  }

  return (
    <div>
      <div hidden={isDetail}>
        <Button
          onClick={() => {
            setAction('create');
            form.setFieldsValue({
              form_action: 'create',
              contact_relative: {
                prefix_phone: '+62',
                has_join_care_group: 'false',
                has_attend_ifgf: 'false',
              },
            });
            onShowDrawer();
          }}
          icon={
            <AntdIconWrapper>
              <Plus />
            </AntdIconWrapper>
          }
        >
          New Data
        </Button>
      </div>

      <div style={{ marginTop: '10px' }}></div>
      {!isMobile && (
        <Table
          columns={
            isNotAdmin
              ? columns?.filter(
                  (item) => !['date_of_birth', 'email', 'address', 'anniversary_date'].includes(item?.key),
                )
              : columns
          }
          dataSource={transformData(value)}
          pagination={false}
          scroll={{ x: 'fit-content', y: 500 }}
        />
      )}
      {isMobile && (
        <ListComponent
          columns={
            isNotAdmin
              ? columns?.filter(
                  (item) => !['date_of_birth', 'email', 'address', 'anniversary_date'].includes(item?.key),
                )
              : columns
          }
          data={transformData(value)}
          titleColumn={'contact_relative.name'}
        />
      )}

      <Drawer
        title={`${action === 'update' ? 'Update' : 'New'} Relative`}
        width={isMobile ? '100%' : 500}
        visible={visibleDrawer}
        onClose={onCloseDrawer}
        footer={
          <div style={{ display: 'flex' }}>
            <Checkbox checked={isCreateAnother} onChange={(e) => setIsCreateAnother(e.target.checked)}>
              Create another
            </Checkbox>

            <Button style={{ marginLeft: 'auto' }} onClick={() => onCloseDrawer()}>
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => onSubmitChecking()}
              loading={loadingContactRelation}
            >
              Save
            </Button>
          </div>
        }
      >
        <Form form={form} onFinish={onSubmitForm} layout="vertical">
          <Form.Item noStyle name="key"></Form.Item>
          <Form.Item noStyle name="is_exist_data" />
          <Form.Item noStyle name="form_action"></Form.Item>
          <Form.Item noStyle name="id"></Form.Item>
          <Form.Item noStyle name="contact_id"></Form.Item>
          <Form.Item noStyle name="contact_relative_id"></Form.Item>
          <Form.Item noStyle name={['contact_relative', 'id']}></Form.Item>
          <Form.Item noStyle name={['contact_relative', 'is_checker']}></Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue, setFieldsValue }) => {
              const values = getFieldsValue();
              const key = values?.key;
              const contact = values?.contact_relative;
              const hasJoinCareGroup = contact?.has_join_care_group === 'true';
              const hasAttendIfgf = contact?.has_attend_ifgf === 'true';
              const maritalStatus = contact?.marital_status;
              const dateOfBirth = contact?.date_of_birth;
              const same_address_as_main_user = contact?.same_address_as_main_user;
              const same_ifgf_info_as_main_user = contact?.same_ifgf_info_as_main_user;
              const age = moment().diff(dateOfBirth, 'years', false);
              const id = values?.id;
              const action = values?.form_action;
              const totalParent = value?.filter(
                (item) => item?.relation_type?.toLowerCase() === 'parents' && item?.key !== key,
              )?.length;
              const onChangeCareGroup = (value) => {
                setFieldsValue({
                  contact_relative: {
                    ...contact,
                    care_group_leader: null,
                    has_join_care_group: value,
                  },
                });
              };

              const onChangeHasAttend = (value) => {
                const discipleshipCenter = value === 'true' && age < 12 ? dataGeneral?.discipleship_center : null;
                setFieldsValue({
                  contact_relative: {
                    ...contact,
                    discipleship_center: isAdmin ? contact?.discipleship_center : discipleshipCenter,
                    has_attend_ifgf: value,
                  },
                });
              };

              const onChangeMaritalStatus = (value) => {
                if (value?.toLowerCase() !== 'married') {
                  setFieldsValue({
                    contact_relative: {
                      ...contact,
                      marital_status: value,
                      anniversary_date: null,
                    },
                  });
                }
              };

              const SameAddressAsMainUser = () => {
                const onChange = (event) => {
                  if (event?.target?.checked) {
                    setFieldsValue({
                      contact_relative: {
                        ...contact,
                        same_address_as_main_user: event.target.checked,
                        address: dataGeneral?.address,
                      },
                    });
                  }
                };
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '5px' }}>Address</span>
                    <Form.Item
                      name={['contact_relative', 'same_address_as_main_user']}
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox onChange={onChange}>
                        <span style={{ fontSize: '9px' }}>Same as main user</span>
                      </Checkbox>
                    </Form.Item>
                  </div>
                );
              };

              const SameIFGFInfoAsMainUser = () => {
                const onChange = (event) => {
                  if (event?.target?.checked) {
                    setFieldsValue({
                      contact_relative: {
                        ...contact,
                        same_ifgf_info_as_main_user: event.target.checked,
                        has_join_care_group: dataGeneral?.has_join_care_group,
                        care_group_leader: dataGeneral?.care_group_leader,
                      },
                    });
                  }
                };
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '5px' }}>Has Join Care Group?</span>
                    <Form.Item
                      name={['contact_relative', 'same_ifgf_info_as_main_user']}
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox onChange={onChange}>
                        <span style={{ fontSize: '9px' }}>Same as main user</span>
                      </Checkbox>
                    </Form.Item>
                  </div>
                );
              };

              const isExistData = values?.is_exist_data;
              const isCreateExistData = action === 'create' && [true, undefined].includes(isExistData);

              const onChangeAddress = (value) => {
                const newRelatives = values?.relatives?.map((item) => {
                  let address = item?.contact_relative?.address;
                  if (item?.contact_relative?.same_address_as_main_user) address = value;
                  return {
                    ...item,
                    contact_relative: {
                      ...item?.contact_relative,
                      address,
                    },
                  };
                });
                setFieldsValue({
                  relatives: newRelatives,
                });
              };
              return (
                <React.Fragment>
                  <FormBuilder
                    columns={[
                      {
                        fields: [
                          {
                            label: 'Relation',
                            gridColumn: { span: 12 },
                            name: ['relation_type'],
                            fieldType: 'selectPaginate',
                            rules: [
                              { required: true, message: 'Relation is require!' },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (value?.toLowerCase() === 'parents' && totalParent >= 2)
                                    return Promise.reject(
                                      new Error('Parental relationship type cannot be more than 2 data!'),
                                    );
                                  else return Promise.resolve();
                                },
                              }),
                            ],
                            selectPaginateProps: {
                              isDisabled: !!id && action === 'update',
                              keySearch: 'search',
                              dataSourceUrl: ApiUrlData.enum_relation,
                              customLabel: (row) => row,
                              customLoadOptions: loadRelationTypeOptions,
                            },
                          },
                          {
                            label: 'Name',
                            gridColumn: { span: 12 },
                            name: ['contact_relative', 'name'],
                            fieldType: 'inputText',
                            rules: [{ required: true, message: 'Name is required!' }],
                            inputTextProps: {
                              allowClear: true,
                            },
                          },
                          {
                            label: 'Date of Birth',
                            gridColumn: { span: 12 },
                            name: ['contact_relative', 'date_of_birth'],
                            rules: [{ required: true, message: 'Date of birth is required!' }],
                            fieldType: 'datePicker',
                            datePickerProps: {
                              style: { width: '100%' },
                            },
                            // customHidden() {
                            //   const isHidden = isCreateExistData ? true : false;
                            //   return isHidden;
                            // },
                          },
                          {
                            label: 'Email',
                            gridColumn: { span: 12 },
                            name: ['contact_relative', 'email'],
                            fieldType: 'inputText',
                            rules: [
                              { type: 'email', message: 'Email is not a valid email' },
                              { required: isCreateExistData ? false : age >= 12, message: 'Email is required!' },
                              {
                                validator(_, value) {
                                  const actionType = values?.form_action;
                                  const userRelatives = dataGeneral?.relatives ?? [];
                                  const isEmailSameAsMain =
                                    !!value && value?.toLowerCase() === dataGeneral?.email?.toLowerCase();
                                  const isEmailSameAsRelatives = userRelatives?.some(
                                    (item) =>
                                      !!item?.contact_relative?.email &&
                                      ((actionType === 'update' && item?.key !== values?.key) ||
                                        actionType === 'create') &&
                                      item?.contact_relative?.email?.toLowerCase() === value?.toLowerCase(),
                                  );

                                  if (!isEmailSameAsMain && !isEmailSameAsRelatives) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('Email cannot be the same as the main or relatives email'),
                                  );
                                },
                              },
                            ],
                            inputTextProps: {
                              allowClear: true,
                            },
                            customHidden() {
                              const isHideByAge = age >= 12 ? false : true;
                              const isHidden = isCreateExistData ? true : isHideByAge;
                              return isHidden;
                            },
                          },
                          {
                            label: 'Password',
                            gridColumn: { span: 12 },
                            name: ['contact_relative', 'password'],
                            fieldType: 'inputPassword',
                            // rules: [
                            //   ({ getFieldValue }) => ({
                            //     validator(_, value) {
                            //       const action = getFieldValue('form_action');
                            //       if (action === 'create' || action === 'duplicate') {
                            //         if (!value) return Promise.reject(new Error('Password is required!'));
                            //         else return Promise.resolve();
                            //       } else return Promise.resolve();
                            //     },
                            //   }),
                            // ],
                            inputPasswordProps: {
                              allowClear: true,
                              autoComplete: 'new-password',
                            },
                            customHidden() {
                              const isHideByAge = age >= 12 ? false : true;
                              const isHidden = isCreateExistData ? true : isHideByAge;
                              return isHidden;
                            },
                            rules: [
                              {
                                required: isCreateExistData
                                  ? false
                                  : isNotAdmin && age >= 12 && !!values?.contact_relative?.email
                                  ? true
                                  : false,
                                message: 'Password is required.',
                              },
                            ],
                          },
                          {
                            label: 'Confirm Password',
                            gridColumn: { span: 12 },
                            name: ['contact_relative', 'retype_password'],
                            fieldType: 'inputPassword',
                            dependencies: ['password'],
                            hasFeedback: true,
                            rules: [
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const password = getFieldValue(['contact_relative', 'password']);
                                  if (!password) return Promise.resolve();
                                  else if (password === value) return Promise.resolve();
                                  else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                                  else return Promise.reject(new Error("Passwords doesn't match!"));
                                },
                              }),
                              {
                                required: isCreateExistData
                                  ? false
                                  : isNotAdmin &&
                                    age >= 12 &&
                                    !!values?.contact_relative?.email &&
                                    !!values?.contact_relative?.password
                                  ? true
                                  : false,
                                message: 'Confirm Password is required.',
                              },
                            ],
                            inputPasswordProps: {
                              allowClear: true,
                              autoComplete: 'new-password',
                            },
                            customHidden() {
                              const isHideByAge = age >= 12 ? false : true;
                              const isHidden = isCreateExistData ? true : isHideByAge;
                              return isHidden;
                            },
                          },
                          {
                            label: 'Marital Status',
                            gridColumn: { span: 12 },
                            name: ['contact_relative', 'marital_status'],
                            fieldType: 'selectPaginate',
                            rules: [
                              { required: isCreateExistData ? false : true, message: 'Marital status is required!' },
                            ],
                            selectPaginateProps: {
                              keySearch: 'search',
                              dataSourceUrl: ApiUrlData.enum_marital_status,
                              customLabel(row) {
                                return row;
                              },
                              onChange: onChangeMaritalStatus,
                            },
                            customHidden() {
                              const isHidden = isCreateExistData ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            label: isMobile ? 'Anniv. Date' : 'Anniversary Date',
                            gridColumn: { span: 12 },
                            rules: [
                              {
                                required: isCreateExistData
                                  ? false
                                  : maritalStatus && maritalStatus?.toLowerCase() === 'married',
                                message: 'Anniversary date is required!',
                              },
                            ],
                            customHidden() {
                              const isHideByMaritalStatus = maritalStatus?.toLowerCase() !== 'married';
                              const isHidden = isCreateExistData ? true : isHideByMaritalStatus;
                              return isHidden;
                            },
                            datePickerProps: {
                              style: { width: '100%' },
                            },
                            name: ['contact_relative', 'anniversary_date'],
                            fieldType: 'datePicker',
                          },
                          {
                            gridColumn: {
                              span: 12,
                              // age >= 12 || (!!maritalStatus && maritalStatus?.toLowerCase() === 'married') ? 12 : 8,
                            },
                            label: 'Gender',
                            name: ['contact_relative', 'gender'],
                            rules: [{ required: isCreateExistData ? false : true, message: 'Gender is required!' }],
                            fieldType: 'select',
                            selectProps: {
                              isClearable: false,
                              options: OptionGender,
                              customLabel: (value) =>
                                OptionGender.find((item) => item.value === value?.toLowerCase())?.label,
                            },
                            customHidden() {
                              const isHidden = isCreateExistData ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            gridColumn: {
                              span: 12,
                              // age >= 12 || (!!maritalStatus && maritalStatus?.toLowerCase() === 'married') ? 24 : 16,
                            },
                            customHidden() {
                              return age >= 12 ? false : true;
                            },
                            renderField() {
                              return (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div style={{ width: !isNotAdmin ? 'calc(100%)' : '100%' }}>
                                    <Form.Item
                                      label="Phone Number"
                                      name={['contact_relative', 'phone_number']}
                                      rules={[
                                        {
                                          pattern: /^[1-9][0-9]*$/,
                                          message: 'Phone number is invalid and cannot start with 0!',
                                        },
                                        { required: age >= 12 ? true : false, message: 'Phone number is required!' },
                                        {
                                          validator(_, value) {
                                            const actionType = values?.form_action;
                                            const userRelatives = dataGeneral?.relatives ?? [];
                                            const prefixPhone = values?.contact_relative?.prefix_phone;
                                            const isPhoneNumberSameAsMain =
                                              !!value &&
                                              prefixPhone === dataGeneral?.prefix_phone &&
                                              value === dataGeneral?.phone_number;
                                            const isPhoneNumberSameAsRelatives = userRelatives?.some(
                                              (item) =>
                                                !!item?.contact_relative?.phone_number &&
                                                ((actionType === 'update' && item?.key !== values?.key) ||
                                                  actionType === 'create') &&
                                                item?.contact_relative?.prefix_phone === prefixPhone &&
                                                item?.contact_relative?.phone_number === value,
                                            );

                                            if (!isPhoneNumberSameAsMain && !isPhoneNumberSameAsRelatives) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(
                                              new Error(
                                                'Phone Number cannot be the same as the main or relatives phone number',
                                              ),
                                            );
                                          },
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Input" allowClear addonBefore={prefixPhoneSelector} />
                                    </Form.Item>
                                  </div>
                                  {/* {!isNotAdmin && (
                                    <div style={{ width: '20px', marginTop: '2px' }}>
                                      <Form.Item label="&#160;">
                                        <Tooltip placement="leftTop" title="Get Existing Data">
                                          <Button
                                            loading={loadingContactRelation}
                                            icon={<InfoCircleOutlined />}
                                            onClick={() => checkContactsRelation(values)}
                                            type="primary"
                                          />
                                        </Tooltip>
                                      </Form.Item>
                                    </div>
                                  )} */}
                                </div>
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                  <FormBuilder
                    columns={[
                      {
                        fields: [
                          {
                            gridColumn: { span: 12 },
                            renderField() {
                              return (
                                <Form.Item
                                  label={<SameAddressAsMainUser />}
                                  name={['contact_relative', 'address']}
                                  rules={[
                                    { required: isCreateExistData ? false : true, message: 'Address is required!' },
                                  ]}
                                >
                                  <InputAddress
                                    showMapIcon={true}
                                    placeholder="Input address"
                                    onChange={onChangeAddress}
                                    disabled={same_address_as_main_user}
                                  />
                                </Form.Item>
                              );
                            },

                            customHidden() {
                              const isHidden = isCreateExistData ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: <SameIFGFInfoAsMainUser />,
                            name: ['contact_relative', 'has_join_care_group'],
                            fieldType: 'select',
                            rules: [
                              {
                                required: isCreateExistData ? false : !isNotAdmin && !isRegister,
                                message: 'Has join care group is required!',
                              },
                            ],
                            selectProps: {
                              isDisabled: same_ifgf_info_as_main_user,
                              onChange: onChangeCareGroup,
                              isClearable: false,
                              options: OptionsYesOrNo,
                              customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                            },
                            customHidden() {
                              const isHidden = isCreateExistData || isRegister || isNotAdmin ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'Care Group Leader',
                            name: ['contact_relative', 'care_group_leader'],
                            fieldType: 'selectPaginate',
                            customHidden(form) {
                              const hasJoin = form.getFieldValue(['contact_relative', 'has_join_care_group']);
                              const isHideByHasJoinChecking =
                                !hasJoin || hasJoin === 'false' || isRegister || isNotAdmin;
                              const isHidden = isCreateExistData ? true : isHideByHasJoinChecking;
                              return isHidden;
                            },
                            rules: [
                              { required: isCreateExistData ? false : hasJoinCareGroup && !isNotAdmin },
                              // ({ getFieldValue }) => ({
                              //   validator(_, value) {
                              //     const hasJoin = getFieldValue(['contact_relative', 'has_join_care_group']);
                              //     const isRequired = hasJoin === 'true';
                              //     const noValue = !value;
                              //     if (isRequired && noValue)
                              //       return Promise.reject(new Error('Care group leader is required!'));
                              //     return Promise.resolve();
                              //   },
                              // }),
                            ],
                            selectPaginateProps: {
                              keySearch: 'search',
                              isDisabled: same_ifgf_info_as_main_user,
                              dataSourceUrl: ApiUrlData.contact,
                              customLabel(row) {
                                return row?.name;
                              },
                              customFilterRequest: () => {
                                return {
                                  is_leader: true,
                                };
                              },
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'ICC Name',
                            name: ['contact_relative', 'icc'],
                            fieldType: 'selectPaginate',
                            selectPaginateProps: {
                              keySearch: 'search',
                              dataSourceUrl: ApiUrlData.contact,
                              customLabel(row) {
                                return row?.name;
                              },
                              customFilterRequest: () => {
                                return {
                                  is_icc: true,
                                };
                              },
                            },
                            customHidden() {
                              const isHidden = isCreateExistData || isRegister || isNotAdmin ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'IHC Name',
                            name: ['contact_relative', 'ihc'],
                            fieldType: 'selectPaginate',
                            selectPaginateProps: {
                              keySearch: 'search',
                              dataSourceUrl: ApiUrlData.contact,
                              customLabel(row) {
                                return row?.name;
                              },
                              customFilterRequest: () => {
                                return {
                                  is_ihc: true,
                                };
                              },
                            },
                            customHidden() {
                              const isHidden = isCreateExistData || isRegister || isNotAdmin ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'HCC',
                            name: ['contact_relative', 'hcc'],
                            fieldType: 'selectPaginate',
                            selectPaginateProps: {
                              keySearch: 'search',
                              dataSourceUrl: ApiUrlData.contact,
                              customLabel(row) {
                                return row?.name;
                              },
                              customFilterRequest: () => {
                                return {
                                  is_hcc: true,
                                };
                              },
                            },
                            customHidden() {
                              const isHidden = isCreateExistData || isRegister || isNotAdmin ? true : false;
                              return isHidden;
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'Has Attend IFGF',
                            name: ['contact_relative', 'has_attend_ifgf'],
                            fieldType: 'select',
                            rules: [
                              {
                                required:
                                  isNotAdmin && action === 'update'
                                    ? !values?.id
                                      ? true
                                      : false
                                    : isCreateExistData
                                    ? false
                                    : true,
                                message: 'Has attend IFGF is required!',
                              },
                            ],
                            selectProps: {
                              onChange: onChangeHasAttend,
                              isClearable: false,
                              options: OptionsYesOrNo,
                              customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                            },
                            customHidden() {
                              const isHidden = isCreateExistData ? true : false;
                              return isNotAdmin && action === 'update' ? (!values?.id ? false : true) : isHidden;
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'Discipleship Center',
                            name: ['contact_relative', 'discipleship_center'],
                            fieldType: 'selectPaginate',
                            customHidden(form) {
                              const hasAttend = form.getFieldValue(['contact_relative', 'has_attend_ifgf']);
                              const isHideByHasAttendChecking = isAdmin
                                ? false
                                : !hasAttend ||
                                  hasAttend === 'false' ||
                                  ((isRegister || isNotAdmin) &&
                                    !!id &&
                                    ([undefined, null].includes(age) || age >= 12));
                              const isHidden = isCreateExistData ? true : isHideByHasAttendChecking;
                              const isHideOnUpdateNotAdmin = !values?.id ? (hasAttend === 'true' ? false : true) : true;
                              return isNotAdmin && action === 'update' ? isHideOnUpdateNotAdmin : isHidden;
                            },
                            rules: [
                              {
                                required: isAdmin
                                  ? false
                                  : isNotAdmin && action === 'update'
                                  ? !values?.id
                                    ? hasAttendIfgf
                                      ? true
                                      : false
                                    : false
                                  : isCreateExistData
                                  ? false
                                  : hasAttendIfgf &&
                                    (((isRegister || isNotAdmin) &&
                                      (!id || (!!id && ([undefined, null].includes(age) || age < 12)))) ||
                                      !isNotAdmin),
                                message: 'Discipleship Center is required.',
                              },
                              // ({ getFieldValue }) => ({
                              //   validator(_, value) {
                              //     const hasAttend = getFieldValue(['contact_relative', 'has_attend_ifgf']);
                              //     const isRequired = hasAttend === 'true';
                              //     const noValue = !value;
                              //     if (isRequired && noValue)
                              //       return Promise.reject(new Error('Discipleship center is required!'));
                              //     return Promise.resolve();
                              //   },
                              // }),
                            ],
                            selectPaginateProps: {
                              keySearch: 'search',
                              dataSourceUrl: ApiUrlData.region,
                            },
                          },
                          {
                            gridColumn: { span: 12 },
                            label: 'Role Position',
                            name: ['contact_relative', 'role_position'],
                            fieldType: 'selectPaginate',
                            selectPaginateProps: {
                              keySearch: 'code',
                              dataSourceUrl: ApiUrlData.user_level,
                            },
                            customHidden() {
                              const isHidden = isCreateExistData || isRegister || isNotAdmin ? true : false;
                              return isHidden;
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </React.Fragment>
              );
            }}
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
