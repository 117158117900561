import { useEffect, useState } from 'react';
import { RunButton } from '../run-button';
import { ChartRenderer } from './chart-render';

interface vizStateEntity {
  query: any;
  chartType: string;
}
interface Props {
  vizState: vizStateEntity;
}
export function ChartRender(props: Props) {
  const { vizState } = props;
  const [showButtonRun, setShowButtonRun] = useState(true);

  // useEffect(() => {
  //   setShowButtonRun(true);
  // }, [vizState]);

  if (showButtonRun) return <RunButton handleAfterRun={() => setShowButtonRun(false)} />;
  return (
    <div style={{ overflow: 'scroll' }}>
      <ChartRenderer vizState={vizState} />;
    </div>
  );

  // return (
  //   <div>
  //     {showButtonRun && <RunButton handleAfterRun={() => setShowButtonRun(false)} />}
  //     {!showButtonRun && <ChartRenderer vizState={vizState} />}
  //   </div>
  // );
}
