import { WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';
import { PageProvider } from '@eigen3m/react-base-frontend';
import { LoginEntity } from '../../domain/entities';
import LoginPage from '../pages/login';

export default function AuthenticationLoginFactory() {
  const initialValue: InitialValuePageProviderEntity<LoginEntity> = {
    moduleKey: 'authentication-login-page',
    webUrl: WebUrlData.forgot_password,
  };

  return (
    <PageProvider initialValue={initialValue}>
      <LoginPage />
    </PageProvider>
  );
}
