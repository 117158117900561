import { typeOf } from 'mathjs';
import { TreeSelect, TreeSelectProps } from 'antd';

export default function TreeSelectCustom(props: TreeSelectProps) {
  function onChangeValue(values, labelList, extra) {
    if (!values) {
      if (props.onChange) props.onChange(values, labelList, extra);
    } else {
      const valueType = typeOf(values);
      if (valueType === 'Object') {
        const isParent = values.value.includes('is_parent');
        if (props.onChange && !isParent) props.onChange(values, labelList, extra);
        else if (props.onChange && isParent && !props.value) props.onChange(null, labelList, extra);
      } else if (valueType === 'Array') {
        const newValue = values.filter((item) => !item.value.includes('is_parent'));
        if (props.onChange) props.onChange(newValue, labelList, extra);
      }
    }
  }
  return <TreeSelect {...props} onChange={onChangeValue} />;
}
