import { ApiUrlData } from '@base-configs';
import { authHelper, FormBuilder, FormPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import UserForm from '@main/components/user-form';
import { Form, notification, Select, Spin, Tabs } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { mockCompanies } from 'states';
import { RelativeComponent } from '../components/relatives';
import { OptionGender, OptionsYesOrNo } from '../helpers';
import { phoneNumberPrefixOptions } from './helpers';
const { Option } = Select;
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

const prefixPhoneSelector = (
  <Form.Item name="prefix_phone" noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

export default function FormPage() {
  const { isCreate, isUpdate, isDuplicate, navigation, dataSource, detailData } = usePageProvider();
  const [showContent, setShowContent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [companies] = useRecoilState(mockCompanies);
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';
  const account = authHelper.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  async function handleConfirm(payload) {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess(params) {
        // notification.success({
        //   message: 'Confirm Success',
        //   description: `Data confirmed`,
        // });
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Failed',
          description: message?.join(', '),
        });
      },
    });
  }

  async function handleAfterCreate(payload) {
    await handleConfirm(payload);
    navigation.goToDetail({ id: payload?.id });
  }

  async function handleAfterUpdate(account) {
    await handleConfirm(account);
    const existAccount = authHelper.getAccount();
    const accountId = account?.id;
    const existAccountId = existAccount?.id;
    if (account && accountId === existAccountId) authHelper.updateAccount(account);
    navigation.goToDetail({ id: account.id });
  }

  function onChangeForm(item, values) {
    // logic for override form values
    // console.log({ values });
    return undefined;
  }

  const initialValues = {
    is_leader: false,
    is_hcc: false,
    is_ihc: false,
    is_icc: false,
    is_speaker: false,
    prefix_phone: '+62',
    has_join_care_group: 'false',
    has_attend_ifgf: 'false',
    company: !!companies?.length ? companies[0] : null,
    relatives: [],
    date_of_birth: isAdmin ? moment().set({ year: 1901, month: 0, date: 1 }) : null,
    email: isAdmin ? `${uuidV4()}@gantiemail.com` : null,
    password: isAdmin ? 'ifgf1234' : null,
    retype_password: isAdmin ? 'ifgf1234' : null,
    marital_status: isAdmin ? 'Single' : null,
    // anniversary_date: isAdmin ? moment().set({ year: 1901, month: 0, date: 1 }) : null,
    gender: isAdmin ? 'Male' : null,
    phone_number: isAdmin ? `${7000}${Math.floor(Math.random() * 1e16)}` : null,
    address: {
      address: isAdmin ? 'gantialamat' : null,
    },
  };

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      {!showContent && <div style={{ minHeight: '50vh' }}></div>}
      {showContent && (
        <FormPageWrapper
          pageToolbarProps={{
            pageTitle: `${title} User`,
            handleClickReload() {
              setShowContent(false);
              setLoading(true);
              setTimeout(() => {
                setShowContent(true);
                setLoading(false);
              }, 200);
            },
          }}
          onChangeFormValues={onChangeForm}
          handleAfterUpdate={handleAfterUpdate}
          ignoreKeyUpdate={['password']}
          ignoreKeyDuplicate={['password', 'relatives', 'id']}
          formInstance={{ initialValues: initialValues }}
          handleAfterCreate={handleAfterCreate}
        >
          <UserForm isCreate={isCreate} isUpdate={isUpdate} />
        </FormPageWrapper>
      )}
    </Spin>
  );
}
