import { EnumColorStatusData, EnumStatusData } from '@eigen3m/react-base-frontend';

export function makeColorStatus(status) {
  if (status === EnumStatusData.active) return EnumColorStatusData.active;
  else if (status === EnumStatusData.inactive) return EnumColorStatusData.inactive;
  return '';
}

export function isScrollAtBottom({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean {
  return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
}
