import { ApiUrlData, WebUrlData } from '@base-configs';
import {
  authHelper,
  FormBuilder,
  FormPageWrapper,
  usePageProvider,
  PageToolbar,
  PageToolbarProps,
  makeCommonDataSource,
  notificationFailed,
} from '@eigen3m/react-base-frontend';
import UserForm from '@main/components/user-form';
import { Button, Drawer, Form, Input, notification, Popover, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { mockCompanies } from 'states';
import { LoginOutlined } from '@ant-design/icons';
import { UserTransformer } from '../../domain/transformer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { decode } from 'punycode';
import { parseJwt } from './helpers';

export default function FormPage() {
  const navigate = useNavigate();
  const { isCreate, isUpdate, isDuplicate, navigation } = usePageProvider();
  const [loading, setLoading] = useState(false);
  const userDataSource = makeCommonDataSource({
    baseUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    apiUrl: ApiUrlData.contact,
  });
  const [companies] = useRecoilState(mockCompanies);
  const [form] = Form.useForm();
  const title = isCreate ? 'New' : isUpdate ? 'Update' : isDuplicate ? 'Duplicate' : '';
  const { token } = useParams();
  const dataSourceLoginToken = makeCommonDataSource({ apiUrl: ApiUrlData.user_login_token });

  function handleAfterUpdate(account) {
    const existAccount = authHelper.getAccount();
    const accountId = account?.id;
    const existAccountId = existAccount?.id;
    if (account && accountId === existAccountId) authHelper.updateAccount(account);
    navigation.goToDetail({ id: account.id });
  }

  function onChangeForm(item, values) {
    // console.log({ values });
    // logic for override form values
    // return undefined;
  }

  const initialValues = {
    is_leader: false,
    is_hcc: false,
    is_ihc: false,
    is_icc: false,
    is_speaker: false,
    prefix_phone: '+62',
    has_attend_ifgf: 'false',
    has_join_care_group: 'false',
    company: !!companies?.length ? companies[0] : null,
    relatives: [],
  };

  const toolbarProps: PageToolbarProps = {
    showBack: false,
    showReload: false,
    showSearch: false,
    showDataTypeSelector: false,
    showConfirmProcess: false,
    showApproval: false,
    showUpdate: false,
    showDuplicate: false,
    showDelete: false,
    showActivate: false,
    showDeactivate: false,
    showExport: false,
    showImport: false,
    showPrint: false,
    showFilter: false,
    showNewData: false,
    pageTitle: 'Register User',
  };

  const onReset = () => {
    form.resetFields();
    hide();
  };

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  async function handleConfirm(payload) {
    await userDataSource.handleConfirmProcess(payload, {
      onSuccess(params) {
        notification.success({
          message: 'Registration Success',
          description: `You can now login to your account. Please check your email to see your QR Codes`,
        });
        navigate(WebUrlData.login);
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Failed',
          description: message?.join(', '),
        });
      },
    });
    setLoading(false);
  }

  async function onFinish(payload?: any) {
    console.log(payload);
    setLoading(true);
    const transformer = new UserTransformer();
    const data = transformer.transformerUpdate(payload);
    await userDataSource.handleCustomRequest({
      paramRequest: {
        method: token ? 'PUT' : 'POST',
        url: `${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.contact}/${payload?.id ?? ''}`,
        data: data,
      },
      onSuccess(params) {
        handleConfirm(params.response);
      },
      onFailed({ message }: any) {
        notification.error({
          message: 'Failed',
          description: message?.join(', '),
        });
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (isCreate) form.setFieldsValue(initialValues);
  }, [isCreate]);

  async function registerToken(token) {
    setLoading(true);
    await dataSourceLoginToken.handleCustomRequest({
      paramRequest: {
        method: 'POST',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.user_login_token,
        data: { token },
      },
      async onSuccess({ response }: any) {
        // const googleInfo = parseJwt(response?.jwt);
        const user = response?.user;
        form.setFieldsValue({
          email: user?.username,
          name: user?.code,
          id: user?.id,
          ...(response?.company ? { company: user?.company } : {}),
        });
        setLoading(false);
      },
      async onFailed({ message }) {
        await authHelper.clearStorageLogout();
        notificationFailed([message as string]);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      registerToken(decodeToken);
    }
  }, [token]);

  return (
    <div>
      {/* <PageToolbar {...toolbarProps}></PageToolbar> */}
      <Drawer
        title="User Registration"
        closable={false}
        closeIcon={null}
        width={'100%'}
        visible={true}
        extra={
          <Space>
            <Link to={'/auth/login'}>
              <Button ghost icon={<LoginOutlined />} type="primary" disabled={loading}>
                Login
              </Button>
            </Link>
          </Space>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Popover
              content={
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button size="small" onClick={hide} style={{ marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button size="small" danger ghost onClick={onReset}>
                    Yes
                  </Button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
              open={open && !loading}
              onOpenChange={handleOpenChange}
            >
              <Button disabled={loading} danger style={{ marginLeft: 'auto' }} onClick={() => handleOpenChange(true)}>
                Reset
              </Button>
            </Popover>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => form.submit()}
              disabled={loading}
              loading={loading}
            >
              Sign Up
            </Button>
          </div>
        }
      >
        {companies?.length > 0 && (
          <Form
            initialValues={initialValues}
            onValuesChange={onChangeForm}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item shouldUpdate noStyle>
              {({ getFieldsValue }) => {
                const values = getFieldsValue();
                return (
                  <React.Fragment>
                    <Form.Item noStyle name="company"></Form.Item>
                    <Form.Item noStyle name="id"></Form.Item>
                  </React.Fragment>
                );
              }}
            </Form.Item>
            <UserForm isCreate={true} isRegister={true} />
          </Form>
        )}
      </Drawer>
    </div>
  );
}
