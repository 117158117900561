import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';

import { typeOf } from 'mathjs';
import { Row, Col, InputNumber, Form, FormInstance } from 'antd';
import { GOOGLE_MAPS_KEY } from './google-key';
import { SelectPaginate } from '@eigen3m/react-base-frontend';
import { BASE_REGION_URL } from './helpers';
import { AddressAutoComplete } from './address-auto-complete';
import { omit } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import './map.style.less';

interface Props {
  mapsClassName?: string;
  hideMap?: boolean;
  form: FormInstance;
  triggerMapKey?: string;
}

function errorMaps({ className, isError }) {
  return (
    <div className={`${className ? className : ''} error-display-maps-default`}>
      <div className="tex-center">
        {isError && (
          <React.Fragment>
            <div className="d-flex justify-content-center">
              <i className="mdi mdi-block-helper fs-20"></i>
            </div>
            <div>
              <p>Failed to display maps, please check your connection!</p>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export function MapPicker(props: Props) {
  const { mapsClassName, hideMap, form, triggerMapKey } = props;
  // setup ref map
  const mapRef: any = useRef();

  // setup selected for show information on marker clicked
  const [selected, setSelected] = useState(null);

  // get callback on load map
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  //setup google maps api key
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: ['places'],
  });

  // set map container style
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };

  // setup position center on map
  const [center, setCenter] = useState({
    lat: -6.917464,
    lng: 107.619125,
  });

  // setup additional options for maps
  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  // setup maker location
  const [marker, setMarker] = useState({
    lat: center.lat,
    lng: center.lng,
    address: '',
    address_components: [],
    time: new Date(),
  });

  function triggerMarker() {
    const lat = form.getFieldValue('latitude') ?? center.lat;
    const lng = form.getFieldValue('longitude') ?? center.lng;
    mapRef?.current?.setZoom(13);
    setMarker({ lat, lng, time: new Date() } as any);
    setCenter({ lat, lng } as any);
  }

  useEffect(() => {
    if (triggerMapKey) triggerMarker();
  }, [triggerMapKey]);

  // action when marker clicked and will show info location
  function handleClickMarker(marker) {
    setSelected(marker);
  }

  async function onChangeForm(item, values) {
    const keyChange = Object.keys(item)[0];
    const dataChange = item[keyChange];
    if (keyChange === 'address') {
      const address = item[keyChange];

      if (address.latitude && address.longitude) {
        const lat = typeOf(address.latitude) === 'string' ? Number(address.latitude) : address.latitude;
        const lng = typeOf(address.longitude) === 'string' ? Number(address.longitude) : address.longitude;
        mapRef?.current?.setZoom(13);
        mapRef.current.panTo({ lat, lng });
        setMarker({ lat, lng, time: new Date() } as any);
      }
      form.setFieldsValue({ ...address });
      setSelected(null);
    } else if (keyChange === 'country' || keyChange === 'province' || keyChange === 'city') {
      const realLat = dataChange?.latitude;
      const realLng = dataChange?.longitude;
      const newValues = {
        ...values,
        latitude: typeOf(realLat) === 'string' ? parseFloat(realLat) : realLat,
        longitude: typeOf(realLng) === 'string' ? parseFloat(realLng) : realLng,
      };

      if (keyChange === 'country') {
        Object.assign(newValues, { province: undefined, city: undefined, postal_code: undefined });
      } else if (keyChange === 'province') {
        Object.assign(newValues, { city: undefined, postal_code: undefined });
      }

      if (newValues.latitude && newValues.longitude) {
        mapRef?.current?.setZoom(13);
        mapRef.current.panTo({ lat: newValues.latitude, lng: newValues.longitude });
        setMarker({ lat: newValues.latitude, lng: newValues.longitude, time: new Date() } as any);
      }
      form.setFieldsValue(newValues);
      setSelected(null);
    }
  }

  if (loadError) return errorMaps({ className: mapsClassName, isError: true });
  if (!isLoaded) return errorMaps({ className: mapsClassName, isError: true });

  return (
    <div id="google-maps-wrapper">
      <Form layout="vertical" form={form} onValuesChange={onChangeForm}>
        <Row gutter={[8, 2]}>
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                  <Form.Item name="address" label="Search" tooltip="Press enter or click item for select address">
                    <AddressAutoComplete placeholder="Search location" />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Col xs={22} sm={11} md={11} lg={5} xl={4}>
            <Form.Item name="country" label="Country">
              <SelectPaginate
                baseDataSourceUrl={BASE_REGION_URL}
                dataSourceUrl="/api/countries"
                isClearable={true}
                placeholder="Choose country"
                keySearch="search"
                customKey={() => uuidV4()}
                customLabel={(row) => row.name ?? row.long_name}
                transformOptions={(opts = []) => {
                  const newOpts = opts.map((item) =>
                    omit(item, ['emoji', 'emojiU', 'native', 'timezones', 'translations']),
                  );
                  return newOpts;
                }}
              />
            </Form.Item>
          </Col>

          <Form.Item dependencies={['country']} noStyle>
            {({ getFieldValue }) => {
              const country = getFieldValue('country');
              const countryId = country?.id;
              return (
                <Col xs={22} sm={11} md={11} lg={5} xl={4}>
                  <Form.Item name="province" label="Province">
                    <SelectPaginate
                      isDisabled={!countryId}
                      baseDataSourceUrl={BASE_REGION_URL}
                      dataSourceUrl="/api/provinces"
                      isClearable={true}
                      keySearch="search"
                      placeholder="Choose province"
                      customLabel={(row) => row.name ?? row.long_name}
                      key={countryId}
                      transformOptions={(opts = []) => {
                        const newOpts = opts.map((item) => omit(item, ['country']));
                        return newOpts;
                      }}
                      filterRequest={{
                        country_ids: [countryId],
                      }}
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Form.Item dependencies={['province', 'country']} noStyle>
            {({ getFieldValue }) => {
              const country = getFieldValue('country');
              const countryId = country?.id;

              const province = getFieldValue('province');
              const provinceId = province?.id;

              return (
                <Col xs={22} sm={11} md={11} lg={5} xl={4}>
                  <Form.Item name="city" label="CIty">
                    <SelectPaginate
                      isDisabled={!provinceId}
                      baseDataSourceUrl={BASE_REGION_URL}
                      dataSourceUrl="/api/cities"
                      isClearable={true}
                      keySearch="search"
                      placeholder="Choose city"
                      key={provinceId}
                      customLabel={(row) => row.name ?? row.long_name}
                      transformOptions={(opts = []) => {
                        const newOpts = opts.map((item) => omit(item, ['country', 'province']));
                        return newOpts;
                      }}
                      filterRequest={{
                        country_ids: [countryId],
                        province_ids: [provinceId],
                      }}
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Col xs={22} sm={11} md={11} lg={5} xl={4}>
            <Form.Item name="postal_code" label="Postal Code">
              <InputNumber placeholder="Input longitude" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={22} sm={11} md={11} lg={5} xl={4}>
            <Form.Item name="latitude" label="Latitude">
              <InputNumber placeholder="Input latitude" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={22} sm={11} md={11} lg={5} xl={4}>
            <Form.Item name="longitude" label="Longitude">
              <InputNumber placeholder="Input longitude" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ marginBottom: '10px' }}></div>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        {!hideMap && (
          <div className={`${mapsClassName ? mapsClassName : 'default-maps-wrapper'}`}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={9}
              center={center}
              options={options}
              // onClick={(event) => handleClickMaps(event)}
              onLoad={onMapLoad}
              mapContainerClassName="container-google-maps"
            >
              <Marker
                key={marker.time.toISOString()}
                position={{ lat: marker.lat, lng: marker.lng }}
                onClick={() => handleClickMarker(marker)}
              />
              {selected && (
                <InfoWindow position={{ lat: selected.lat, lng: selected.lng }} onCloseClick={() => setSelected(null)}>
                  <div>{marker.address}</div>
                </InfoWindow>
              )}
            </GoogleMap>
          </div>
        )}
      </div>
    </div>
  );
}
