export const ApiUrlData = {
  template: 'v1/users',
  contact: 'v1/event/contacts',
  report: 'v1/event/play-grounds',
  user_level: 'v1/event/users/levels',
  region: 'v1/event/regions',
  event: 'v1/event/event-data',
  companies: 'v1/event/constants/mock-up/companies',
  check_in: 'v1/event/check-ins',
  check_in_data: 'v1/event/check-ins/times',

  enum_gender: 'v1/event/constants/enum/gender',
  enum_relation: 'v1/event/constants/enum/relations',
  enum_event_type: 'v1/event/constants/enum/event-types',
  mock_company: 'v1/event/constants/mock-up/companies',

  enum_marital_status: 'v1/event/constants/enum/marital-status',
  enum_registration_type: 'v1/event/constants/enum/registration-type',
  enum_price_configuration: 'v1/event/constants/enum/price-configuration',
  enum_attendance_type: 'v1/event/constants/enum/attendance-type',
  enum_job_position_i_care: 'v1/event/constants/enum/job-position/i-care',
  enum_job_position_non_i_care: 'v1/event/constants/enum/job-position/non-i-care',
  enum_department: 'v1/event/constants/enum/departments',
  enum_ministry: 'v1/event/constants/enum/ministries',
  enum_discipleship_center: 'v1/event/constants/enum/discipleship-center',

  user_login: 'v1/event/auth',
  user_login_token: 'v1/event/auth/login-token',
  login_google: 'v1/event/auth/google',
  register_google: 'v1/event/auth/register/google',
  register_token: 'v1/event/auth/register-token',
  forgot_password: 'v1/event/auth/forgot-password',
};
