import React, { useMemo, useRef } from 'react';
import moment from 'moment';
import { Badge, Button, Form, Input, Spin } from 'antd';
import { useCubeQuery } from '@cubejs-client/react';
import { queryTransformer } from '../../../../helpers';
import { stringFormatter } from '@eigen3m/react-base-frontend';

// AG Grid React components & library
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
// AG Grid styles
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.prototype.isDisplayWatermark = () => {
  return false;
};

LicenseManager.prototype.validateLicense = () => {
  // Hehe boys
};

interface Props {
  configuration?: any;
}

const KeyIndexDate = ['created', 'created_at', 'last_update'];

export function TableRenderer(props: Props) {
  const { configuration } = props;
  const query = queryTransformer(configuration.query) ?? {};
  const resultQuery = useCubeQuery(query);
  const { isLoading, resultSet } = resultQuery;
  const gridRef: any = useRef();

  const rowData = resultSet?.tablePivot() ?? [];

  function transformRowData(data = []) {
    return data.map((row) =>
      Object.keys(row).reduce((object, key) => {
        const dataIndex = key.split('.')[1];
        let value = row[key];
        const isDate = KeyIndexDate.includes(dataIndex) || dataIndex?.includes('date');
        if (isDate && /^\d+$/.test(value)) value = moment(value);

        return {
          ...object,
          [key.replace('.', '-')]: value,
        };
      }, {}),
    );
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 250,
          maxWidth: 400,
          width: 250,
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 250,
          maxWidth: 400,
          width: 250,
        },
      ],
      // defaultToolPanel: 'columns',
    };
  }, []);

  function renderStatus(value: any): any {
    const StatusConfig = [
      { status: 'done', color: 'green' },
      { status: 'open', color: 'geekblue' },
      { status: 'in_process', color: 'geekblue' },
      { status: 'waiting', color: 'yellow' },
      { status: 'cancel', color: 'red' },
      { status: 'closed', color: 'red' },
      { status: 'draft', color: '#ccc' },
    ];

    return stringFormatter.capitalizeEachWord(value?.split('_')?.join(' '));
    return (
      <Badge
        color={StatusConfig.find((item) => item.status === value)?.color ?? ''}
        text={stringFormatter.capitalizeEachWord(value?.split('_')?.join(' '))}
      />
    );
  }

  function customRender(props) {
    const value = props?.value;
    const dataIndex: string = props?.colDef?.field?.split('-')[1];
    const isStatus = dataIndex?.toLowerCase() === 'status';

    if (isStatus) return renderStatus(value);
    return value ?? null;
  }

  function customFilter(fieldItem) {
    const detectFilterType = fieldItem.split('.')[1].split('__')[1];
    switch (detectFilterType) {
      case 'number': {
        return 'agNumberColumnFilter';
        break;
      }
      case 'string': {
        return 'agTextColumnFilter';
        break;
      }
      case 'date': {
        return 'agDateColumnFilter';
        break;
      }
      default: {
        return 'agCheckColumnFilter';
        break;
      }
    }
  }

  function makeColumnDefs(query: any): any {
    if (!query) return [];
    return [...query.dimensions, ...query.measures].map((fieldItem) => {
      const headerName = stringFormatter.capitalizeEachWord(
        fieldItem.split('.')[1].split('__')[0].split('_').join(' '),
      );
      const field = fieldItem.replace('.', '-');
      const name = field.replace('-', '.');
      const isDimension = Object.values(query.dimensions).indexOf(name) !== -1;
      const isMeasure = Object.values(query.measures).indexOf(name) !== -1;

      return {
        headerName,
        field,
        enablePivot: true,
        enableRowGroup: isDimension,
        enableValue: isMeasure,
        pivot: headerName === 'status',
        rowGroup: headerName === 'name',
        allowedAggFuncs: ['sum', 'max', 'avg', 'min'],
        aggFunc: isMeasure ? 'sum' : null,
        filter: customFilter(fieldItem),
        cellRenderer: customRender,
      };
    });
  }

  function checkRef() {
    console.log({ gridRef });
  }

  return (
    <Spin spinning={isLoading}>
      {/* <Button onClick={checkRef}>Check</Button> */}
      <div className="ag-theme-balham" style={{ height: 700 }}>
        <AgGridReact
          ref={gridRef}
          sideBar={sideBar}
          rowData={transformRowData(rowData)}
          columnDefs={makeColumnDefs(query)}
          autoGroupColumnDef={{ minWidth: 250 }}
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            sortable: true,
            resizable: true,
          }}
          aggFuncs={{
            min: ({ values }) => values.reduce((min, value) => Math.min(min, Number(value)), 0),
            max: ({ values }) => values.reduce((max, value) => Math.max(max, Number(value)), 0),
            sum: ({ values }) => values.reduce((sum, value) => sum + Number(value), 0),
            avg: ({ values }) => (values.reduce((sum, value) => sum + Number(value), 0) / values.length).toFixed(0),
          }}
        />
      </div>
    </Spin>
  );
}
