import { WebUrlData } from '@base-configs';
import { AntdIconWrapper } from '@eigen3m/react-base-frontend';
import { AiOutlineQrcode } from 'react-icons/ai';
import { FiUserCheck, FiUsers } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';

export const itemMenu = [
  // {
  //   label: 'QR',
  //   key: WebUrlData.qr,
  //   icon: (
  //     <AntdIconWrapper>
  //       <AiOutlineQrcode />
  //     </AntdIconWrapper>
  //   ),
  // },
  {
    label: 'User',
    key: WebUrlData.users,
    icon: (
      <AntdIconWrapper>
        <FiUsers />
      </AntdIconWrapper>
    ),
  },
  {
    label: 'Event',
    key: WebUrlData.event,
    icon: (
      <AntdIconWrapper>
        <FiUsers />
      </AntdIconWrapper>
    ),
  },
  {
    label: 'Attendance',
    key: WebUrlData.attendance,
    icon: (
      <AntdIconWrapper>
        <FiUserCheck />
      </AntdIconWrapper>
    ),
  },
  {
    label: 'Report',
    key: WebUrlData.report,
    icon: (
      <AntdIconWrapper>
        <HiOutlineDocumentReport />
      </AntdIconWrapper>
    ),
  },
];
