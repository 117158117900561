import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { AutoComplete } from 'antd';
const { Option } = AutoComplete;
import { MapPin } from 'phosphor-react';
import { generateFormateAddress } from './helpers';

interface AutoCompleteAddressProps {
  value?: any;
  onChange?(value: any): void;
  placeholder?: string;
  disabled?: boolean;
}

export function AddressAutoComplete(props: AutoCompleteAddressProps) {
  const { value, onChange, placeholder, disabled } = props;

  const autoComplete = usePlacesAutocomplete();
  const ready = autoComplete.ready;
  const addressValue = autoComplete.value;
  const { status, data }: any = autoComplete.suggestions;
  const setValue = autoComplete.setValue;
  const clearSuggestions = autoComplete.clearSuggestions;
  const disableField = !ready || disabled;

  function handleChange({ address, ...props }) {
    setValue(address);
    if (onChange) onChange({ address, ...props });
  }

  async function handleSelect(address) {
    clearSuggestions();
    try {
      const result = await getGeocode({ address });
      const { lat, lng } = await getLatLng(result[0]);
      const generateAddress = await generateFormateAddress({ lat, lng });
      handleChange(generateAddress);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (value?.address) setValue(value.address);
    else setValue(value);
  }, [value]);

  return (
    <div style={{ width: '100%' }}>
      <AutoComplete
        disabled={disableField}
        value={addressValue}
        placeholder={placeholder}
        onChange={(address) => handleChange({ address, latitude: undefined, longitude: undefined })}
        onSelect={handleSelect}
      >
        {status === 'OK' &&
          data.map(({ description, place_id }) => (
            <Option key={place_id} value={description}>
              <span>
                <MapPin style={{ marginRight: '10px' }} />
                {description}
              </span>
            </Option>
          ))}
      </AutoComplete>
    </div>
  );
}
