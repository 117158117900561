import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageForm from '../pages/form';

import { UserEntity } from '../../domain/entities';
import { makeUserTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';

export default function FactoryPage() {
  const transformer = makeUserTransformer<UserEntity>();
  const InitialValue: InitialValuePageProviderEntity<UserEntity> = {
    transformer,
    baseApiUrl: process.env.REACT_APP_BASE_SERVICE_URL,
    moduleKey: 'data-register-page',
    webUrl: WebUrlData.register,
    apiUrl: ApiUrlData.contact,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageCreate={PageForm} PageIndex={PageForm} />
    </PageProvider>
  );
}
