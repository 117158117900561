import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageDetail from '../pages/detail';

import { AttendanceEntity } from '../../domain/entities';
import { makeAttendanceTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';

export default function FactoryPage() {
  const transformer = makeAttendanceTransformer<AttendanceEntity>();
  const InitialValue: InitialValuePageProviderEntity<AttendanceEntity> = {
    transformer,
    moduleKey: 'data-user-page',
    webUrl: WebUrlData.attendance,
    apiUrl: ApiUrlData.check_in,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} PageDetail={PageDetail} />
    </PageProvider>
  );
}
