import { RecoilRoot } from 'recoil';
import AppRoutes from '@main/routes/app-routes';

export function App(props: any) {
  const basePath = props.baseUrl ?? '/';
  console.log('version', process.env.REACT_APP_VERSION);
  return (
    <RecoilRoot>
      <AppRoutes basePath={basePath} {...props} />
    </RecoilRoot>
  );
}
