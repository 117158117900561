import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import styled from 'styled-components';

import { CubeLoader } from '../../atoms';
import { PlaySquareOutlined } from '@ant-design/icons';

const Positioner = styled.div`
  min-height: 400px;
  max-height: 800px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

interface Props {
  handleAfterRun?(): void;
}
export function RunButton(props: Props) {
  const { handleAfterRun } = props;
  const [loadingRun, setLoadingRun] = useState(false);

  function handleRun() {
    setLoadingRun(true);
    setTimeout(() => {
      setLoadingRun(false);
      if (handleAfterRun) handleAfterRun();
    }, 1000);
  }

  return (
    <div style={{ position: 'relative' }}>
      {!loadingRun && (
        <Positioner>
          <Centered>
            <Button
              data-testid="run-query-btn"
              size="large"
              type="primary"
              icon={<PlaySquareOutlined />}
              onClick={() => handleRun()}
            >
              Run
            </Button>
          </Centered>
        </Positioner>
      )}
      {loadingRun && (
        <Positioner>
          <Centered>
            <Wrapper>
              <CubeLoader full={false} />
            </Wrapper>
          </Centered>
        </Positioner>
      )}
    </div>
  );
}
