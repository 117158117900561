import { PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';

import { EventEntity } from '../../domain/entities';
import { makeEventTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';

export default function FactoryPage() {
  const transformer = makeEventTransformer<EventEntity>();
  const InitialValue: InitialValuePageProviderEntity<EventEntity> = {
    transformer,
    moduleKey: 'data-event-page',
    webUrl: WebUrlData.event,
    apiUrl: ApiUrlData.event,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
