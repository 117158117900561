import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import {
  IndexPageWrapper,
  stringFormatter,
  AntdIconWrapper,
  useMediaQuery,
  usePageProvider,
  notificationSuccess,
  notificationFailed,
  authHelper,
} from '@eigen3m/react-base-frontend';
import CheckInData from '@pages/check-in-data/presentation/factories';
import { Filter } from '../components/filter';
import React from 'react';
import { Button, Card, Popconfirm } from 'antd';
import { BiEdit, BiTrashAlt } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { AntdDataTable } from '@main/components/data-table-component';

export default function IndexPage() {
  const account = authHelper?.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;
  const navigate = useNavigate();
  const {
    transformer,
    filterDataTable,
    dataSource,
    recordDataTable,
    setRecordDataTable,
    metaDataTable,
    setMetaDataTable,
  } = usePageProvider();

  async function handleDelete(payload): Promise<void> {
    await dataSource.handleDelete(payload, {
      onSuccess: ({ response }: any) => {
        notificationSuccess([response]);
        setRecordDataTable(recordDataTable.filter((item: any) => item.id !== payload.id));
        setMetaDataTable({
          ...metaDataTable,
          totalItems: metaDataTable.totalItems - 1,
        });
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  const renderAction = (item, row) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => navigate(`/attendances/detail?id=${row?.id}`)}
          size="small"
          type="text"
          icon={<AntdIconWrapper>{<BsEye />}</AntdIconWrapper>}
        ></Button>
        {isAdmin && (
          <Popconfirm
            title="Are you sure to delete this attendance?"
            placement="leftTop"
            onConfirm={() => handleDelete(row)}
          >
            <Button size="small" type="text" icon={<AntdIconWrapper>{<BiTrashAlt />}</AntdIconWrapper>} />
          </Popconfirm>
        )}
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Action',
      key: 'action',
      width: 70,
      dataIndex: 'action',
      render: renderAction,
    },
    {
      title: 'Name',
      key: 'information.name',
      width: 300,
      dataIndex: 'information.name',
      ellipsis: true,
      sorter: true,
      render(value, record, index) {
        return record?.event?.information?.name;
      },
    },
    {
      title: 'Date',
      key: 'date',
      width: 200,
      dataIndex: 'date',
      ellipsis: true,
      sorter: true,
      render(value, record, index) {
        if (!value) return '-';
        return moment(value).format('DD-MM-YYYY');
      },
    },
    {
      title: 'Venue/Center',
      key: 'sub_region.name',
      // width: 100,
      dataIndex: 'sub_region.name',
      ellipsis: true,
      sorter: true,
      render(value, record, index) {
        return record?.sub_region?.name;
      },
    },
    {
      title: 'Total Attendance',
      key: 'of_attendances',
      // width: 100,
      dataIndex: 'of_attendances',
      ellipsis: true,
      sorter: false,
      render(value, record, index) {
        return value ?? 0;
      },
    },
  ];

  const isMobile = useMediaQuery({ media: 'mobile' });
  const actionIgnored: any[] = ['activate', 'deactivate', 'duplicate', 'update', 'confirm_process'];
  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Attendance',
        showNewData: false,
        showDelete: false,
        showActivate: false,
        showDeactivate: false,
        showSearchBadge: true,
        showFilterBadge: true,
      }}
      drawerFilterProp={{
        ...(isMobile ? { width: '100%' } : {}),
        formBuilderProps: {
          columns: Filter,
        },
      }}
    >
      <Card>
        <AntdDataTable
          columns={columns as any}
          defaultColumnIgnored={['status', 'action', 'created_at', 'updated_at']}
          rowActionProps={{
            actionIgnored: isAdmin ? actionIgnored : [...actionIgnored, 'delete'],
          }}
          onRow={null}
          rowSelection={null}
          rowKey="customRowKeyTable"
          expandable={{
            expandedRowRender: (record) => (
              <CheckInData
                defaultFilter={{
                  ...transformer.transformerFilterIndexTable(filterDataTable),
                  event_check_in_ids: record?.id ? [record?.id] : [],
                }}
              />
            ),
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
