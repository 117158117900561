import {
  ActionProps,
  AntdIconWrapper,
  EnumColorStatusData,
  EnumStatusData,
  IndexPageWrapper,
  stringFormatter,
  useMediaQuery,
  usePageProvider,
} from '@eigen3m/react-base-frontend';
import { useState } from 'react';
import { EventForm } from '../components/form';
import { columns } from '../helpers';
import { mockCompanies } from 'states';
import { useRecoilState } from 'recoil';
import EventDetail from '../components/detail';
import { Button, notification, Tag } from 'antd';
import React from 'react';
import { BsEye } from 'react-icons/bs';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { SinglePage } from '@main/components/single-page/single-page';

function makeColorStatus(status) {
  if (status === EnumStatusData.active) return EnumColorStatusData.active;
  else if (status === EnumStatusData.inactive) return EnumColorStatusData.inactive;
  return '';
}

export default function IndexPage() {
  const { dataSource } = usePageProvider();
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [formValue, setFormValue] = useState(null);
  const navigate = useNavigate();

  const renderAction = (item, row) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => onShowDrawerDetail(row)}
          size="small"
          type="text"
          icon={<AntdIconWrapper>{<BsEye />}</AntdIconWrapper>}
        ></Button>
        <div style={{ marginLeft: 7 }}></div>
        <Button
          size="small"
          type="text"
          onClick={() => onShowDrawerForm('update', row)}
          icon={<AntdIconWrapper>{<BiEdit />}</AntdIconWrapper>}
        ></Button>
      </div>
    );
  };

  const [companies] = useRecoilState(mockCompanies);

  const onShowDrawerForm = (action: ActionProps, row?: any) => {
    setFormValue(row);
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = (row?: any) => {
    setFormValue(row);
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  async function handleConfirm(payload) {
    await dataSource.handleConfirmProcess(payload, {
      onSuccess({ response }: any) {
        if (response?.type === 'failed') {
          notification.error({
            message: 'Confirm Failed',
            description: `${response?.message}`,
          });
        } else {
          notification.success({
            message: 'Confirm Success',
            description: `Data confirmed`,
          });
        }
      },
      onFailed({ message }: any) {
        // console.log({ error });
        notification.error({
          message: 'Failed',
          description: message?.join(', '),
        });
      },
    });
  }

  const updateShowForm = async ({ setShowForm }) => {
    await setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 100);
  };

  async function handleAfterSave(payload) {
    await handleConfirm(payload);
    // console.log('masuk');
    updateShowForm({ setShowForm });
  }

  // console.log({ actionForm });
  const isMobile = useMediaQuery({ media: 'mobile' });

  console.log({ isMobile });
  if (showForm) {
    return (
      <IndexPageWrapper
        pageToolbarProps={{
          pageTitle: 'Event',
          handleClickNewData() {
            onShowDrawerForm('create');
          },
          showSearchBadge: true,
          showFilterBadge: true,
          showFilter: false,
          showConfirmProcess: false,
        }}
      >
        <SinglePage
          drawerFormProps={{
            title: 'Event',
            action: actionForm,
            visible: visibleDrawerForm,
            onClose: onCloseDrawerForm,
            // forceRequest: true,
            children: <EventForm />,
            onShow: onShowDrawerForm,
            initialValues: { company: !!companies?.length ? companies[0] : null },
            handleAfterSave: handleAfterSave,
            width: isMobile ? '100%' : undefined,
            value: formValue,
          }}
          dataTableProps={{
            columns: [
              {
                title: '',
                key: 'action',
                width: 60,
                dataIndex: 'action',
                render: renderAction,
              },
              {
                title: 'Status',
                key: 'status',
                width: 80,
                sorter: true,
                render(value, record, index) {
                  const item = record?.status?.toLowerCase();
                  if (!item) return <div></div>;
                  return <Tag color={makeColorStatus(item)}>{stringFormatter.capitalizeEachWord(item)}</Tag>;
                },
              },
              ...columns,
            ],
            rowActionProps: {
              actionIgnored: isMobile ? [] : ['detail', 'update'],
            },
            defaultColumnIgnored: ['status'],
            onRow: null,
          }}
          drawerDetailProps={{
            title: 'Event',
            visible: visibleDrawerDetail,
            onClose: onCloseDrawerDetail,
            // forceRequest: true,
            children: <EventDetail />,
            onShow: onShowDrawerDetail,
            value: formValue,
            ...(isMobile ? { width: '100%' } : {}),
          }}
        />
      </IndexPageWrapper>
    );
  }
  return <></>;
}
