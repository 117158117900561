import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { MapPin } from 'phosphor-react';
import { MapPicker } from '../map-picker';
import { AntdIconWrapper } from '@eigen3m/react-base-frontend';
import { AddressAutoComplete } from '../map-picker/address-auto-complete';
import { v4 as uuidV4 } from 'uuid';

type AddressValue = {
  address?: string;
  latitude?: number | string;
  longitude?: number | string;
  country?: any;
  province?: any;
  city?: any;
  postal_code?: any;
};

export interface InputAddressProps {
  value?: AddressValue;
  onChange?(value: any): void;
  showMapIcon?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export function InputAddress(props: InputAddressProps) {
  const [form] = Form.useForm();
  const [showMap, setShowMap] = React.useState(false);
  const [triggerMapKey, setTriggerMapKey] = useState(undefined);
  const { value, onChange, showMapIcon = true, placeholder, disabled = false } = props;

  async function handleSaveAddress() {
    try {
      const payload = await form.validateFields();
      setShowMap(false);
      setTriggerMapKey(uuidV4());
      if (onChange) onChange(payload);
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeAutoComplete(value) {
    if (onChange)
      onChange({
        ...value,
        country: null,
        province: null,
        city: null,
        postal_code: null,
        latitude: null,
        longitude: null,
      });
  }

  function handleCancel() {
    setTriggerMapKey(uuidV4());
    setShowMap(false);
  }

  function handleShowModal() {
    setShowMap(true);
    setTriggerMapKey(uuidV4());
  }
  useEffect(() => {
    if (value) form.setFieldsValue(value);
  }, [value]);

  return (
    <div>
      <Modal
        okText="Save"
        visible={showMap}
        title="Google Maps"
        onOk={() => handleSaveAddress()}
        onCancel={() => handleCancel()}
        width={'80vw'}
      >
        <MapPicker form={form} triggerMapKey={triggerMapKey} />
      </Modal>
      <div style={{ display: 'flex' }}>
        <AddressAutoComplete value={value} onChange={onChangeAutoComplete} placeholder={placeholder} />
        <Button
          hidden={!showMapIcon}
          disabled={disabled}
          onClick={() => handleShowModal()}
          style={{ marginLeft: '-1px', padding: '0' }}
          icon={
            <AntdIconWrapper>
              <MapPin size={18} color="grey" />
            </AntdIconWrapper>
          }
        />
      </div>
    </div>
  );
}
