import { capitalize, omit } from 'lodash';
import { UserEntity } from '../entities';
import { authHelper, BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

const getTransformer = (payload) => {
  const isDetail = window.location.href.indexOf('detail') > -1 ? true : false;
  const account = authHelper?.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  const address = payload?.address?.address;
  const anniversaryDate = payload?.anniversary_date;
  const dateOfBirth = payload?.date_of_birth;
  const email = payload?.email;
  const gender = payload?.gender;
  const maritalStatus = payload?.marital_status;
  const phoneNumber = payload?.phone_number;

  const isNotRealAddress = !isAdmin && !!address && address?.toLowerCase()?.includes('gantialamat') ? true : false;
  const isNotRealDateOfBirth =
    !isAdmin && !!dateOfBirth && dateOfBirth?.toLowerCase()?.includes('1901-01-01') ? true : false;
  const isNotRealAnniversaryDate =
    !isAdmin && !!anniversaryDate && anniversaryDate?.toLowerCase()?.includes('1901-01-01') ? true : false;

  if (!payload) return null;

  let newEmail = email;
  let newDateOfBirth = dateOfBirth;
  let newGender = gender;
  let newPhoneNumber = phoneNumber;
  let newAnniversaryDate = anniversaryDate;

  if (isAdmin) {
    newAnniversaryDate = !anniversaryDate ? moment('1901-01-01') : anniversaryDate;
    newDateOfBirth = !dateOfBirth ? moment('1901-01-01') : dateOfBirth;
    newEmail = !email ? `${uuidV4()}@gantiemail.com` : email;
    newGender = !gender ? 'Male' : gender;
    newPhoneNumber = !phoneNumber ? `${7000}${Math.floor(Math.random() * 1e16)}` : newPhoneNumber;
  }

  return {
    ...payload,
    // README: Yang di comment dibawah breaking karena data yang di hasilkan sudah berupa string boolean
    // has_join_care_group: payload?.has_join_care_group === true ? 'true' : 'false',
    // has_attend_ifgf: payload?.has_attend_ifgf === true ? 'true' : 'false',
    address:
      isAdmin && isDetail && !payload?.address
        ? null
        : {
            ...payload?.address,
            address: isAdmin && !payload?.address ? 'gantialamat' : isNotRealAddress ? null : address,
          },
    has_join_care_group: payload?.has_join_care_group ? 'true' : 'false',
    has_attend_ifgf: payload?.has_attend_ifgf ? 'true' : 'false',
    date_of_birth:
      isAdmin && isDetail && !payload?.date_of_birth
        ? null
        : isNotRealDateOfBirth
        ? null
        : dateOfBirth
        ? moment(dateOfBirth)
        : newDateOfBirth,
    email: isAdmin && isDetail && !payload?.email ? null : newEmail,
    marital_status: isAdmin && isDetail && !payload?.marital_status ? null : maritalStatus ? maritalStatus : 'Single',
    gender: isAdmin && isDetail && !payload?.gender ? null : newGender,
    phone_number: isAdmin && isDetail && !payload?.phone_number ? null : newPhoneNumber,
    anniversary_date:
      isAdmin && isDetail && !payload?.anniversary_date
        ? null
        : isNotRealAnniversaryDate
        ? null
        : anniversaryDate
        ? moment(payload?.anniversary_date)
        : newAnniversaryDate,
    // prefix_phone: payload?.prefix_phone ?? '+62',
    // email: isNotRealEmail ? null : email,
    last_sent_email: payload?.last_sent_email ? moment(Number(payload?.last_sent_email)) : null,
    relatives: payload?.relatives?.map((item) => {
      return {
        ...item,
        key: item?.id,
        contact_relative: item?.contact_relative ? getTransformer(item?.contact_relative) : null,
      };
    }),
  };
};

export class UserTransformer<E extends BaseEntity = UserEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['form_action']);
    return {
      ...cleanVal,
      // README: Yang di comment dibawah breaking karena data yang di hasilkan sudah berupa string boolean
      date_of_birth: payload?.date_of_birth ? moment(payload?.date_of_birth).format('YYYY-MM-DD') : null,
      anniversary_date: payload?.anniversary_date ? moment(payload?.anniversary_date).format('YYYY-MM-DD') : null,
      last_sent_email: payload?.last_sent_email ? new Date(payload?.last_sent_email).valueOf() : null,
      has_join_care_group: payload?.has_join_care_group === 'true' ? true : false,
      has_attend_ifgf: payload?.has_attend_ifgf === 'true' ? true : false,
      gender: payload?.gender ? capitalize(payload?.gender) : null,
      password: payload?.password ? payload?.password : undefined,
      retype_password: payload?.retype_password ? payload?.retype_password : undefined,
      relatives: payload?.relatives?.map((item) => {
        return {
          ...item,
          contact_relative: this.transformerUpdate(item?.contact_relative),
        };
      }),
    };
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    if (!payload) return payload;
    const data = {
      ...getTransformer(payload),
      requested_data: getTransformer(payload?.requested_data),
    };
    return data;
  }

  transformerFilterIndexTable(payload: any) {
    if (!payload) return payload;
    const resultDate = (data) => {
      return data?.map((item, idx) => {
        if (idx === 0) {
          return moment(`${moment(item).format('YYYY-MM-DD')} 00:00:00`);
        }
        if (idx === 1) {
          return moment(`${moment(item).format('YYYY-MM-DD')} 23:59:59`);
        }
      });
    };
    const createdDate = resultDate(payload?.created_at);
    const updatedDate = resultDate(payload?.updated_at);
    return {
      ...payload,
      icc_ids: payload?.iccs?.map((item) => item?.id),
      iccs: [],
      hcc_ids: payload?.hccs?.map((item) => item?.id),
      hccs: [],
      role_position_ids: payload?.role_positions?.map((item) => item?.id),
      role_positions: [],
      discipleship_center_ids: payload?.discipleship_centers?.map((item) => item?.id),
      discipleship_centers: [],
      created_from: payload?.created_at?.[0] ? new Date(createdDate?.[0]).valueOf() : null,
      created_to: payload?.created_at?.[1] ? new Date(createdDate?.[1]).valueOf() : null,
      created_at: [],
      updated_from: payload?.updated_at?.[0] ? new Date(updatedDate?.[0]).valueOf() : null,
      updated_to: payload?.updated_at?.[1] ? new Date(updatedDate?.[1]).valueOf() : null,
      updated_at: [],
    };
  }
}
