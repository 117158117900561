import cubejs from '@cubejs-client/core';
import { authHelper } from '@eigen3m/react-base-frontend';

const API_URL = `${process.env.REACT_APP_BASE_CUBE_API_URL}/v1`;
const CUBEJS_TOKEN = authHelper.getAuthToken();

// const CUBEJS_TOKEN =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzU1NCwiZmlyc3RfbmFtZSI6ImVpZ2VuMjIyIiwibGFzdF9uYW1lIjoiZWlnZW4yMjIiLCJwcm9maWxlX2ltYWdlIjpudWxsLCJlbWFpbCI6ImVpZ2VuMjIyQGlmZ2YuZ2xvYmFsIiwicGhvbmUiOm51bGwsInNjb3BlIjoiZ2xvYmFsIiwic2NvcGVfcmVnaW9ucyI6W10sInNjb3BlX3N1Yl9yZWdpb25zIjpbXSwic2NvcGVfc3ViX3JlZ2lvbnNfdHBpIjpbXSwiaWF0IjoxNjYzMTQ1MjQ4LCJleHAiOjE2NjU3MzcyNDh9.Wa2gN5EidV91E4s6gVkMX9BxlZxP5ifdq19STWu8QxE';

export const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });
