import { CheckInEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';

export class CheckInTransformer<E extends BaseEntity = CheckInEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerCreate(payload: E): E {
    if (!payload) return payload;
    return payload;
  }

  transformerDuplicate(payload: any): any {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: any): any {
    if (!payload) return payload;
    return payload;
  }

  transformerFilterIndexTable(payload: any) {
    return payload;
  }
}
