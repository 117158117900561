import {
  ActionProps,
  IndexPageWrapper,
  makeCommonDataSource,
  notificationFailed,
  notificationSuccess,
  SinglePage,
} from '@eigen3m/react-base-frontend';
import { useState } from 'react';
import { CheckInForm } from '../components/form';
import { columns } from '../helpers';
import { mockCompanies } from 'states';
import { useRecoilState } from 'recoil';
import CheckInDetail from '../components/detail';
import { AntdDataTable } from '@main/components/data-table-component';
import { ApiUrlData } from '@base-configs';

export default function IndexPage() {
  const [visibleDrawerForm, setVisibleDrawerForm] = useState(false);
  const [actionForm, setActionForm] = useState<ActionProps>('create');
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const updateShowForm = async ({ setShowForm }) => {
    await setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 100);
  };

  const [companies] = useRecoilState(mockCompanies);

  const onShowDrawerForm = (action: ActionProps) => {
    setVisibleDrawerForm(true);
    setActionForm(action);
  };
  const onCloseDrawerForm = () => {
    setVisibleDrawerForm(false);
  };

  const onShowDrawerDetail = () => {
    setVisibleDrawerDetail(true);
  };
  const onCloseDrawerDetail = () => {
    setVisibleDrawerDetail(false);
  };

  const dataSource = makeCommonDataSource({
    apiUrl: ApiUrlData.check_in,
  });

  async function handleClickDelete(row: any) {
    try {
      setLoading(true);
      await dataSource.handleCustomRequest({
        paramRequest: {
          url: `${process.env.REACT_APP_BASE_URL}${ApiUrlData.check_in}/batch-delete`,
          // params: {
          //   ids: [row?.id],
          // },
          method: 'delete',
          data: {
            ids: [row?.id],
          },
        },
        onSuccess: ({ response }: any) => {
          const responseItems = response ?? [];
          const successMessage = responseItems
            .filter((item) => item.type === 'success')
            .map((itemMsg) => itemMsg.message);

          const failedMessage = responseItems
            .filter((item) => item.type === 'failed')
            .map((itemMsg) => itemMsg.message);

          if (successMessage.length > 0) notificationSuccess(successMessage);
          if (failedMessage.length > 0) notificationFailed(failedMessage);
        },
        onFailed: ({ message }: any) => {
          notificationFailed(message);
        },
      });
    } catch (error) {
      notificationFailed([error as string]);
    } finally {
      setLoading(false);
      updateShowForm({ setShowForm });
    }
  }

  if (!showForm) return <></>;
  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Check In Data',
        handleClickNewData() {
          onShowDrawerForm('create');
        },
        showBack: false,
        showActivate: false,
        showDelete: false,
        showReload: false,
        showApproval: false,
        showDeactivate: false,
        showExport: false,
        showImport: false,
        showNewData: false,
        showPrint: false,
        showFilter: false,
        showTitle: false,
      }}
      showPageHeader={false}
      padding={['top']}
    >
      <AntdDataTable
        columns={columns}
        defaultColumnIgnored={['created_at', 'updated_at', 'status']}
        rowActionProps={{
          actionIgnored: ['activate', 'confirm_process', 'deactivate', 'detail', 'duplicate', 'update'],
          handleClickDelete: handleClickDelete,
        }}
        rowSelection={null}
        style={{ marginTop: -60 }}
        onRow={null}
        size="small"
        loading={loading}
      />
      {/* <SinglePage
        drawerFormProps={{
          title: 'CheckIn',
          action: actionForm,
          visible: visibleDrawerForm,
          onClose: onCloseDrawerForm,
          forceRequest: true,
          children: <CheckInForm />,
          onShow: onShowDrawerForm,
          initialValues: { company: !!companies?.length ? companies[0] : null },
        }}
        dataTableProps={{
          columns: columns,
          defaultColumnIgnored: ['action', 'created_at', 'updated_at', 'status'],
          rowSelection: null,
          style: {
            marginTop: -60,
          },
          onRow: (record) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
              },
            };
          },
        }}
        drawerDetailProps={{
          title: 'CheckIn',
          visible: visibleDrawerDetail,
          onClose: onCloseDrawerDetail,
          // forceRequest: true,
          children: <CheckInDetail />,
          onShow: onShowDrawerDetail,
        }}
      /> */}
    </IndexPageWrapper>
  );
}
