import { Avatar, Dropdown, Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { CgMenuGridR } from 'react-icons/cg';
import { AntdIconWrapper, authHelper, useMediaQuery } from '@eigen3m/react-base-frontend';
import { itemMenu } from './item-menu';
import { WebUrlData } from '@base-configs';
import { SignOut } from 'phosphor-react';
import Logo from '@assets/images/logo-small.png';
import { useState } from 'react';
import MobileMenu from './mobile-menu';
import { MenuOutlined } from '@ant-design/icons';

const headerStyle: any = {
  position: 'fixed',
  zIndex: 99,
  width: '100%',
  top: 0,
  height: '65px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #f0f0f0',
  display: 'flex',
  alignItems: 'center',
  // paddingLeft: '3vw',
  // paddingRight: '4vw',
};

interface Props {
  children: JSX.Element;
  accessFromMainApp: boolean;
}
export function AuthLayout({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const account = authHelper.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;
  const isAdminReport = account?.user?.username === process.env.REACT_APP_ADMIN_REPORT_EMAIL;

  const isMobile = useMediaQuery({ media: 'mobile' });

  async function handleLogout() {
    await authHelper.clearStorageLogout();
    navigate(WebUrlData.login);
  }

  function makeAvatarMenu() {
    return (
      <Menu style={{ width: '150px' }}>
        <Menu.Item icon={<SignOut />} onClick={() => handleLogout()}>
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  let menus = [];
  if (isAdmin) menus = itemMenu;
  if (isAdminReport) menus = itemMenu.filter((menu) => ['report', 'attendance'].includes(menu.label.toLowerCase()));

  const name = account?.user?.code ?? '';
  const labelName = name
    ?.split(' ')
    .map((item) => item[0])
    .join('');

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const showMobileMenu = () => {
    setOpenMobileMenu(true);
  };

  const onCloseMobileMenu = () => {
    setOpenMobileMenu(false);
  };
  return (
    <div>
      <Outlet />
      <div style={headerStyle}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '65px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '3vw',
            paddingRight: '4vw',
          }}
        >
          <div style={{ marginRight: '20px' }}>
            <a href="/">
              <img src={Logo} height="60" />
            </a>
          </div>
          <div style={{ width: '100%', display: isMobile ? 'none' : 'block' }}>
            <Menu
              defaultSelectedKeys={[location.pathname]}
              onClick={(e) => navigate(e.key)}
              mode="horizontal"
              items={menus}
            />
          </div>
          {isMobile && (isAdmin || isAdminReport) && (
            <>
              <MenuOutlined
                onClick={() => showMobileMenu()}
                style={{ position: 'absolute', right: 0, marginRight: '4vw' }}
              />
              <MobileMenu
                onClose={onCloseMobileMenu}
                open={openMobileMenu}
                labelName={labelName}
                name={name}
                menu={
                  <Menu
                    defaultSelectedKeys={[location.pathname]}
                    onClick={(e) => {
                      if (isMobile) {
                        onCloseMobileMenu();
                        navigate(e.key);
                      } else {
                        navigate(e.key);
                      }
                    }}
                    mode="vertical"
                    items={menus}
                  />
                }
              />
            </>
          )}
          {((!isMobile && (isAdmin || isAdminReport)) || (!isAdmin && !isAdminReport)) && (
            <div className="navbar-right-item" style={{ position: 'absolute', right: 0, marginRight: '4vw' }}>
              <Dropdown overlay={makeAvatarMenu()} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      marginRight: 10,
                      fontSize: 12,
                      fontWeight: 800,
                      whiteSpace: 'nowrap',
                      width: 100,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textAlign: 'right',
                    }}
                  >
                    {name}
                  </div>
                  <div>
                    <Avatar size={35} className="custom-avatar">
                      {labelName?.toUpperCase()}
                    </Avatar>
                  </div>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: '65px' }}></div>
      <div style={{ background: 'transparent' }}>{children}</div>
    </div>
  );
}
