import { authHelper, PageProvider } from '@eigen3m/react-base-frontend';
import { PageController } from '@eigen3m/react-base-frontend';
import { InitialValuePageProviderEntity } from '@eigen3m/react-base-frontend';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

import { UserEntity } from '../../domain/entities';
import { makeUserTransformer } from '../../domain/factories';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function FactoryPage() {
  const navigate = useNavigate();
  const transformer = makeUserTransformer<UserEntity>();
  const InitialValue: InitialValuePageProviderEntity<UserEntity> = {
    transformer,
    moduleKey: 'data-user-contact-page',
    webUrl: WebUrlData.users,
    apiUrl: ApiUrlData.contact,
  };
  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
