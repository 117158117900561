import { RenderAddress } from '@eigen3m/react-base-frontend';
import { TableColumnsType } from 'antd';
import moment from 'moment';

export const columns: TableColumnsType<any> = [
  {
    width: 100,
    title: 'Time Check In',
    dataIndex: 'time',
    key: 'time',
    ellipsis: true,
    sorter: true,
    render(item) {
      if (!item) return '-';
      return moment(Number(item)).format('DD-MM-YYYY HH:mm');
    },
  },
  {
    title: 'Name',
    key: 'user.name',
    width: 100,
    dataIndex: ['user', 'name'],
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Gender',
    key: 'user.gender',
    width: 100,
    dataIndex: ['user', 'gender'],
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Age',
    key: 'user.date_of_birth',
    width: 100,
    dataIndex: ['user', 'date_of_birth'],
    render(item) {
      if (!item) return '-';
      return moment().diff(moment(item), 'years');
    },
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Attendance Center',
    key: 'user.discipleship_center',
    width: 100,
    dataIndex: ['user', 'discipleship_center', 'name'],
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Care Group Leader',
    key: 'user.care_group_leader',
    width: 100,
    dataIndex: ['user', 'care_group_leader', 'name'],
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Phone Number',
    key: 'user.phone_number',
    width: 100,
    ellipsis: true,
    sorter: true,
    dataIndex: ['user', 'phone_number'],
    render(item, row) {
      if (!item) return '-';
      return `${row?.user?.prefix_phone ?? ''} ${item}`;
    },
  },
  {
    title: 'Email',
    key: 'user.email',
    width: 100,
    dataIndex: ['user', 'email'],
    ellipsis: true,
    sorter: true,
  },
  {
    title: 'Checked By',
    key: 'checker.name',
    width: 100,
    dataIndex: ['checker', 'name'],
    ellipsis: true,
    sorter: true,
  },
];
