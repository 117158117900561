import { LoginTemplate1 } from '../components';
import BackgroundImage from '../assets/background-3.png';
import { useNavigate, useParams } from 'react-router-dom';
import { WebUrlData, ApiUrlData } from '@base-configs';
import { authHelper, makeCommonDataSource, notificationFailed } from '@eigen3m/react-base-frontend';
import { useEffect, useState } from 'react';
import Logo from '../assets/logo-1.png';
import { Form } from 'antd';

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.user_login });
  const dataSourceLoginToken = makeCommonDataSource({ apiUrl: ApiUrlData.user_login_token });
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  async function handleAfterLogin(response: any): Promise<void> {
    await authHelper.setAccount(response);
    const isAdmin = response?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;
    const isAdminReport = response?.user?.username === process.env.REACT_APP_ADMIN_REPORT_EMAIL;
    const urlBeforeLogin = localStorage.getItem('url-before-login');
    if (isAdmin) {
      navigate('/users');
    } else if (isAdminReport) {
      navigate('/attendances');
    } else if (urlBeforeLogin) {
      window.location.replace(urlBeforeLogin);
    } else {
      navigate('/');
    }
  }

  async function onFinish(payload) {
    setLoading(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'POST',
        url: process.env.REACT_APP_BASE_SERVICE_URL + ApiUrlData.user_login,
        data: { ...payload, company: 'E' },
      },
      async onSuccess({ response }: any) {
        await handleAfterLogin(response);
        setLoading(false);
      },
      async onFailed({ message }) {
        await authHelper.clearStorageLogout();
        notificationFailed([message as string]);
        setLoading(false);
      },
    });
  }

  async function loginToken(token) {
    setLoading(true);
    await dataSourceLoginToken.handleCreate(
      { token },
      {
        async onSuccess({ response }) {
          await handleAfterLogin(response);
          setLoading(false);
        },
        async onFailed({ message }) {
          await authHelper.clearStorageLogout();
          notificationFailed([message as string]);
          setLoading(false);
        },
      },
    );
  }

  // const testing = async () => {
  //   const urlBeforeLogin = window.location.href;
  //   await authHelper.clearStorageLogout();
  //   await localStorage.setItem('url-before-login', urlBeforeLogin);
  //   setTimeout(() => {
  //     console.log(localStorage.getItem('url-before-login'));
  //   }, 100);
  // };

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      loginToken(decodeToken);
    }
  }, [token]);

  const onKeyDownEmail = (event) => {
    if (event?.key?.toLowerCase() === 'enter') {
      const nextValue = document.getElementById(`password`);
      if (nextValue) {
        nextValue.focus();
        nextValue.scrollIntoView();
      }
    }
  };

  const handleSubmitByEnter = (event, values) => {
    if (event?.key?.toLowerCase() === 'enter') {
      onFinish(values);
    }
  };

  return (
    <LoginTemplate1
      loadingLogin={loading}
      onSubmitLogin={onFinish}
      onClickManualRegister={() => navigate(WebUrlData.register)}
      rightContentImage={BackgroundImage}
      onClickForgotPassword={() => navigate(WebUrlData.forgot_password)}
      loginWithGoogleUrl={`${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.login_google}`}
      registerWithGoogleUrl={`${process.env.REACT_APP_BASE_SERVICE_URL}${ApiUrlData.register_google}`}
      logoImage={Logo}
      handleSubmitByEnter={handleSubmitByEnter}
      formBuilderProps={{
        columns: [
          {
            fields: [
              {
                label: 'Email',
                name: 'username',
                gridColumn: { span: 24 },
                fieldType: 'inputText',
                rules: [{ required: true, message: 'Please input your email!' }],
                inputTextProps: {
                  type: 'email',
                  size: 'large',
                  placeholder: 'Enter your mail',
                  className: 'login-field',
                  onKeyDown: onKeyDownEmail,
                },
              },
              {
                label: 'Password',
                name: 'password',
                gridColumn: { span: 24 },
                fieldType: 'inputPassword',
                rules: [{ required: true, message: 'Please input your password!' }],
                inputPasswordProps: {
                  id: 'password',
                  size: 'large',
                  placeholder: 'Enter your password',
                  className: 'login-field',
                },
              },
            ],
          },
        ],
      }}
    />
  );
}
