import { useEffect, useState } from 'react';
import { RunButton } from '../run-button';
import { TableRenderer } from './table-renderer';

interface Props {
  configuration?: any;
}

export function TableRender(props: Props) {
  const { configuration } = props;
  const query = configuration?.query;
  const [showButtonRun, setShowButtonRun] = useState(true);

  useEffect(() => {
    setShowButtonRun(true);
  }, [query]);

  if (showButtonRun) return <RunButton handleAfterRun={() => setShowButtonRun(false)} />;
  return <TableRenderer configuration={configuration} />;

  // return (
  //   <div>
  //     {showButtonRun && <RunButton handleAfterRun={() => setShowButtonRun(false)} />}
  //     {!showButtonRun && <TableRenderer configuration={configuration} />}
  //   </div>
  // );
}
