import { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { queryTransformer } from '../../helpers';

interface Props {
  configuration?: any;
  language?: any;
}

export function PreviewQuery(props: Props) {
  const { language = 'json', configuration = {} } = props;
  const codeString = JSON.stringify(queryTransformer(configuration.query) ?? {});
  return (
    <SyntaxHighlighter language={language} style={docco} wrapLines={true} wrapLongLines={true}>
      {codeString}
    </SyntaxHighlighter>
  );
}
