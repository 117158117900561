import { EventEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import { omit } from 'lodash';

function informationTransformer(data) {
  if (!data) return data;
  return {
    ...data,
    description: data?.description ?? '',
    banner_url: data?.banner_url ?? '',
    event_type: data?.event_type ?? '',
  };
}

export class EventTransformer<E extends BaseEntity = EventEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = {
      company: payload?.company,
      information: informationTransformer(payload?.information),
    };
    return cleanVal;
  }

  transformerDuplicate(payload: any): any {
    const cleanPayload = {
      ...payload,
      information: {
        ...omit(payload?.information, ['id']),
      },
    };
    return this.transformerCreate(cleanPayload);
  }

  transformerGetData(payload: any): any {
    if (!payload) return payload;
    const cleanVal = {
      ...payload,
      key: payload?.id,
      company: payload?.company_id
        ? {
            id: payload?.company_id,
          }
        : null,
    };
    return cleanVal;
  }
}
