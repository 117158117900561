import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IndexPageWrapper, AntdIconWrapper, authHelper, useMediaQuery } from '@eigen3m/react-base-frontend';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import { BiEdit } from 'react-icons/bi';
import { FormColumnProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';
import { AntdDataTable } from '@main/components/data-table-component';

export default function IndexPage() {
  const defaultActionIgnored: any[] = ['activate', 'deactivate', 'update', 'detail'];
  const [actionIgnored, setActionIgnored] = useState(defaultActionIgnored);
  const navigate = useNavigate();
  const account = authHelper.getAccount();
  const isAdminReport = account?.user?.username === process.env.REACT_APP_ADMIN_REPORT_EMAIL;
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  const renderAction = (item, row) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => navigate(`/report/detail?id=${row?.id}`)}
          size="small"
          type="text"
          icon={<AntdIconWrapper>{<BsEye />}</AntdIconWrapper>}
        ></Button>
        <div style={{ marginLeft: 7 }}></div>
        {isAdmin && (
          <Button
            size="small"
            type="text"
            onClick={() => navigate(`/report/update?id=${row?.id}`)}
            icon={<AntdIconWrapper>{<BiEdit />}</AntdIconWrapper>}
          ></Button>
        )}
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      key: 'action',
      width: 70,
      dataIndex: 'action',
      render: renderAction,
    },
    {
      title: 'Name',
      key: 'name',
      // width: 200,
      dataIndex: 'name',
      ellipsis: true,
      sorter: true,
    },
  ];

  useEffect(() => {
    if (isAdminReport) {
      setActionIgnored([...defaultActionIgnored, 'delete']);
    } else {
      setActionIgnored([...defaultActionIgnored]);
    }
  }, [isAdminReport]);

  const isMobile = useMediaQuery({ media: 'mobile' });

  const Filter: FormColumnProps[] = [
    {
      fields: [
        {
          label: 'Created at',
          name: 'created_at',
          gridColumn: { span: 12 },
          fieldType: 'dateRangePicker',
        },
        {
          label: 'Updated at',
          name: 'updated_at',
          gridColumn: { span: 12 },
          fieldType: 'dateRangePicker',
        },
      ],
    },
  ];

  return (
    <IndexPageWrapper
      pageToolbarProps={{
        pageTitle: 'Report',
        showSearchBadge: false,
        showFilterBadge: false,
        showConfirmProcess: false,
        showActivate: false,
        showDeactivate: false,
        showFilter: false,
      }}
      drawerFilterProp={{
        ...(isMobile ? { width: '100%' } : {}),
        formBuilderProps: {
          columns: Filter,
        },
      }}
    >
      <Card>
        <AntdDataTable
          columns={columns as any}
          defaultColumnIgnored={['status']}
          rowActionProps={{
            actionIgnored: isMobile ? actionIgnored?.filter((item) => item !== 'detail') : actionIgnored,
          }}
          onRow={null}
        />
      </Card>
    </IndexPageWrapper>
  );
}
