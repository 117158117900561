import { useParams } from 'react-router-dom';
import { WebUrlData } from '@base-configs';
import { RouteEntity } from '@eigen3m/react-base-frontend';

import ModuleTemplate from '@pages/template-page/presentation/factories';
import User from '@pages/users/presentation/factories';
import QR from '@pages/qr/presentation/factories';
import Report from '@pages/report/presentation/factories';
import Event from '@pages/event/presentation/factories';
import Attendance from '@pages/attendance/presentation/factories';

export const DefaultPage = (): JSX.Element => {
  const params = useParams();
  return <div>You are in default page {params.action}</div>;
};

export const DefaultPath = WebUrlData.qr;
export const AuthRoutes: RouteEntity[] = [
  {
    label: 'QR',
    path: WebUrlData.qr,
    element: QR,
  },
  {
    label: 'User',
    path: WebUrlData.users,
    element: User,
  },
  {
    label: 'Report',
    path: WebUrlData.report,
    element: Report,
  },
  {
    label: 'Event',
    path: WebUrlData.event,
    element: Event,
  },
  {
    label: 'Attendance',
    path: WebUrlData.attendance,
    element: Attendance,
  },
];
