import { Descriptions, List } from 'antd';
import { get } from 'lodash';

interface Props {
  data: any[];
  columns: any[];
  titleColumn: string;
}

export function ListComponent(props: Props) {
  const { data, columns, titleColumn = 'name' } = props;

  const action = columns?.find((item) => item?.key === 'action');

  function renderItem(item, index) {
    return (
      <List.Item key={item?.id}>
        <List.Item.Meta
          title={item?.contact_relative?.name}
          // description={item.relation_type}
          style={{ marginBottom: 0 }}
        />
        {action ? action.render(item) : null}
        <table>
          {columns?.map((column) => {
            if (!['action', 'contact_relative.name'].includes(column?.key)) {
              return (
                <tr key={column?.key}>
                  <th style={{ padding: '4px 4px', width: '90px' }}>{column.title}</th>
                  <th style={{ padding: '4px 4px', width: '5px' }}>:</th>
                  <td
                    style={{
                      padding: '4px 4px',
                      borderRight: 'unset',
                      maxWidth: '100px',
                      // width: item.width,
                    }}
                  >
                    {column?.render
                      ? column.render(get(item, column.dataIndex), item, index)
                      : get(item, column.dataIndex)}
                  </td>
                </tr>
              );
            }
          })}
        </table>
      </List.Item>
    );
  }

  return <List itemLayout="vertical" size="small" dataSource={data} renderItem={renderItem} />;
}
