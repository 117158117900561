import React, { useMemo } from 'react';
import { useDataTableProvider, useMediaQuery } from '@eigen3m/react-base-frontend';
import { ColumnsType } from 'antd/lib/table';
import { get, omit, pick } from 'lodash';
import { RowAction } from '../row-action';
import { AntdDataTableProps, defaultColumnIgnoredType } from './antd-data-table.entity';
import { Pagination, Table, Tag } from 'antd';
import { useEffect } from 'react';
import { makeColorStatus } from './antd-data-table.helper';
import { dateFormatter, stringFormatter } from '@eigen3m/react-base-frontend';

import './antd-data-table.style.less';
import { SORTER_TYPE } from '@eigen3m/react-base-frontend';
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai';
import { RiMoreLine } from 'react-icons/ri';

import PrintProvider, { Print as ReactPrint } from 'react-easy-print';
import { DataTablePrint } from '../../data-table-component/data-table-print';

export function AntdDataTable(props: AntdDataTableProps) {
  const newProps = omit(props, ['rowActionProps', 'defaultColumnIgnored', 'defaultFilterRequestData']);
  const { columns = [], className } = newProps;

  const extensionProps = pick(props, ['rowActionProps', 'defaultColumnIgnored', 'defaultFilterRequestData']);
  const { rowActionProps = {}, defaultColumnIgnored = [], defaultFilterRequestData = {} } = extensionProps;

  const { recordDataTable, metaDataTable } = useDataTableProvider();
  const { filterDataTable, loadingDataTable } = useDataTableProvider();
  const { selectedDataTable = [], setSelectedDataTable } = useDataTableProvider();
  const isMobileScreen = useMediaQuery({ media: 'mobile' });

  const {
    handleUpdate,
    handleDetail,
    handleGetDataIndex,
    handleActivate,
    handleDeactivate,
    handleDelete,
    handleDuplicate,
    handleConfirmProcess,
  } = useDataTableProvider();

  const y: any = props?.scroll?.y ?? 600;

  const defaultColumnPrev: ColumnsType<any> = [
    {
      key: 'action',
      width: 30,
      fixed: true,
      render(value, record, index) {
        return (
          <RowAction
            rowData={record}
            handleClickActivate={handleActivate}
            handleClickDeactivate={handleDeactivate}
            handleClickDelete={handleDelete}
            handleClickDetail={handleDetail}
            handleClickDuplicate={handleDuplicate}
            handleClickUpdate={handleUpdate}
            handleClickConfirmProcess={handleConfirmProcess}
            {...rowActionProps}
          />
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: 80,
      sorter: true,
      render(value, record, index) {
        const item = record?.status?.toLowerCase();
        if (!item) return <div></div>;
        return <Tag color={makeColorStatus(item)}>{stringFormatter.capitalizeEachWord(item)}</Tag>;
      },
    },
  ].filter((item) => !defaultColumnIgnored.includes(item.key as defaultColumnIgnoredType));

  const defaultColumnPost = [
    {
      title: 'Created',
      key: 'created_at',
      width: 240,
      dataIndex: 'created_at',
      ellipsis: true,
      sorter: true,
      render(item, row) {
        return `${dateFormatter(item).parseEpoch()} ${row.created_by ? ' by ' + row.created_by?.first_name : ''}`;
      },
    },
    {
      title: 'Updated',
      key: 'updated_at',
      width: columns.length > 0 ? 240 : undefined,
      dataIndex: 'updated_at',
      ellipsis: true,
      sorter: true,
      render(item, row) {
        return `${dateFormatter(item).parseEpoch()} ${row.updated_by ? ' by ' + row.updated_by?.first_name : ''}`;
      },
    },
  ].filter((item) => !defaultColumnIgnored.includes(item.key as defaultColumnIgnoredType));

  const mergedColumns: any = [...defaultColumnPrev, ...columns, ...defaultColumnPost].map((item) => {
    if (item.key && item.key === filterDataTable?.order_by) {
      return {
        ...item,
        defaultSortOrder: filterDataTable?.order_type === SORTER_TYPE.ascend ? 'ascend' : 'descend',
      };
    }
    return item;
  });

  const rowSelection = {
    columnWidth: 30,
    selectedRowKeys: selectedDataTable?.map((item) => item.id),
    onChange: (_, selectedRowsPayload) => {
      const selectData = selectedRowsPayload?.filter((item) => item.id);
      const dataKeys = recordDataTable.map((item) => item.id ?? item.uuid);
      const otherKeys = selectedDataTable?.filter((item) => !dataKeys.includes(item.id)) ?? [];
      const newData = Array.from(new Set([...otherKeys, ...selectData]));
      setSelectedDataTable(newData);
    },
    getCheckboxProps: (row) => {
      if (row.id) return;
      return {
        style: { display: 'none' },
      };
    },
  };

  useEffect(() => {
    handleGetDataIndex({
      filter: {
        ...defaultFilterRequestData,
        ...filterDataTable,
        page: metaDataTable?.currentPage ?? 1,
      },
    });
  }, []);

  const mobileColumn = [
    {
      key: 'mobile-column',
      render(_, row, rowIndex) {
        return (
          <div style={{ padding: '0px 10px' }}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <RowAction
                rowData={row}
                icon={<RiMoreLine />}
                handleClickActivate={handleActivate}
                handleClickDeactivate={handleDeactivate}
                handleClickDelete={handleDelete}
                handleClickDetail={handleDetail}
                handleClickDuplicate={handleDuplicate}
                handleClickUpdate={handleUpdate}
                {...rowActionProps}
              />
            </div>
            <table className="mobile-row-table">
              {mergedColumns.map((item, index) => {
                if (item.key === 'action') return null;
                return (
                  <tr key={index}>
                    <th style={{ padding: '4px 4px', width: item.width }}>{item.title}</th>
                    <th style={{ padding: '4px 4px', width: '20px' }}>:</th>
                    <td
                      style={{
                        padding: '4px 4px',
                        borderRight: 'unset',
                        width: item.width,
                      }}
                    >
                      {item?.render ? item.render(get(row, item.dataIndex), row, index) : get(row, item.dataIndex)}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        );
      },
    },
  ];

  const pagination = useMemo(() => {
    return {
      current: metaDataTable?.currentPage ?? 1,
      pageSize: metaDataTable?.itemsPerPage ?? 1,
      total: metaDataTable?.totalItems ?? 1,
      showSizeChanger: true,
    };
  }, [metaDataTable]);

  function makeDescriptionTable() {
    const totalItems = metaDataTable?.totalItems ?? 0;
    const currentPage = metaDataTable?.currentPage ?? 0;
    const itemsPerPage = metaDataTable?.itemsPerPage ?? 0;
    const itemCount = metaDataTable?.itemCount ?? 0;

    const from = currentPage * itemsPerPage - itemsPerPage + 1;
    const to = (currentPage - 1) * itemsPerPage + itemCount;
    const of = totalItems;

    return `Showing ${from} to ${to} of ${of} entries.`;
  }
  return (
    <div>
      <Table
        bordered
        pagination={{ ...pagination, style: { display: 'none' } }}
        rowSelection={rowSelection}
        dataSource={recordDataTable}
        onRow={(record: any) => {
          return {
            onClick: (event) => {
              event.stopPropagation();
              handleDetail(record);
            },
          };
        }}
        scroll={{ x: 'fit-content', y: y - 50 }}
        onChange={({ current, pageSize }, _, sorter: any) => {
          const sorterValue = {
            order_by: sorter?.columnKey && SORTER_TYPE[sorter?.order] ? sorter?.columnKey : undefined,
            order_type: SORTER_TYPE[sorter?.order] ?? undefined,
          };
          handleGetDataIndex({
            filter: {
              ...defaultFilterRequestData,
              ...filterDataTable,
              ...sorterValue,
              page: current,
              limit: pageSize,
            },
          });
        }}
        {...newProps}
        loading={newProps?.loading ? newProps?.loading : loadingDataTable}
        columns={[...(isMobileScreen ? mobileColumn : mergedColumns)]}
        className={` ${props.className} no-print`}
        expandable={{
          ...(newProps?.expandable ?? {}),
          expandIcon({ expanded, onExpand, record }) {
            if (newProps.expandable) {
              if (expanded)
                return (
                  <AiOutlineDown
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                );
              else
                return (
                  <AiOutlineRight
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                );
            } else if (record.children?.length > 0) {
              if (expanded)
                return (
                  <AiOutlineDown
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                );
              else
                return (
                  <AiOutlineRight
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                );
            }
          },
        }}
      />

      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: 20 }}>
        <div style={{ marginRight: 'auto', marginBottom: 20 }}>{makeDescriptionTable()}</div>
        <div style={{ marginLeft: 'auto', marginBottom: 20 }}>
          <Pagination
            {...pagination}
            onChange={(current, pageSize) =>
              handleGetDataIndex({
                filter: {
                  ...defaultFilterRequestData,
                  ...filterDataTable,
                  page: current,
                  limit: pageSize,
                },
              })
            }
          />
        </div>
      </div>
      <PrintProvider>
        <ReactPrint printOnly name="print-index">
          <div className="print">
            <style type="text/css">{`
              @media print{
                @page {
                  size: 210mm 297mm !important;
                }
                html, body {
                  height: 100% !important;
                  page-break-after: avoid !important;
                  page-brßeak-before: avoid !important;
                }
              }
            `}</style>
            <DataTablePrint columns={mergedColumns?.filter((column) => column?.key !== 'action')} />
          </div>
        </ReactPrint>
      </PrintProvider>
    </div>
  );
}
