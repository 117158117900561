import { authHelper, DetailBuilder, DetailPageWrapper, usePageProvider } from '@eigen3m/react-base-frontend';
import { Form, Tabs } from 'antd';
import CheckInData from '@pages/check-in-data/presentation/factories';
import moment from 'moment';
import { RelativeComponent } from '../components/relatives';
const grid = { xs: 24, sm: 10, md: 6, lg: 4, xl: 4 };

export default function DetailPage() {
  const { id, transformer, filterDataTable } = usePageProvider();
  const account = authHelper?.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <DetailPageWrapper
      pageToolbarProps={{
        pageTitle: 'Detail Attendance',
        showActivate: false,
        showApproval: false,
        showDataTypeSelector: false,
        showSave: false,
        showDuplicate: false,
        showDelete: isAdmin ? true : false,
        showUpdate: false,
        showNewData: false,
        showTagStatus: false,
      }}
    >
      <DetailBuilder
        columns={[
          {
            title: 'EVENT INFORMATION',
            fields: [
              {
                gridColumn: grid,
                label: 'Name',
                name: ['detail_data', 'event', 'information', 'name'],
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              {
                gridColumn: grid,
                label: 'Date',
                name: ['detail_data', 'date'],
                fieldType: 'text',
                renderTextProps: {
                  renderValue(value) {
                    return moment(value).format('DD-MM-YYYY');
                  },
                  defaultRender: () => '-',
                },
              },
              {
                gridColumn: grid,
                label: 'Venue/Center',
                name: ['detail_data', 'sub_region', 'name'],
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
              // {
              //   gridColumn: grid,
              //   label: 'Total Attendance',
              //   name: ['detail_data', 'of_attendances'],
              //   fieldType: 'text',
              //   renderTextProps: {
              //     defaultRender: () => '-',
              //   },
              // },
            ],
          },
        ]}
      />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const values = getFieldsValue();
          if (values?.detail_data?.id) {
            return (
              <CheckInData
                defaultFilter={{
                  ...transformer.transformerFilterIndexTable(filterDataTable),
                  event_check_in_ids: id ? [id] : [],
                }}
              />
            );
          }
          return <></>;
        }}
      </Form.Item>
    </DetailPageWrapper>
  );
}
