import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import {
  IndexPageWrapper,
  stringFormatter,
  AntdIconWrapper,
  EnumStatusData,
  EnumColorStatusData,
  authHelper,
  useMediaQuery,
  ButtonToolbarWrapperProps,
  usePageProvider,
  SelectPaginate,
  makeArrayIds,
  makeCommonDataSource,
} from '@eigen3m/react-base-frontend';
import { OptionsYesOrNo } from '../helpers';
import { ApiUrlData } from '@base-configs';
import { FormColumnProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';
import { Button, Card, Form, MenuItemProps, Modal, notification, Tag } from 'antd';
import { BiEdit, BiMailSend } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import { requestSendBulkEmail, requestSendEmail } from './helpers';
import { BsEye } from 'react-icons/bs';
import { GrDocumentPdf } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { AntdDataTable } from '@main/components/data-table-component';

function makeColorStatus(status) {
  if (status === EnumStatusData.active) return EnumColorStatusData.active;
  else if (status === EnumStatusData.inactive) return EnumColorStatusData.inactive;
  return '';
}

const Filter: FormColumnProps[] = [
  {
    fields: [
      {
        label: 'Gender',
        name: 'gender',
        fieldType: 'selectPaginate',
        gridColumn: { span: 12 },
        selectPaginateProps: {
          dataSourceUrl: ApiUrlData.enum_gender,
          customLabel(row) {
            return row;
          },
          // isClearable: true,
          // options: OptionGender,
          // customLabel: (value) => OptionGender.find((item) => item.value === value?.toLowerCase())?.label,
        },
      },
      {
        label: 'Has join care group?',
        name: 'has_join_care_group',
        gridColumn: { span: 12 },
        fieldType: 'select',
        selectProps: {
          isClearable: true,
          options: OptionsYesOrNo,
          customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
        },
      },
      // {
      //   label: 'Care Group Leader',
      //   name: 'care_group_leader',
      //   gridColumn: { span: 12 },
      //   fieldType: 'selectPaginate',
      //   selectPaginateProps: {
      //     isMulti: true,
      //     dataSourceUrl: ApiUrlData.contact,
      //     customLabel(row) {
      //       return row?.name;
      //     },
      //     customFilterRequest: () => {
      //       return {
      //         is_leader: true,
      //       };
      //     },
      //   },
      // },
      {
        label: 'Has attend IFGF?',
        name: 'has_attend_ifgf',
        gridColumn: { span: 12 },
        fieldType: 'select',
        selectProps: {
          isClearable: true,
          options: OptionsYesOrNo,
          customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
        },
      },
      {
        label: 'Is Checker?',
        name: 'is_checker',
        gridColumn: { span: 12 },
        fieldType: 'select',
        selectProps: {
          isClearable: true,
          options: OptionsYesOrNo,
          customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
        },
      },
      {
        label: 'Discipleship Center',
        name: 'discipleship_centers',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.region,
          transformOptions(options) {
            const newOptions = [{ code: 'None', id: 'null' }, ...options];
            return newOptions;
          },
        },
      },
      {
        label: 'Role Position',
        name: 'role_positions',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          keySearch: 'code',
          isMulti: true,
          dataSourceUrl: ApiUrlData.user_level,
        },
      },
      {
        label: 'HCC',
        name: 'hccs',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.contact,
          customLabel(row) {
            return row?.name;
          },
          customFilterRequest: () => {
            return {
              is_hcc: true,
            };
          },
        },
      },
      {
        label: 'ICC Name',
        name: 'iccs',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.contact,
          customLabel(row) {
            return row?.name;
          },
          customFilterRequest: () => {
            return {
              is_icc: true,
            };
          },
        },
      },
      {
        label: 'Created at',
        name: 'created_at',
        gridColumn: { span: 12 },
        fieldType: 'dateRangePicker',
      },
      {
        label: 'Updated at',
        name: 'updated_at',
        gridColumn: { span: 12 },
        fieldType: 'dateRangePicker',
      },
    ],
  },
];

const defaultButtonStyle = {
  marginLeft: '12px',
  marginRight: '12px',
  fontSize: '12px',
};

export default function IndexPage() {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { recordDataTable, selectedDataTable } = usePageProvider();
  const totalData = recordDataTable?.length ?? 0;
  const totalSelected = selectedDataTable?.length ?? 0;
  const showAdditionalToolbar = !totalSelected || totalSelected === 0;

  const account = authHelper.getAccount();
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.contact });

  useEffect(() => {
    if (account && !isAdmin) {
      navigate('/qr-code');
    }
  }, [isAdmin]);

  const showModal = () => setModalOpen(true);
  const handleCancel = () => setModalOpen(false);

  function handleBlobResponse(blobFileData) {
    const url = window.URL.createObjectURL(new Blob([blobFileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'qr-file.pdf'); // nama file dan extension sesuaikan dengan file yang di download
    document.body.appendChild(link);
    link.click();
  }

  async function handleOk() {
    const values = form.getFieldsValue();

    const params = {
      role_position_ids: makeArrayIds({ data: values?.criteria_types }),
      discipleship_center_ids: makeArrayIds({ data: values?.discipleship_center }),
    };

    await dataSource.handleCustomRequest({
      paramRequest: {
        method: 'GET',
        url: process.env.REACT_APP_BASE_URL + ApiUrlData.contact + '/download-qr-codes',
        data: {},
        responseType: 'blob',
        params,
      },
      onSuccess({ response }) {
        // console.log({ response });
        handleBlobResponse(response);
        // notification.success({
        //   message: 'Success',
        //   description: `The qr code has been download`,
        // });
        form.resetFields();
        setModalOpen(false);
      },
      onFailed({ message }) {
        notification.error({
          message: 'Error',
          description: `${message?.[0]}`,
        });
      },
    });
  }

  const renderAction = (item, row) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => navigate(`/users/detail?id=${row?.id}`)}
          size="small"
          type="text"
          icon={<AntdIconWrapper>{<BsEye />}</AntdIconWrapper>}
        ></Button>
        <div style={{ marginLeft: 7 }}></div>
        <Button
          size="small"
          type="text"
          onClick={() => navigate(`/users/update?id=${row?.id}`)}
          icon={<AntdIconWrapper>{<BiEdit />}</AntdIconWrapper>}
        ></Button>
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      key: 'action',
      width: 60,
      dataIndex: 'action',
      render: renderAction,
    },
    {
      title: 'Status',
      key: 'status',
      width: 80,
      sorter: true,
      render(value, record, index) {
        const item = record?.status?.toLowerCase();
        if (!item) return <div></div>;
        return <Tag color={makeColorStatus(item)}>{stringFormatter.capitalizeEachWord(item)}</Tag>;
      },
    },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      dataIndex: 'name',
      ellipsis: true,
      sorter: true,
    },
    {
      title: 'Email',
      key: 'email',
      width: 200,
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Is Checker',
      key: 'is_checker',
      width: 120,
      dataIndex: 'is_checker',
      sorter: true,
      // ellipsis: true,
      render(value, record, index) {
        return value ? 'Yes' : 'No';
      },
    },
    {
      width: 150,
      title: 'Date of Birth',
      dataIndex: ['date_of_birth'],
      key: 'date_of_birth',
      render(item) {
        if (!item) return '-';
        return moment(item).format('DD-MM-YYYY');
      },
      sorter: true,
    },
    {
      width: 100,
      title: 'Gender',
      dataIndex: ['gender'],
      key: 'gender',
      sorter: true,
      render(item) {
        if (!item) return '-';
        return stringFormatter.capitalizeEachWord(item);
      },
    },
    {
      width: 150,
      title: 'Phone Number',
      dataIndex: ['phone_number'],
      key: 'phone_number',
      sorter: true,
      render(item, row) {
        if (!item) return '-';
        return `${row?.prefix_phone ?? ''} ${item}`;
      },
    },

    {
      width: 150,
      title: 'Join Care Group?',
      dataIndex: ['has_join_care_group'],
      key: 'has_join_care_group',
      sorter: true,
      render(item) {
        if (!item) return '-';
        if (item === true) return 'Yes';
      },
    },
    {
      width: 150,
      title: 'Has Attend IFGF',
      dataIndex: ['has_attend_ifgf'],
      key: 'has_attend_ifgf',
      sorter: true,
      render(item) {
        if (!item) return '-';
        if (item === true) return 'Yes';
      },
    },
    {
      width: 170,
      title: 'Discipleship Center',
      dataIndex: ['discipleship_center'],
      key: 'discipleship_center',
      sorter: true,
      render(item) {
        if (!item) return '-';
        return item?.name;
      },
    },
    {
      width: 150,
      title: 'QR Code Active',
      dataIndex: ['is_active_qr'],
      key: 'is_active_qr',
      sorter: false,
      render(item) {
        return item ? 'Yes' : 'No';
      },
    },

    {
      width: 150,
      title: 'Last Email Sent',
      dataIndex: ['last_sent_email'],
      key: 'last_sent_email',
      sorter: true,
      render(item) {
        if (!item) return '-';
        return moment(Number(item)).format('DD-MM-YYYY HH:mm');
      },
    },
  ];

  async function handleSendEmail(data) {
    setLoading(true);
    await requestSendEmail(data);
    setLoading(false);
  }

  async function sendBulkEmail() {
    setLoading(true);
    await requestSendBulkEmail();
    setLoading(false);
  }

  const additionalRowActions = (row): MenuItemProps[] => {
    const columns = [
      {
        key: 'Send Email',
        icon: <BiMailSend />,
        label: 'Send Email',
        onClick(e) {
          e.domEvent.stopPropagation();
          handleClickSingleSendEmail(row);
        },
      },
    ];
    return columns;
  };

  function handleClickSingleSendEmail(row) {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Send Email Confirmation',
      content: 'Are you sure you want to send email to this user?',
      onOk: () => handleSendEmail(row),
    });
  }

  function handleClickBulkSendEmail() {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Broadcast Email Confirmation',
      content: 'Are you sure you want to broadcast email to all user?',
      onOk: () => sendBulkEmail(),
    });
  }

  const isMobile = useMediaQuery({ media: 'mobile' });

  const additionalToolbar: ButtonToolbarWrapperProps[] = [
    {
      buttonTitle: 'Broadcast email',
      tooltipTitle: 'Broadcast email',
      icon: <BiMailSend />,
      loadingButton: loading,
      handleClick: handleClickBulkSendEmail,
      key: 'email',
    },
    {
      buttonTitle: 'Download QR List',
      tooltipTitle: 'Download QR List',
      icon: <GrDocumentPdf />,
      loadingButton: loading,
      handleClick: showModal,
      key: 'qr-code',
    },
  ].filter((toolbar) => (isAdmin ? ['email', 'qr-code'].includes(toolbar.key) : ['email'].includes(toolbar.key)));

  return (
    <React.Fragment>
      <IndexPageWrapper
        pageToolbarProps={{
          pageTitle: 'User',
          showSearchBadge: true,
          showFilterBadge: true,
          showConfirmProcess: false,
          additionalButtonAction: showAdditionalToolbar ? additionalToolbar : [],
        }}
        drawerFilterProp={{
          ...(isMobile ? { width: '100%' } : {}),
          formBuilderProps: {
            columns: Filter,
          },
        }}
      >
        <Card>
          <AntdDataTable
            columns={columns as any}
            defaultColumnIgnored={['status']}
            rowActionProps={{
              // additionalActions: additionalRowActions,
              actionIgnored: isMobile ? [] : ['update', 'detail'],
            }}
            loading={loading}
            onRow={null}
          />
        </Card>

        <Modal
          title="Download QR List"
          open={modalOpen}
          onOk={async () => {
            try {
              await form.validateFields();
              handleOk();
            } catch (error) {}
          }}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="criteria_types"
              label="Criteria Types"
              rules={[{ required: true, message: 'Criteria Types is required.' }]}
            >
              <SelectPaginate
                dataSourceUrl={ApiUrlData.user_level}
                keySearch="code"
                isMulti={true}
                transformOptions={(options) => {
                  const newOptions = [
                    ...options,
                    { id: '5885b7c6-5e9b-43db-92d0-d089c0dc7908', code: 'Kids' },
                    { id: 'ad68a838-22d7-4c07-bfba-a2bf6fe3e929', code: 'Blank Role Position' },
                  ];
                  return newOptions;
                }}
              />
            </Form.Item>
            <Form.Item
              name="discipleship_center"
              label="Discipleship Center"
              rules={[{ required: true, message: 'Discipleship Center is required.' }]}
            >
              <SelectPaginate dataSourceUrl={ApiUrlData.region} keySearch="name" />
            </Form.Item>
          </Form>
        </Modal>
      </IndexPageWrapper>
    </React.Fragment>
  );
}
