import { omit } from 'lodash';
import { AttendanceEntity } from '../entities';
import { BaseEntity, BaseTransformer } from '@eigen3m/react-base-frontend';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

export class AttendanceTransformer<E extends BaseEntity = AttendanceEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = omit(payload, ['id', 'form_action']);
    return {
      ...cleanVal,
    };
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    if (!payload) return payload;
    return payload;
  }

  transformerGetIndex(payload: E[]): E[] {
    if (!payload) return [];
    return payload.map((item) => {
      return {
        ...item,
        customRowKeyTable: item.id + '__' + uuidV4(),
      };
    });
  }

  transformerFilterIndexTable(payload: any) {
    if (!payload) return payload;
    const resultDate = (data) => {
      return data?.map((item, idx) => {
        if (idx === 0) {
          return moment(`${moment(item).format('YYYY-MM-DD')} 00:00:00`);
        }
        if (idx === 1) {
          return moment(`${moment(item).format('YYYY-MM-DD')} 23:59:59`);
        }
      });
    };
    const createdDate = resultDate(payload?.created_at);
    const updatedDate = resultDate(payload?.updated_at);
    const eventDate = resultDate(payload?.date);
    const checkInDate = resultDate(payload?.check_in_time);
    return {
      ...payload,
      user_ids: payload.user_ids?.map((item) => item.id),
      event_ids: payload?.events?.map((item) => item?.id),
      events: [],
      sub_region_ids: payload?.sub_regions?.map((item) => item?.id),
      sub_regions: [],
      care_group_leader_ids: payload?.care_group_leaders?.map((item) => item?.id),
      care_group_leaders: [],
      discipleship_center_ids: payload?.discipleship_centers?.map((item) => item?.id),
      discipleship_centers: [],
      created_from: payload?.created_at?.[0] ? new Date(createdDate?.[0]).valueOf() : null,
      created_to: payload?.created_at?.[1] ? new Date(createdDate?.[1]).valueOf() : null,
      created_at: [],
      updated_from: payload?.updated_at?.[0] ? new Date(updatedDate?.[0]).valueOf() : null,
      updated_to: payload?.updated_at?.[1] ? new Date(updatedDate?.[1]).valueOf() : null,
      updated_at: [],
      date_from: payload?.date?.[0] ? moment(eventDate?.[0]).format('YYYY-MM-DD') : null,
      date_to: payload?.date?.[1] ? moment(eventDate?.[1]).format('YYYY-MM-DD') : null,
      date: [],
      check_in_time_from: payload?.check_in_time?.[0] ? new Date(payload?.check_in_time?.[0]).valueOf() : null,
      check_in_time_to: payload?.check_in_time?.[1] ? new Date(payload?.check_in_time?.[1]).valueOf() : null,
      check_in_time: [],
    };
  }
}
