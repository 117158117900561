import { RenderAddress } from '@eigen3m/react-base-frontend';
import { TableColumnsType } from 'antd';

export const columns: TableColumnsType<any> = [
  {
    title: 'Name',
    key: 'information.name',
    width: 200,
    dataIndex: 'information.name',
    ellipsis: true,
    sorter: true,
    render(value, record, index) {
      return record?.information?.name;
    },
  },
];
