import { ApiUrlData } from '@base-configs';
import { authHelper, FormBuilder, makeCommonDataSource, usePageProvider } from '@eigen3m/react-base-frontend';
import { OptionGender, OptionsYesOrNo } from '@pages/users/presentation/helpers';
import { phoneNumberPrefixOptions } from '@pages/users/presentation/pages/helpers';
import { Form, Select, Tabs } from 'antd';
import { typeOf } from 'mathjs';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { InputAddress } from '../form-address/input-address';
import { RelativeComponent } from '../relative-form';

const { Option } = Select;

interface Props {
  isCreate?: boolean;
  isRegister?: boolean;
  isUpdate?: boolean;
}

const prefixPhoneSelector = (
  <Form.Item name="prefix_phone" noStyle>
    <Select
      style={{ width: 70 }}
      defaultValue={'+62'}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {/* <Option value="+62">+62</Option> */}
      {phoneNumberPrefixOptions?.map((item, index) => {
        return (
          <Option key={index} value={item?.dial_code}>
            {item?.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

function index(props: Props) {
  const { isCreate, isRegister, isUpdate } = props;
  const { detailData } = usePageProvider();
  const account = authHelper.getAccount();
  const isNotAdmin = account?.user?.username !== process.env.REACT_APP_ADMIN_EMAIL;
  const isAdmin = account?.user?.username === process.env.REACT_APP_ADMIN_EMAIL;

  return (
    <div id="form-user">
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldsValue }) => {
          const values = getFieldsValue();
          // console.log({ values });
          const hasJoinCareGroup = values?.has_join_care_group === 'true';
          const hasAttendIfgf = values?.has_attend_ifgf === 'true';
          const maritalStatus = values?.marital_status;
          const anniversaryDate = values?.anniversary_date;

          const onChangeCareGroup = (value) => {
            const newRelatives = values?.relatives?.map((item) => {
              let has_join_care_group = item?.contact_relative?.has_join_care_group;
              let care_group_leader = item?.contact_relative?.care_group_leader;

              if (item?.contact_relative?.same_ifgf_info_as_main_user) {
                has_join_care_group = value;
                care_group_leader = null;
              }
              return {
                ...item,
                contact_relative: {
                  ...item?.contact_relative,
                  care_group_leader,
                  has_join_care_group,
                },
              };
            });
            setFieldsValue({
              care_group_leader: null,
              relatives: newRelatives,
            });
          };

          const onChangeCareGroupLeader = (value) => {
            const newRelatives = values?.relatives?.map((item) => {
              let care_group_leader = item?.contact_relative?.care_group_leader;
              if (item?.contact_relative?.same_ifgf_info_as_main_user) {
                care_group_leader = value;
              }
              return {
                ...item,
                contact_relative: {
                  ...item?.contact_relative,
                  care_group_leader,
                },
              };
            });
            setFieldsValue({
              relatives: newRelatives,
            });
          };

          const onChangeHasAttend = () => {
            setFieldsValue({
              discipleship_center: isAdmin ? values?.discipleship_center : null,
            });
          };

          const onChangeMaritalStatus = (value) => {
            if (value?.toLowerCase() !== 'married') {
              setFieldsValue({
                anniversary_date: null,
              });
            } else if (isAdmin && value?.toLowerCase() === 'married' && !anniversaryDate) {
              setFieldsValue({
                anniversary_date: moment().set({ year: 1901, month: 0, date: 1 }),
              });
            }
          };

          const onChangeAddress = (value) => {
            const newRelatives = values?.relatives?.map((item) => {
              let address = item?.contact_relative?.address;
              if (item?.contact_relative?.same_address_as_main_user) address = value;
              return {
                ...item,
                contact_relative: {
                  ...item?.contact_relative,
                  address,
                },
              };
            });
            setFieldsValue({
              relatives: newRelatives,
            });
          };

          const dateOfBirth = values?.date_of_birth;
          const age = moment().diff(dateOfBirth, 'years', false);
          const actionForm = values?.form_action;
          return (
            <FormBuilder
              columns={[
                {
                  title: 'INDIVIDUAL INFORMATION',
                  fields: [
                    {
                      label: 'Name',
                      name: 'name',
                      fieldType: 'inputText',
                      rules: [{ required: true }],
                      inputTextProps: {
                        allowClear: true,
                      },
                    },
                    {
                      label: 'Date of Birth',
                      name: 'date_of_birth',
                      rules: [
                        { required: true, message: 'Date of birth is required!' },
                        {
                          validator(_, value) {
                            const age = moment().diff(value, 'years', false);
                            if (isNotAdmin && age < 12) {
                              return Promise.reject(
                                new Error(
                                  'Cannot create account for user below 12 y.o. Please add account through parent profile',
                                ),
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ],
                      gridColumn: { xxl: 3, xl: 4, md: 8, sm: 8, xs: 24 },
                      fieldType: 'datePicker',
                      datePickerProps: {
                        style: { width: '100%' },
                      },
                    },
                    {
                      label: 'Email',
                      name: 'email',
                      fieldType: 'inputText',
                      rules: [
                        { type: 'email' },
                        {
                          required: age < 12 ? (isAdmin ? false : true) : true,
                          message: 'Email is required.',
                        },
                        {
                          validator(_, value) {
                            const userRelatives = values?.relatives ?? [];
                            const isEmailSameAsRelatives = userRelatives?.some(
                              (item) =>
                                !!item?.contact_relative?.email &&
                                item?.contact_relative?.email?.toLowerCase() === value?.toLowerCase(),
                            );

                            if (!isEmailSameAsRelatives) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Email cannot be the same as relatives email'));
                          },
                        },
                      ],
                      inputTextProps: {
                        allowClear: true,
                      },
                    },
                    {
                      label: 'Password',
                      name: 'password',
                      fieldType: 'inputPassword',
                      rules: [
                        {
                          required:
                            isCreate || ['create', 'duplicate']?.includes(actionForm)
                              ? age < 12
                                ? isAdmin
                                  ? false
                                  : true
                                : true
                              : false,
                          message: 'Password is required',
                        },
                      ],
                      inputPasswordProps: {
                        allowClear: true,
                        autoComplete: 'new-password',
                      },
                    },
                    {
                      label: 'Confirm Password',
                      name: 'retype_password',
                      fieldType: 'inputPassword',
                      dependencies: ['password'],
                      hasFeedback: true,
                      rules: [
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const password = getFieldValue('password');
                            if (!password) return Promise.resolve();
                            else if (password === value) return Promise.resolve();
                            else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                            else return Promise.reject(new Error("Passwords doesn't match!"));
                          },
                        }),
                      ],
                      inputPasswordProps: {
                        allowClear: true,
                        autoComplete: 'new-password',
                      },
                    },
                    {
                      label: 'Marital Status',
                      name: 'marital_status',
                      fieldType: 'selectPaginate',
                      rules: [{ required: true, message: 'Marital status is required!' }],
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.enum_marital_status,
                        customLabel(row) {
                          return row;
                        },
                        onChange: onChangeMaritalStatus,
                      },
                    },
                    {
                      label: 'Anniversary Date',
                      name: 'anniversary_date',
                      rules: [
                        {
                          required: maritalStatus && maritalStatus?.toLowerCase() === 'married',
                          message: 'Anniversary date is required!',
                        },
                      ],
                      customHidden(form) {
                        return maritalStatus?.toLowerCase() !== 'married';
                      },
                      fieldType: 'datePicker',
                    },
                    {
                      label: 'Gender',
                      name: 'gender',
                      rules: [{ required: true, message: 'Gender is required!' }],
                      fieldType: 'select',
                      selectProps: {
                        isClearable: false,
                        options: OptionGender,
                        customLabel: (value) => OptionGender.find((item) => item.value === value?.toLowerCase())?.label,
                      },
                    },
                    {
                      gridColumn: { xs: 24, sm: 8, md: 9, lg: 5, xl: 5 },
                      label: 'Phone Number',
                      name: 'phone_number',
                      rules: [
                        { pattern: /^[1-9][0-9]*$/, message: 'Phone number is invalid and cannot start with 0!' },
                        { required: age < 12 ? (isAdmin ? false : true) : true, message: 'Phone number is required!' },
                      ],
                      fieldType: 'inputText',
                      inputTextProps: {
                        allowClear: true,
                        addonBefore: prefixPhoneSelector,
                      },
                    },
                    {
                      gridColumn: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },

                      renderField(form) {
                        return (
                          <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                              { required: true, message: 'Address is required!' },
                              {
                                validator(_, value) {
                                  if (!value?.address) {
                                    return Promise.reject('Address is required!');
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                          >
                            <InputAddress showMapIcon={true} placeholder="Input address" onChange={onChangeAddress} />
                          </Form.Item>
                        );
                      },
                      // label: 'Address',
                      // name: 'address',
                      // rules: [{ required: true, message: 'Address is required!' }],
                      // fieldType: 'inputAddress',
                      // inputAddressProps: {
                      //   showMapIcon: true,
                      //   manualAddressConfig: {
                      //     showCountry: true,
                      //     showProvince: true,
                      //     showCity: true,
                      //     showDistrict: true,
                      //     showVillage: true,
                      //     showHamlet: true,
                      //     showPostalCode: true,
                      //   },
                      //   onChange: onChangeAddress,
                      // },
                    },

                    {
                      label: 'Has join care group?',
                      name: 'has_join_care_group',
                      fieldType: 'select',
                      rules: [{ required: !isNotAdmin, message: 'Has join care group is required!' }],
                      selectProps: {
                        onChange: onChangeCareGroup,
                        isClearable: false,
                        options: OptionsYesOrNo,
                        customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                      },
                      customHidden(form) {
                        if (isRegister) return true;
                        if (isNotAdmin) return true;
                        return false;
                      },
                    },
                    {
                      label: 'Care Group Leader',
                      name: 'care_group_leader',
                      fieldType: 'selectPaginate',
                      customHidden(form) {
                        const hasJoin = form.getFieldValue('has_join_care_group');
                        return !hasJoin || hasJoin === 'false' || isNotAdmin;
                      },
                      rules: [
                        { required: hasJoinCareGroup && !isNotAdmin },
                        // ({ getFieldValue }) => ({
                        //   validator(_, value) {
                        //     const hasJoin = getFieldValue('has_join_care_group');
                        //     const isRequired = hasJoin === 'true';
                        //     const noValue = !value;
                        //     if (isRequired && noValue)
                        //       return Promise.reject(new Error('Care group leader is required!'));
                        //     return Promise.resolve();
                        //   },
                        // }),
                      ],
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.contact,
                        onChange: onChangeCareGroupLeader,
                        customLabel(row) {
                          return row?.name;
                        },
                        customFilterRequest: () => {
                          return {
                            is_leader: true,
                          };
                        },
                      },
                    },
                    {
                      label: 'ICC Name',
                      name: 'icc',
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.contact,
                        customLabel(row) {
                          return row?.name;
                        },
                        // styles: {
                        //   menuPortal: (base): any => ({
                        //     ...base,
                        //     position: 'absolute important',
                        //     zIndex: 999999999,
                        //   }),
                        // },
                        customFilterRequest: () => {
                          return {
                            is_icc: true,
                          };
                        },
                      },
                      customHidden(form) {
                        if (isRegister) return true;
                        if (isNotAdmin) return true;
                        return false;
                      },
                    },
                    {
                      label: 'IHC Name',
                      name: 'ihc',
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.contact,
                        customLabel(row) {
                          return row?.name;
                        },
                        customFilterRequest: () => {
                          return {
                            is_ihc: true,
                          };
                        },
                      },
                      customHidden(form) {
                        if (isRegister) return true;
                        if (isNotAdmin) return true;
                        return false;
                      },
                    },
                    {
                      label: 'HCC',
                      name: 'hcc',
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.contact,
                        customLabel(row) {
                          return row?.name;
                        },
                        customFilterRequest: () => {
                          return {
                            is_hcc: true,
                          };
                        },
                      },
                      customHidden(form) {
                        if (isRegister) return true;
                        if (isNotAdmin) return true;
                        return false;
                      },
                    },
                    {
                      label: 'Has attend IFGF?',
                      name: 'has_attend_ifgf',
                      fieldType: 'select',
                      rules: [
                        { required: isNotAdmin && isUpdate ? false : true, message: 'Has attend IFGF is required!' },
                      ],
                      selectProps: {
                        onChange: onChangeHasAttend,
                        isClearable: false,
                        options: OptionsYesOrNo,
                        customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                      },
                      customHidden() {
                        return isNotAdmin && isUpdate ? true : false;
                      },
                    },
                    {
                      label: 'Discipleship Center',
                      name: 'discipleship_center',
                      fieldType: 'selectPaginate',
                      customHidden(form) {
                        const hasAttend = form.getFieldValue('has_attend_ifgf');
                        // return !hasAttend || hasAttend === 'false' || isRegister || isNotAdmin;
                        return isNotAdmin && isUpdate ? true : isAdmin ? false : !hasAttend || hasAttend === 'false';
                      },
                      rules: [
                        // { required: hasAttendIfgf && !isRegister && !isNotAdmin },
                        {
                          required: isNotAdmin && isUpdate ? false : isAdmin ? false : hasAttendIfgf,
                          message: 'Discipleship Center is required.',
                        },
                        // ({ getFieldValue }) => ({
                        //   validator(_, value) {
                        //     const hasAttend = getFieldValue('has_attend_ifgf');
                        //     const isRequired = hasAttend === 'true';
                        //     const noValue = !value;
                        //     if (isRequired && noValue)
                        //       return Promise.reject(new Error('Discipleship center is required!'));
                        //     return Promise.resolve();
                        //   },
                        // }),
                      ],
                      selectPaginateProps: {
                        keySearch: 'search',
                        dataSourceUrl: ApiUrlData.region,
                      },
                    },
                    {
                      label: 'Role Position',
                      name: 'role_position',
                      fieldType: 'selectPaginate',
                      selectPaginateProps: {
                        keySearch: 'code',
                        dataSourceUrl: ApiUrlData.user_level,
                        styles: {
                          // input: (base: any) => ({

                          // }),
                          menuPortal: (base: any) => ({
                            ...base,
                            position: 'fixed !important',
                            zIndex: '99999999 !important',
                          }),
                        },
                      },
                      customHidden(form) {
                        if (isRegister) return true;
                        if (isNotAdmin) return true;
                        return false;
                      },
                    },
                    {
                      label: 'Last Email Sent',
                      name: 'last_sent_email',
                      fieldType: 'datePicker',
                      datePickerProps: {
                        showTime: true,
                      },
                      customHidden(form) {
                        return (!detailData?.last_sent_email && !isNotAdmin) || isNotAdmin;
                      },
                    },
                    {
                      label: '',
                      gridColumn: { span: 24 },
                      name: 'is_checker',
                      fieldType: 'checkbox',
                      checkboxProps: {
                        children: <span>Is Checker</span>,
                      },
                      customHidden(form) {
                        if (isNotAdmin) return true;
                        return false;
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>
      {/* <FormBuilder
        columns={[
          {
            fields: [
              {
                label: 'Has join care group?',
                name: 'has_join_care_group',
                fieldType: 'select',
                rules: [{ required: true, message: 'Has join care group is required!' }],
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'Care Group Leader',
                name: 'care_group_leader',
                fieldType: 'select',
                customHidden(form) {
                  const hasJoin = form.getFieldValue('has_join_care_group');
                  return !hasJoin || hasJoin === 'no';
                },
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const hasJoin = getFieldValue('has_join_care_group');
                      const isRequired = hasJoin === 'yes';
                      const noValue = !value;
                      if (isRequired && noValue) return Promise.reject(new Error('Care group leader is required!'));
                      return Promise.resolve();
                    },
                  }),
                ],
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'Has attend IFGF?',
                name: 'has_attend_ifgf',
                fieldType: 'select',
                rules: [{ required: true, message: 'Has attend IFGF is required!' }],
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'Discipleship Center',
                name: 'discipleship_center',
                fieldType: 'select',
                customHidden(form) {
                  const hasAttend = form.getFieldValue('has_attend_ifgf');
                  return !hasAttend || hasAttend === 'no';
                },
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const hasAttend = getFieldValue('has_attend_ifgf');
                      const isRequired = hasAttend === 'yes';
                      const noValue = !value;
                      if (isRequired && noValue) return Promise.reject(new Error('Discipleship center is required!'));
                      return Promise.resolve();
                    },
                  }),
                ],
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'Role Position',
                name: 'role_position',
                fieldType: 'select',
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'HCC',
                name: 'hcc',
                fieldType: 'select',
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'IHC Name',
                name: 'ihc_name',
                fieldType: 'select',
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
              {
                label: 'ICC Name',
                name: 'icc_name',
                fieldType: 'select',
                selectProps: {
                  isClearable: false,
                  options: OptionsYesOrNo,
                  customLabel: (value) => OptionsYesOrNo.find((item) => item.value === value)?.label,
                },
              },
            ],
          },
        ]}
      /> */}
      <Form.Item name="is_leader" hidden></Form.Item>
      <Form.Item name="is_hcc" hidden></Form.Item>
      <Form.Item name="is_ihc" hidden></Form.Item>
      <Form.Item name="is_icc" hidden></Form.Item>
      <Form.Item name="is_speaker" hidden></Form.Item>
      <Form.Item name="company" hidden></Form.Item>
      <Tabs>
        <Tabs.TabPane tab="Relative">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              return (
                <Form.Item name="relatives" noStyle>
                  <RelativeComponent isRegister={isRegister} dataGeneral={values} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default index;
