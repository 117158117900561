import React from 'react';
import { DetailBuilder } from '@eigen3m/react-base-frontend';

export default function EventDetail() {
  return (
    <React.Fragment>
      <DetailBuilder
        columns={[
          {
            fields: [
              {
                gridColumn: { span: 24 },
                label: 'Name',
                name: ['detail_data', 'information', 'name'],
                fieldType: 'text',
                renderTextProps: {
                  defaultRender: () => '-',
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
}
