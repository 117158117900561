export function queryTransformer(query) {
  if (!query) return {};
  const dimensions = (query.dimensions ?? []).map((item) => item.value);
  const measures = (query.measures ?? []).map((item) => item.value);
  let filters = (query.filters ?? []).map((item) => {
    return {
      member: item?.member?.value,
      operator: item?.operator?.value,
      values: (item?.values ?? []).map((itemValues) => itemValues.value),
    };
  });

  let timeDimensions = [
    {
      dimension: query?.timeDimensions?.dimension?.value,
      granularity: query?.timeDimensions?.granularity?.value,
      dateRange: query?.timeDimensions?.dateRange?.value,
    },
  ];

  if (!filters[0]?.member) filters = undefined;
  if (!timeDimensions[0]?.dimension) timeDimensions = undefined;

  const newQuery = { ...query, dimensions, measures, timeDimensions, filters };
  return newQuery;
}
