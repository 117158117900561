import { AntdIconWrapper, FormBuilder, useMediaQuery } from '@eigen3m/react-base-frontend';
import { FormBuilderProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';
import { Row, Col, Image, Form, Button, Divider } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GoogleLogo from './assets/google.png';
import './style.less';

interface LoginTemplate1Props {
  loadingLogin?: boolean;
  rightContentImage?: any;
  logoImage?: any;
  formBuilderProps?: FormBuilderProps;
  loginWithGoogleUrl?: string;
  registerWithGoogleUrl?: string;
  onClickManualRegister?(): void;
  onClickForgotPassword?(): void;
  onSubmitLogin?(payload: any): void;
  handleSubmitByEnter?(event: any, values: any): void;
}
export function LoginTemplate1(props: LoginTemplate1Props) {
  const {
    rightContentImage,
    logoImage,
    formBuilderProps = {},
    loginWithGoogleUrl = '',
    onClickManualRegister,
    registerWithGoogleUrl,
    onClickForgotPassword,
    onSubmitLogin,
    loadingLogin = false,
    handleSubmitByEnter,
  } = props;

  const [formType, setFormType] = useState('login');
  const [form] = Form.useForm();

  const isMobile = useMediaQuery({ media: 'mobile' });

  return (
    <div className="login-page-template-1">
      <Row>
        <Col xs={24} sm={24} lg={9}>
          <div className={`form-content-wrapper ${isMobile ? 'form-content-wrapper-mobile' : ''}`}>
            <div>
              <div className="logo-wrapper">
                {logoImage && <Image className="login-logo" src={logoImage} preview={false} />}
              </div>

              {formType === 'login' && (
                <React.Fragment>
                  <div className="login-title">Log In </div>
                  <div className="login-sub-title">
                    Welcome, please put your login credential below to start using the app.{' '}
                  </div>
                  <div>
                    <Form form={form} name="form-login" layout={'vertical'} onFinish={onSubmitLogin} autoComplete="off">
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldsValue }) => {
                          const values = getFieldsValue();
                          return (
                            <FormBuilder
                              {...formBuilderProps}
                              columns={formBuilderProps?.columns?.map((column) => {
                                return {
                                  ...column,
                                  fields: column?.fields?.map((field) => {
                                    if (field?.name === 'password') {
                                      return {
                                        ...field,
                                        inputPasswordProps: {
                                          ...field?.inputPasswordProps,
                                          onKeyDown: (event) => handleSubmitByEnter(event, values),
                                        },
                                      };
                                    }
                                    return {
                                      ...field,
                                    };
                                  }),
                                };
                              })}
                            />
                          );
                        }}
                      </Form.Item>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <Button size="small" type="link" disabled={loadingLogin} onClick={onClickForgotPassword}>
                        Forgot Password
                      </Button>
                    </div>
                    <div style={{ marginBottom: '20px' }}></div>
                    <Button
                      size="large"
                      type="primary"
                      disabled={loadingLogin}
                      style={{ width: '100%' }}
                      onClick={() => form.submit()}
                    >
                      Log In
                    </Button>
                    {/* <div style={{ marginBottom: '20px' }}></div>
                    <Divider orientation="center" plain>
                      Or log in with
                    </Divider>
                    <div style={{ marginBottom: '20px' }}></div>
                    <Button
                      size="large"
                      style={{ width: '100%' }}
                      disabled={loadingLogin}
                      onClick={() => window.location.replace(loginWithGoogleUrl)}
                    >
                      <div>
                        <img src={GoogleLogo} height={'15'} style={{ marginRight: '10px' }} />
                        <span style={{ paddingTop: '10px', fontSize: '12px' }}>LOGIN WITH GOOGLE</span>
                      </div>
                    </Button> */}
                  </div>
                  <div style={{ marginBottom: '20px' }}></div>
                  <div>
                    <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                      <span style={{ color: 'grey' }}>{`Don't have an account?`}</span>
                      <span
                        style={{ marginLeft: '5px', fontWeight: 600, cursor: loadingLogin ? '' : 'pointer' }}
                        onClick={() => !loadingLogin && onClickManualRegister()}
                        // onClick={() => !loadingLogin && setFormType('register')}
                      >
                        Create account
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {formType === 'register' && (
                <React.Fragment>
                  <div className="login-title">Register</div>
                  <div className="login-sub-title">choose your method to register.</div>
                  <div style={{ marginBottom: '30px' }}></div>

                  <div>
                    {/* <Button
                      size="large"
                      style={{ width: '100%' }}
                      onClick={() => window.location.replace(loginWithGoogleUrl)}
                    >
                      <div>
                        <img src={GoogleLogo} height={'15'} style={{ marginRight: '10px' }} />
                        <span style={{ paddingTop: '10px', fontSize: '12px' }}>REGISTER WITH GOOGLE</span>
                      </div>
                    </Button> */}
                    <div style={{ marginBottom: '20px' }}></div>
                    <Button size="large" style={{ width: '100%' }} onClick={onClickManualRegister}>
                      MANUAL REGISTER
                    </Button>
                  </div>
                  <div style={{ marginBottom: '30px' }}></div>
                  <div>
                    <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                      <span style={{ color: 'grey' }}>{`Have an account?`}</span>
                      <span
                        style={{ marginLeft: '5px', fontWeight: 600, cursor: 'pointer' }}
                        onClick={() => setFormType('login')}
                      >
                        Log In
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} lg={15}>
          {rightContentImage && (
            <img src={rightContentImage} style={{ height: '100vh', width: '100%', objectFit: 'cover' }} />
          )}
        </Col>
      </Row>
    </div>
  );
}
