import { ApiUrlData } from '@base-configs';
import { FormColumnProps } from '@eigen3m/react-base-frontend/dist/base/presentation/components/form-components/form-builder';

export const Filter: FormColumnProps[] = [
  {
    fields: [
      {
        label: 'Events',
        name: 'events',
        fieldType: 'selectPaginate',
        gridColumn: { span: 12 },
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.event,
          customLabel(row) {
            return row?.information?.name;
          },
        },
      },
      {
        label: 'Event Date',
        name: 'date',
        gridColumn: { span: 12 },
        fieldType: 'dateRangePicker',
      },
      {
        label: 'Check In Time',
        name: 'check_in_time',
        gridColumn: { span: 12 },
        fieldType: 'dateRangePicker',
        dateRangePickerProps: {
          showTime: true,
        },
      },
      {
        label: 'Venue/Center',
        name: 'sub_regions',
        fieldType: 'selectPaginate',
        gridColumn: { span: 12 },
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.region,
          customLabel(row) {
            return row?.name;
          },
        },
      },
      {
        label: 'Gender',
        name: 'gender',
        fieldType: 'selectPaginate',
        gridColumn: { span: 12 },
        selectPaginateProps: {
          keySearch: 'search',
          dataSourceUrl: ApiUrlData.enum_gender,
          customLabel(row) {
            return row;
          },
        },
      },
      {
        label: 'Attendance Center',
        name: 'discipleship_centers',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.region,
          transformOptions(options) {
            const newOptions = [{ code: 'None', id: 'null' }, ...options];
            return newOptions;
          },
        },
      },
      {
        label: 'Care Group Leader',
        name: 'care_group_leaders',
        gridColumn: { span: 12 },
        fieldType: 'selectPaginate',
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.contact,
          customLabel(row) {
            return row?.name;
          },
          customFilterRequest: () => {
            return {
              is_leader: true,
            };
          },
        },
      },
      // {
      //   label: 'Created at',
      //   name: 'created_at',
      //   gridColumn: { span: 12 },
      //   fieldType: 'dateRangePicker',
      // },
      // {
      //   label: 'Updated at',
      //   name: 'updated_at',
      //   gridColumn: { span: 12 },
      //   fieldType: 'dateRangePicker',
      // },

      {
        label: 'Participants',
        name: 'user_ids',
        fieldType: 'selectPaginate',
        gridColumn: { span: 12 },
        selectPaginateProps: {
          keySearch: 'search',
          isMulti: true,
          dataSourceUrl: ApiUrlData.contact,
          customLabel(row) {
            return row?.name;
          },
        },
      },
    ],
  },
];
